import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { useTimeout } from '../../../hooks';

const PaginationItem = ({ currentIndex, marketingHook, onChangeSlide, onClick, active = false, delay = 5000 }) => {
  const callback = ({ stop }) => {
    onChangeSlide();
    stop();
  };

  const { restart, stop } = useTimeout({
    callback,
    delay,
  });

  useEffect(() => {
    if (active) {
      restart();
    } else {
      stop();
    }
  }, [active]);

  return (
    <button
      className={classNames('slider-home__mini-slider-item', 'swiper-slide', { 'swiper-slide--active': active })}
      onClick={() => onClick(currentIndex)}
    >
      <div className="slider-home__mini-slider-progress">
        {marketingHook}
        <span className="slider-home__mini-slider-progress-progress-bar">
          <span className="slider-home__mini-slider-progress-progress-bar-progress" />
        </span>
      </div>
    </button>
  );
};

PaginationItem.propTypes = {
  currentIndex: PropTypes.number,
  marketingHook: PropTypes.string,
  onChangeSlide: PropTypes.func,
  onClick: PropTypes.func,
  active: PropTypes.bool,
  delay: PropTypes.number,
};

export default PaginationItem;
