import React from 'react';
import { string } from 'prop-types';

import { Icon, Link } from '@ets-global/b2c-website-ui';

import { GoogleStaticMap } from '../Google';

import './styles.scss';

const LocationLoader = ({ centerName, address, website, email, phone }) => {
  return (
    <div className="location-center">
      <div className="location-center__column">
        <h2 className="location-center__name">{centerName}</h2>
        <ul className="location-center__informations">
          <li className="location-center__information-item">
            <Icon name={'marker'} />
            <span className="location-center__information-item-text">{address}</span>
          </li>
          {website && (
            <li className="location-center__information-item">
              <Icon name={'www'} />
              <span className="location-center__information-item-text">
                <Link to={website} target={'_blank'}>
                  {website}
                </Link>
              </span>
            </li>
          )}
          {email && (
            <li className="location-center__information-item">
              <Icon name={'arobase'} />
              <span className="location-center__information-item-text">
                <Link to={`mailto:${email}`} target={'_blank'}>
                  {email}
                </Link>
              </span>
            </li>
          )}
          {phone && (
            <li className="location-center__information-item">
              <Icon name={'phone'} />
              <span className="location-center__information-item-text">
                <Link to={`tel:${phone}`} target={'_blank'}>
                  {phone}
                </Link>
              </span>
            </li>
          )}
        </ul>
      </div>

      {address && (
        <div className="location-center__column">
          <GoogleStaticMap
            className="location-center__map"
            zoom={18}
            address={address}
            size={{ width: 905, height: 487 }}
            link={true}
          />
        </div>
      )}
    </div>
  );
};

LocationLoader.propTypes = {
  centerName: string.isRequired,
  address: string.isRequired,
  website: string,
  email: string,
  phone: string,
};

export default LocationLoader;
