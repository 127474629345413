import React, { useEffect } from 'react';
import cx from 'classnames';
import { Trans } from 'react-i18next';
import { Form, Formik } from 'formik';
import { useMutation } from '@apollo/client';
import { useHistory } from 'react-router';

import { Button, Icon, Loader } from '@ets-global/b2c-website-ui';
import HeaderMeta from '../../components/HeaderMeta';
import PracticeTestConclusionFormB2B from './formB2B';
import PracticeTestConclusionFormB2C from './formB2C';
import useYup from '../../hooks/useYup';
import getFormValidator from './getFormValidator';
import { useDataLayer, useLazyQuery, useQuery, useRouter } from '../../hooks';
import {
  CANDIDATE_TYPE_OF_PRACTICE_TEST,
  IS_FRENCH_AND_DOM_TOM_REGION,
  MY_PRACTICE_TEST_SESSIONS,
  OPTION_OF_PRACTICE_TEST,
} from '../../graphql/queries';
import { SAVE_USER_MARKETING_INFO } from '../../graphql/mutations';
import { paths } from '../../paths';
import NotFoundHandler from '../../components/NotFoundHandler';

import './styles.scss';
import FormikObserver from '../../components/FormikObserver';
import { LocalizedRedirect } from '../../components/Routing';

const PracticeTestConclusionForm = () => {
  const {
    generatePath,
    countryIso2,
    currentRoute: {
      params: { slug, sessionId },
    },
  } = useRouter();
  const history = useHistory();
  const { Yup } = useYup();
  const { pushEventLoadedPlacementTestFunnel } = useDataLayer();
  const {
    loading: loadingMyPracticeTestSessions,
    data: { user },
  } = useQuery(MY_PRACTICE_TEST_SESSIONS);
  const {
    loading: loadingIsFrenchAndDomTomRegion,
    data: { isFrenchAndDomTomRegion },
  } = useQuery(IS_FRENCH_AND_DOM_TOM_REGION, { variables: { country: countryIso2 } });
  const [getCandidateType, { data: candidateTypeResult }] = useLazyQuery(CANDIDATE_TYPE_OF_PRACTICE_TEST);
  const [getOptionOfPracticeTest, { data: optionOfPracticeTestResult }] = useLazyQuery(OPTION_OF_PRACTICE_TEST);
  const [mutateSaveUserMarketingInfo, { data: mutationData }] = useMutation(SAVE_USER_MARKETING_INFO);

  const IconStyle = { width: '46px', height: '50px', fill: 'none' };

  useEffect(() => {
    pushEventLoadedPlacementTestFunnel({
      stepName: 'results form',
      stepNumber: '23',
    });
  }, [sessionId]);

  useEffect(() => {
    if (mutationData?.isSaved) {
      history.push(generatePath(paths.PRACTICE_TEST_RESULT_PAGE, { slug, sessionId }));
    }
  }, [mutationData?.isSaved]);

  const formikOnChange = (values) => {
    if (values.candidateType && values.candidateType.length > 0) {
      getOptionOfPracticeTest({
        variables: {
          isFrenchAndDomTomRegion,
          candidateType: values.candidateType,
        },
      });
    }
  };

  if (loadingMyPracticeTestSessions || loadingIsFrenchAndDomTomRegion) {
    return (
      <main className="main main--no-offset-top">
        <Loader />
      </main>
    );
  }

  if (!user.practiceTestSessionIds.includes(parseInt(sessionId, 10))) {
    return <NotFoundHandler />;
  }

  if (user?.marketingInfo?.candidateType) {
    return (
      <LocalizedRedirect
        to={{
          pathname: generatePath(paths.PRACTICE_TEST_RESULT_PAGE, {
            slug,
            sessionId,
          }),
        }}
      />
    );
  }

  return (
    <main className="main main--no-offset-top">
      <HeaderMeta page={'practice-test-practice-test-conclusion'} />
      <div className="practice-test-conclusion__header">
        <h3 className="practice-test-conclusion__header-title">
          <Trans>practice-test-conclusion.title</Trans>
        </h3>
        <h4 className="practice-test-conclusion__header-subtitle">
          <Trans>practice-test-conclusion.subtitle</Trans>
        </h4>
      </div>
      <div className="practice-test-conclusion__body">
        <Formik
          initialValues={{
            isB2C: null,
            candidateType: '',
            city: '',
            schoolName: '',
            schoolCity: '',
            classLevel: '',
            schoolType: '',
            degreeLevel: '',
            activityStatus: '',
            reason: '',
          }}
          validate={(values) => getFormValidator({ Yup, values, isFrenchAndDomTomRegion })}
          onSubmit={(values) => {
            const marketingInfo = Object.entries(values).reduce(
              (marketingInfo, [key, value]) => {
                if (
                  !['schoolCityInput', 'cityInput'].includes(key) &&
                  (typeof value === 'string' ? value.length > 0 : true)
                ) {
                  marketingInfo[key] = value;
                }

                return marketingInfo;
              },
              { country: countryIso2 },
            );

            mutateSaveUserMarketingInfo({ variables: { marketingInfo } });
          }}
        >
          {(formikProps) => {
            const { setFieldValue, values, isSubmitting, isValid, resetForm } = formikProps;

            return (
              <Form>
                <div className="practice-test-conclusion__introduction">
                  <h3 className="practice-test-conclusion__introduction-title">
                    <Trans>practice-test-conclusion.introduction-title</Trans>
                  </h3>
                  <Button
                    className={cx(
                      'practice-test-conclusion__introduction-button',
                      `${
                        values.isB2C === true
                          ? 'practice-test-conclusion__introduction-button--selected'
                          : 'button--white'
                      }`,
                    )}
                    onClick={() => {
                      resetForm();
                      setFieldValue('isB2C', true);
                      getCandidateType({ variables: { isB2C: true } });
                    }}
                  >
                    <Icon
                      name="b2c"
                      style={IconStyle}
                      className={cx(
                        `${
                          values.isB2C === true
                            ? 'practice-test-conclusion__introduction-icon--selected'
                            : 'practice-test-conclusion__introduction-icon'
                        }`,
                      )}
                    />
                    <Trans>practice-test-conclusion.form.b2c.label</Trans>
                  </Button>
                  <Button
                    className={cx(
                      'practice-test-conclusion__introduction-button',
                      `${
                        values.isB2C === false
                          ? 'practice-test-conclusion__introduction-button--selected'
                          : 'button--white'
                      }`,
                    )}
                    onClick={() => {
                      resetForm();
                      setFieldValue('isB2C', false);
                      getCandidateType({ variables: { isB2C: false } });
                    }}
                  >
                    <Icon
                      name="b2b"
                      style={IconStyle}
                      className={cx(
                        `${
                          values.isB2C === false
                            ? 'practice-test-conclusion__introduction-icon--selected'
                            : 'practice-test-conclusion__introduction-icon'
                        }`,
                      )}
                    />
                    <Trans>practice-test-conclusion.form.b2b.label</Trans>
                  </Button>
                </div>
                <div className="practice-test-conclusion__form">
                  {values.isB2C !== null &&
                    (values.isB2C ? (
                      <PracticeTestConclusionFormB2C
                        {...formikProps}
                        candidateType={candidateTypeResult?.candidateType}
                        options={optionOfPracticeTestResult?.options}
                      />
                    ) : (
                      <PracticeTestConclusionFormB2B />
                    ))}
                </div>
                {isValid && (
                  <Button className="practice-test-conclusion__submit" type="submit" disabled={isSubmitting}>
                    <Trans>practice-test-conclusion.form.submit</Trans>
                  </Button>
                )}
                <FormikObserver values={values} onChange={formikOnChange} />
              </Form>
            );
          }}
        </Formik>
      </div>
    </main>
  );
};

export default PracticeTestConclusionForm;
