import React from 'react';

import SessionSearchItemSkeleton from '../List/Item/SessionSearchItemSkeleton';
import SkeletonItem from '../../../SkeletonItem';

const SessionSearchMapTooltipSkeleton = () => {
  return (
    <div className="search-session-map__tooltip search-session-map__tooltip--skeleton">
      <div className={'search-session-map__tooltip-address'}>
        <SkeletonItem style={{ width: '50%', height: '22px' }} />
        <SkeletonItem style={{ width: '65%', height: '19px' }} />
      </div>
      <div className="carousel search-session-map__tooltip-slider">
        <div className="carousel__items">
          {[...Array(4)].map((session, key) => (
            <div className="carousel__item" key={key}>
              <SessionSearchItemSkeleton inMap={true} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default SessionSearchMapTooltipSkeleton;
