import React from 'react';
import { Trans } from 'react-i18next';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router';
import { getParams } from '../../../../helpers/router';

import { Button } from '@ets-global/b2c-website-ui';

const NoSessionForNonProctored = ({ reloadSessionSearchPage }) => {
  const location = useLocation();
  const { distance } = getParams(location.search);

  return (
    <>
      <p className="search-session-main__no-results-paragraph">
        <Trans>session-search.main.no-result.first-line</Trans>
      </p>
      <p className="search-session-main__no-results-paragraph">
        <Trans>
          {distance === 0
            ? 'session-search.main.no-result.second-line'
            : 'session-search.main.no-result.city-perimeter'}
        </Trans>
      </p>
      {distance !== 0 && (
        <Button className={'search-session-main__search-in-country-button'} onClick={() => reloadSessionSearchPage()}>
          <Trans>session-search.main.no-result.search-in-country</Trans>
        </Button>
      )}
    </>
  );
};

NoSessionForNonProctored.propTypes = {
  reloadSessionSearchPage: PropTypes.func.isRequired,
};

export default NoSessionForNonProctored;
