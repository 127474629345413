import React from 'react';

import { Button } from '@ets-global/b2c-website-ui';

import './style.scss';

const MainBlogEditorialContentSkeleton = () => {
  return (
    <div className={'blog-list__top-news skeleton-item skeleton-item--blue'} style={{ width: '100%', height: '370px' }}>
      <div className={'blog-list-top-news__content'}>
        <Button className={'skeleton-item'}>
          <div style={{ width: '70px', height: '16px' }} />
        </Button>
      </div>
    </div>
  );
};

export default MainBlogEditorialContentSkeleton;
