import React, { Fragment } from 'react';

import EditorialContentItemSkeleton from '../EditorialContentItem/EditorialContentItemSkeleton';

const EditorialContentListSkeleton = ({ nbItems = 3 }) => (
  <Fragment>
    <div className="editorial-content-list__list">
      {[...Array(nbItems)].map((data, key) => (
        <EditorialContentItemSkeleton key={key} />
      ))}
    </div>
  </Fragment>
);

export default EditorialContentListSkeleton;
