import React from 'react';

import SkeletonItem from '../../SkeletonItem';
import { SubTitle } from '../../Base/Title';

const PersonTypeAndPurposeSelectsSkeleton = () => {
  return (
    <div className="dotted-select__container">
      <div className="container">
        <div className="dotted-select__wrapper">
          <SubTitle className="subtitle--blue">
            <SkeletonItem style={{ width: '20%', height: '19px' }} />
          </SubTitle>
          <SkeletonItem className={'dotted-select'} style={{ width: '620px', height: '38px' }} />
          <SkeletonItem className={'button'} style={{ width: '115px', height: '38px' }} />
        </div>
      </div>
    </div>
  );
};

export default PersonTypeAndPurposeSelectsSkeleton;
