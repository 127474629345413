import React from 'react';
import SkeletonItem from '../../SkeletonItem';

const EditorialContentItemSkeleton = () => (
  <div className={'editorial-content-item'}>
    <div className={'editorial-content-item__picture'}>
      <SkeletonItem className={'editorial-content-item__picture-inner skeleton-item--blue'} style={{ width: '100%' }} />
    </div>
    <SkeletonItem className={'editorial-content-item__category'} style={{ height: '19px', width: '20%' }} />
    <SkeletonItem className={'editorial-content-item__title'} style={{ height: '52px', width: '100%' }} />
  </div>
);

export default EditorialContentItemSkeleton;
