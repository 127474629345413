import './styles.scss';

import { useApolloClient } from '@apollo/client';
import { Button, Icon } from '@ets-global/b2c-website-ui';
import classNames from 'classnames';
import { Map } from 'immutable';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { Trans } from 'react-i18next';
import { useHistory, useLocation } from 'react-router';

import { PURCHASE } from '../../../../../graphql/queries';
import { addressToString } from '../../../../../helpers/Formatter/StringFormatter';
import { useRouter } from '../../../../../hooks';
import useBasketCookie from '../../../../../hooks/useBasketCookie';
import { paths } from '../../../../../paths';
import ModalContext from '../../../../../store/Modal/ModalContext';
import { Address, Tooltip } from '../../../../Base';
import { NumberByLocale } from '../../../../Element';
import { useDateToString } from '../../../../Element/DateToString';
import GoogleStaticMap from '../../../../Google/StaticMap';
import SessionJsonLD from '../../../../JsonLD/SessionJsonLD';
import TestTypeFamilyTitle from '../../../../Title/TestTypeFamilyTitle';
import SessionSwitchWarningModal from '../../../SessionSwitchWarningModal';

const SessionSearchItem = ({
  session,
  inMap = false,
  className,
  isRegisterButtonClicked,
  setRegisterButtonClicked,
}) => {
  const apolloClient = useApolloClient();
  const location = useLocation();
  const history = useHistory();
  const { generatePath } = useRouter();
  const { getBasket } = useBasketCookie();
  const registrationId = getBasket();
  const { showModal, hideModal } = useContext(ModalContext);
  const DateToString = useDateToString();

  const remainingTimeInMilliSecs = new Date(session.get('lockedAt')).getTime() - new Date().getTime();
  const timeRemaining = {
    days: remainingTimeInMilliSecs / (24 * 60 * 60 * 1000),
    hours: remainingTimeInMilliSecs / (60 * 60 * 1000),
    minutes: remainingTimeInMilliSecs / (60 * 1000),
  };
  const isDay = remainingTimeInMilliSecs >= 86400000;
  const isHours = remainingTimeInMilliSecs < 86400000 && remainingTimeInMilliSecs >= 3600000;

  const displaySessionSwitchModal = (message, sessionId, canValidate = true) => {
    showModal(SessionSwitchWarningModal, {
      message,
      canValidate,
      onClose: () => {
        hideModal();
        setRegisterButtonClicked(false);
      },
      onSubmit: () => {
        hideModal();
        goToNextStep(sessionId);
      },
    });
  };

  const checkExistingPurchase = (sessionId) => {
    setRegisterButtonClicked(true);

    if (registrationId) {
      apolloClient
        .query({
          query: PURCHASE,
          variables: { registrationId },
        })
        .then(({ data: { purchase } }) => {
          if (purchase.voucher) {
            // user cannot add a session to PO if he has a CPF voucher with prep tools only
            if (purchase.voucher.isCpf && purchase.voucher.testTypeIds.length === 0) {
              return displaySessionSwitchModal(
                'session-search.main.item.warning.cpf-voucher.content',
                sessionId,
                false,
              );
            }

            // if there is a session in the voucher, display warning
            if (purchase.voucher.testTypeIds.length !== 0) {
              return displaySessionSwitchModal(
                'session-search.main.item.warning.change-session-with-voucher.content',
                sessionId,
              );
            }
          }

          if (purchase.candidates.length <= 0) {
            return goToNextStep(sessionId);
          }

          displaySessionSwitchModal('session-search.main.item.warning.change-session.content', sessionId);
        });

      return;
    }

    goToNextStep(sessionId);
  };

  const goToNextStep = (sessionId) => {
    return history.push({
      pathname: generatePath(paths.SESSION_REGISTER, { sessionId }),
      state: { prevSearch: location.search },
    });
  };

  let price = (
    <span className="search-session-item__price">
      <NumberByLocale value={session.get('price').get('value')} currency={session.get('price').get('currency')} />
    </span>
  );

  if (Number.isFinite(session.get('price').get('discountedPrice'))) {
    price = (
      <div className="search-session-item__pricing">
        <span
          className={classNames({
            'search-session-item__price': true,
            'search-session-item__price--in-map': inMap,
          })}
        >
          <NumberByLocale
            value={session.get('price').get('discountedPrice')}
            currency={session.get('price').get('currency')}
          />
        </span>
        <span className={classNames('search-session-item__discounted-price', className)}>
          <NumberByLocale value={session.get('price').get('value')} currency={session.get('price').get('currency')} />
        </span>
      </div>
    );
  }

  return (
    <div className={classNames('search-session-item', inMap && 'search-session-item--in-map', className)}>
      <SessionJsonLD session={session} />
      <div className="search-session-item__date">
        <span className="search-session-item__day">
          <DateToString date={session.get('scheduledAt')} timezone={session.get('timeZone')} format="DD MMM" />
        </span>
        <span className="search-session-item__hour">
          <DateToString date={session.get('scheduledAt')} timezone={session.get('timeZone')} format="LT" />
        </span>
      </div>
      <div className="search-session-item__content">
        <div className="search-session-item__informations">
          {session.get('testTypeFamily') && (
            <p className="search-session-item__name">
              <TestTypeFamilyTitle
                component="span"
                color={session.get('testTypeFamily').get('color')}
                title={session.get('testTypeFamily').get('name')}
                testTypeProgram={session.get('testType').get('program')}
                testTypeFormat={session.getIn(['testType', 'format'])}
                className="search-session-item__name-text"
              />
            </p>
          )}
          {!inMap && (
            <div className="search-session-item__address">
              <p
                className={classNames('search-session-item__address-name', {
                  'search-session-item__address-name--e-proctor': !session.get('testingSite'),
                })}
              >
                {!session.get('testingSite') ? (
                  <Trans>common.online</Trans>
                ) : (
                  <>
                    {session.get('testingSite').get('name')}
                    {session.get('testingSite').get('wheelchairAccess') && <Icon name="disabled" />}
                  </>
                )}
              </p>
              {session.get('testingSite') && (
                <div className="search-session-item__address-content">
                  <Tooltip position="top-right" icon="pin" width="219px">
                    <div className="search-session-item__tooltip">
                      <div className="search-session-item__tooltip-wrapper-map">
                        <GoogleStaticMap
                          address={addressToString(session.get('testingSite').get('address'))}
                          size={{ width: 234, height: 152 }}
                        />
                      </div>
                      <Address
                        className={'address--small'}
                        wheelchairAccess={session.get('testingSite').get('wheelChairAccess')}
                        addressName={session.get('testingSite').get('name')}
                        address={session.get('testingSite').get('address')}
                      />
                    </div>
                  </Tooltip>
                  {addressToString(session.get('testingSite').get('address'))}
                </div>
              )}
            </div>
          )}
        </div>

        <div className="search-session-item__register">
          <div className="search-session-item__register-action">
            {price}
            <Button
              data-cypress="buying-process.register"
              onClick={(e) => {
                e.preventDefault();
                checkExistingPurchase(session.get('id'));
              }}
              loading={isRegisterButtonClicked}
              to={paths.SESSION_REGISTER}
              params={{ sessionId: session.get('id') }}
            >
              <Trans>session-search.main.item.register</Trans>
            </Button>
          </div>
          <span className="search-session-item__tips">
            <Trans values={{ remainingSeats: session.get('seatsAvailable') }} count={session.get('seatsAvailable')}>
              session-search.main.item.available_remaining_seats
            </Trans>
          </span>
          {0 < timeRemaining.days && isDay && (
            <span className="search-session-item__tips">
              <Trans
                values={{
                  numberOfDays: Math.round(timeRemaining.days),
                  count: Math.round(timeRemaining.days),
                }}
              >
                session-search.main.item.number_of_days_to_book
              </Trans>
            </span>
          )}
          {1 > timeRemaining.days && 0 < timeRemaining.hours && isHours && (
            <span className="search-session-item__tips">
              <Trans
                values={{
                  numberOfHours: Math.round(timeRemaining.hours),
                  count: Math.round(timeRemaining.hours),
                }}
              >
                session-search.main.item.number_of_hours_to_book
              </Trans>
            </span>
          )}
          {1 > timeRemaining.days && 1 > timeRemaining.hours && 0 < timeRemaining.minutes && (
            <span className="search-session-item__tips">
              <Trans
                values={{
                  numberOfMinutes: Math.round(timeRemaining.minutes),
                  count: Math.round(timeRemaining.minutes),
                }}
              >
                session-search.main.item.number_of_minutes_to_book
              </Trans>
            </span>
          )}
        </div>
      </div>
    </div>
  );
};

SessionSearchItem.propTypes = {
  session: PropTypes.instanceOf(Map),
  inMap: PropTypes.bool,
  location: PropTypes.object,
};

export default React.memo(
  SessionSearchItem,
  ({ session: prevSession, location: prevLocation }, { session: nextSession, location: nextLocation }) => {
    return prevSession.equals(nextSession) && prevLocation && prevLocation.equals(nextLocation.search);
  },
);
