import React from 'react';
import PropTypes from 'prop-types';

import { paths } from '../../../paths';
import { PROGRAMME_COLOR } from '../../../constants';
import { useRouter } from '../../../hooks';
import { BorderedBoxesList, BorderedBox } from '../../BorderedBoxesList';

const HomeProgrammes = ({ marketingHook, programmes, otherProgrammes }) => {
  const { generatePath } = useRouter();

  const borderedBoxes = programmes.map((programme, key) => (
    <BorderedBox
      key={key}
      animated
      title={programme.miniTitle}
      color={PROGRAMME_COLOR[programme.color]}
      linkTo={generatePath(paths.PROGRAMME, { slug: programme.slug })}
    >
      {programme.miniDescription}
    </BorderedBox>
  ));

  return <BorderedBoxesList marketingHook={marketingHook} borderedBoxes={borderedBoxes} solutions={otherProgrammes} />;
};

HomeProgrammes.propType = {
  marketingHook: PropTypes.string,
  programmes: PropTypes.arrayOf(
    PropTypes.shape({
      slug: PropTypes.string,
      miniTitle: PropTypes.string,
      miniDescription: PropTypes.string,
      color: PropTypes.string,
    }),
  ),
  otherProgrammes: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      subtitle: PropTypes.string,
    }),
  ),
};

export default HomeProgrammes;
