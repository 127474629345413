import React from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';

import { Mobile, Tablet, Desktop } from '../../Responsive';
import { PrismicRichText, SocialShare } from '../../Base';
import InjectHtml from '../../InjectHtml';

import './styles.scss';

const LandingHeader = ({ editorialContent }) => {
  return (
    <>
      <section>
        {editorialContent.get('images').map((image, key) => {
          let MediaQuery = Mobile;
          if (image.get('source') === 'DESKTOP') {
            MediaQuery = Desktop;
          } else if (image.get('source') === 'TABLET') {
            MediaQuery = Tablet;
          }

          return (
            <MediaQuery key={key}>
              <div
                className="landing__header"
                style={{
                  backgroundImage: `url(${image.get('url')})`,
                }}
              >
                <div className="container container--padding">
                  <div className="landing__header-inner">
                    <InjectHtml component="h1" className="landing__title" html={editorialContent.get('title')} />
                    <InjectHtml component="p" className="landing__subtitle" html={editorialContent.get('subtitle')} />
                  </div>
                </div>
              </div>
            </MediaQuery>
          );
        })}
        <div className="container container--padding">
          <main className="landing__main">
            {editorialContent.get('editorialContentType') !== 'LANDING_PAGE_PARTNER' && (
              <>
                <div className="landing__share">
                  {editorialContent.get('meta') && <SocialShare title={editorialContent.get('meta').get('title')} />}
                </div>
                {editorialContent.get('summary') && (
                  <PrismicRichText className="introduction" richText={editorialContent.get('summary').toJS()} />
                )}
              </>
            )}
          </main>
        </div>
      </section>
    </>
  );
};

LandingHeader.propTypes = {
  editorialContent: PropTypes.instanceOf(Map).isRequired,
};

export default LandingHeader;
