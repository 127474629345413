import React, { Fragment, useEffect } from 'react';
import { fromJS } from 'immutable';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';

import { Loader } from '@ets-global/b2c-website-ui';

import { ECBlockType } from '../../constants';
import { useDataLayer, useRouter } from '../../hooks';
import useQuery from '../../hooks/useQuery';
import { TEST_LANDING_PAGE } from '../../graphql/queries';
import HeaderMeta from '../../components/HeaderMeta';
import HeaderLight from '../../components/Header/HeaderLight';
import NotFoundHandler from '../../components/NotFoundHandler';
import Footer from '../../components/Footer';
import { ArticleBlock } from '../../components/Content';
import Banner from '../../components/Base/Banner';
import { LocalizedRedirect } from '../../components/Routing';
import { tawkScript } from './tawkScript';

import './styles.scss';

const TestLandingPage = () => {
  const { lang, countryEmoIso2, currentRoute, generatePrismicDocumentTypeOrCustomLinkPath } = useRouter();
  const { params, isHomeTest } = currentRoute;
  const { pushEventGAClickNavigation, pushEventLoadedPage } = useDataLayer();

  const { t } = useTranslation();
  const {
    loading,
    data: { testLandingPage },
  } = useQuery(TEST_LANDING_PAGE, {
    variables: { lang, country: countryEmoIso2, token: params.token, isHomeTest: isHomeTest || false },
  });

  useEffect(() => {
    if (window?.Tawk_API) {
      window.Tawk_API.onChatStarted = () => {
        pushEventGAClickNavigation({ eventLabel: 'need help' });
      };
    }
  }, [testLandingPage?.needHelp?.tawkId]);

  useEffect(() => {
    if (testLandingPage) {
      pushEventLoadedPage(currentRoute, { testType: testLandingPage.testTypeOrHomeTestName });
    }
  }, [currentRoute, testLandingPage]);

  if (loading) return <Loader />;
  if (!testLandingPage) return <NotFoundHandler />;
  if (testLandingPage.redirectLink?.slug) {
    return (
      <LocalizedRedirect status={301} to={generatePrismicDocumentTypeOrCustomLinkPath(testLandingPage.redirectLink)} />
    );
  }

  const blocks = fromJS(testLandingPage.blocks).map((block) => {
    switch (block.get('__typename')) {
      case ECBlockType.ECLegalSentenceForHomeTestBlock:
        block = block
          .set('testDuration', testLandingPage.testDuration)
          .set('candidateName', testLandingPage.candidateName)
          .set('candidateName', testLandingPage.candidateName)
          .set('homeSessionId', testLandingPage.homeSessionId)
          .set('sessionIsStarted', testLandingPage.sessionIsStarted)
          .set('sessionIsFinished', testLandingPage.sessionIsFinished)
          .set('nextItsMaintenanceWindow', testLandingPage.nextItsMaintenanceWindow);
        break;
      case ECBlockType.ECHorizontalIconContentListBlock: {
        const horizontalIconContentItems = block.get('horizontalIconContentItems').map((horizontalIconContentItem) => {
          if (!isHomeTest && horizontalIconContentItem.get('exposeProctorexamCheckRequirementsLink')) {
            horizontalIconContentItem = horizontalIconContentItem.setIn(
              ['link', 'to'],
              `https://etsglobal.proctorexam.com/check_requirements/${params.token}`,
            );
          }

          return horizontalIconContentItem;
        });
        block = block.set('horizontalIconContentItems', horizontalIconContentItems);
        break;
      }
    }

    return block;
  });

  return (
    <Fragment>
      <HeaderMeta page={isHomeTest ? 'toeic-in-autonomy' : 'remote-proctored-test'} />
      {testLandingPage?.needHelp?.tawkId && (
        <Helmet
          script={[
            {
              type: 'text/javascript',
              innerHTML: tawkScript(testLandingPage?.needHelp?.tawkId),
            },
          ]}
        />
      )}
      <div className={'landing-page-test'}>
        <HeaderLight needHelp={testLandingPage.needHelp} />
        <article className={'landing-page-test__main'}>
          <Banner
            title={t('common.welcome-candidate', { fullName: testLandingPage.candidateName })}
            baseline={testLandingPage.baseline}
            images={testLandingPage.backgroundImage}
            isTestLandingPage={true}
          />
          {blocks.map((block, key) => (
            <ArticleBlock key={key} block={block} />
          ))}
        </article>
        <Footer />
      </div>
    </Fragment>
  );
};

export default TestLandingPage;
