import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import intersection from 'lodash/intersection';
import { Trans, useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { Link } from '@ets-global/b2c-website-ui';

import { CheckboxField, Icon } from '@ets-global/b2c-website-ui';
import { Tooltip } from '../../../../Base';
import BaseModal from '../../../../Base/Modal/BaseModal';
import ChangeTestTypeFilterModal from '../../../ChangeTestTypeFilterModal';
import ModalContext from '../../../../../store/Modal/ModalContext';
import PrismicRichText from '../../../../Base/PrismicRichText';
import { TestTypeFormat, TestTypeProgram } from '../../../../../constants';
import ErrorMessage from '../../../../Base/ErrorMessage/ErrorMessage';

const TestTypeFamilyProgram = ({
  hasManyPrograms,
  program,
  testTypeFamilyId,
  checkedTestTypeIds,
  pushToUrl,
  canChangeTestTypeFilter = true,
  isChangeTestTypeFilterModalDisplayed = false,
}) => {
  const [isModalOpened, setIsModalOpened] = useState(false);
  const { showModal, hideModal } = useContext(ModalContext);
  const { t } = useTranslation();
  const isPP = program.type === TestTypeProgram.PP;
  const programLabel = isPP ? `public-program` : `institutional-program`;
  const [showErrorMessage, setShowErrorMessage] = useState(false);

  const onChange = (event, currentFormatType) => {
    const checked = event.target.checked;

    if (!checked && checkedTestTypeIds.length === 1) {
      setShowErrorMessage(event.target.id);

      return;
    }

    setShowErrorMessage(false);
    const currentTestTypeIds = program.formats[currentFormatType].testTypeIds;
    const testTypeIds = checked
      ? [...new Set([...checkedTestTypeIds.concat(currentTestTypeIds)])]
      : checkedTestTypeIds.filter((currentId) => !currentTestTypeIds.includes(currentId));

    if (isChangeTestTypeFilterModalDisplayed) {
      showModal(ChangeTestTypeFilterModal, {
        onClose: () => {
          hideModal();
        },
        onSubmit: () => {
          hideModal();
          pushToUrl({ testTypeIds });
        },
      });

      return;
    }

    pushToUrl({ testTypeIds });
  };

  return (
    <>
      <div className="search-session-choice__program">
        <span className="search-session-choice__program-label">
          {t(`session-search.side-bar.choice.program.${programLabel}`)}
          {isPP ? <Icon name={programLabel} /> : null}
        </span>
        {program.shortDescription && (
          <Tooltip position={'bottom-right'} width={'300px'} icon={'info'}>
            <PrismicRichText richText={program.shortDescription} forceTargetBlank={true} />
          </Tooltip>
        )}
        {program.scoreReport?.url && (
          <div className="search-session-choice__program-attestation">
            <Link onClick={() => setIsModalOpened(true)}>
              <Trans>session-search.side-bar.choice.certification-type</Trans>
            </Link>
            <BaseModal
              className={'search-session-choice__program-attestation-modal'}
              isOpen={isModalOpened}
              onClose={() => setIsModalOpened(false)}
            >
              <img src={program.scoreReport.url} alt={t('session-search.side-bar.choice.certification-type')} />
            </BaseModal>
          </div>
        )}
      </div>
      <div className="search-session-choice__formats">
        <span className="search-session-choice__formats-label">
          <Trans>session-search.side-bar.format</Trans>
        </span>
        {Object.values(program.formats).map((format, key) => {
          const id = `${testTypeFamilyId}-${program.type}-${format.type}`;

          return (
            <div
              key={key}
              className={classNames('search-session-choice__format-item', {
                'search-session-choice__format-item--without-checkbox': Boolean(
                  !canChangeTestTypeFilter || !hasManyPrograms || program.formats.length === 1,
                ),
                'search-session-choice__format-item--with-promotion': format.promotionLabel,
              })}
            >
              <CheckboxField
                id={id}
                label={format.title}
                icon={format.iconName}
                name={`format-${isPP ? 'pp' : 'ip'}`}
                value={intersection(checkedTestTypeIds, format.testTypeIds).length}
                onChange={(event) => onChange(event, format.type)}
              />
              {format.promotionLabel && (
                <span className="search-session-choice__format-item-promotion">{format.promotionLabel}</span>
              )}
              <Tooltip position={'bottom-right'} width={'300px'} icon={'info'}>
                {format.description}
              </Tooltip>
              {showErrorMessage === id && (
                <ErrorMessage>{t('session-search.side-bar.choice.select-at-least-one-format')}</ErrorMessage>
              )}
            </div>
          );
        })}
      </div>
    </>
  );
};

TestTypeFamilyProgram.propTypes = {
  hasManyPrograms: PropTypes.bool,
  program: PropTypes.shape({
    type: PropTypes.oneOf([TestTypeProgram.PP, TestTypeProgram.IP]),
    shortDescription: PropTypes.array.isRequired,
    scoreReport: PropTypes.shape({
      url: PropTypes.string,
    }),
    formats: PropTypes.objectOf(
      PropTypes.shape({
        type: PropTypes.oneOf([TestTypeFormat.CBT, TestTypeFormat.PBT]),
        title: PropTypes.string,
        iconName: PropTypes.string,
        promotionLabel: PropTypes.string,
        testTypeIds: PropTypes.arrayOf(PropTypes.number).isRequired,
      }),
    ),
  }).isRequired,
  testTypeFamilyId: PropTypes.string.isRequired,
  checkedTestTypeIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  pushToUrl: PropTypes.func.isRequired,
  canChangeTestTypeFilter: PropTypes.bool,
  isChangeTestTypeFilterModalDisplayed: PropTypes.bool,
};

export default TestTypeFamilyProgram;
