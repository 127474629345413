import { useCombobox, UseComboboxStateChange } from 'downshift';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  AutocompleteFieldProps,
  AutocompleteItem,
  AutocompleteOptionLabel,
} from '../../@ets-global/b2c-website-ui/Fields/Autocomplete';
import Icon from '../../@ets-global/b2c-website-ui/Icon';
import { PLACE_AUTOCOMPLETE_RESULT, REGION_AND_CITY_AND_COORDINATE_BY_PLACE_ID } from '../../graphql/queries';
import { capitalize } from '../../helpers/Formatter/StringFormatter';
import { useLazyQuery, useRouter } from '../../hooks';
import { useDebounce } from '../../hooks/useDebounce';
import { GooglePlacesAutocompleteContainerProps } from './GooglePlacesAutocomplete';

export interface UseGooglePlacesAutocomplete extends AutocompleteFieldProps {}

export const useGooglePlacesAutocomplete = (
  options: Pick<
    GooglePlacesAutocompleteContainerProps,
    'defaultRegion' | 'defaultCity' | 'onInputValueChange' | 'onSelectedItemChange' | 'searchType'
  >
): UseGooglePlacesAutocomplete => {
  const minLengthToSearch = 3;
  const { t } = useTranslation();
  const { lang, countryIso2 } = useRouter();
  const [debouncedSearchParams, setDebouncedSearchParams] = useState<{
    value: string;
    delay: number;
  }>({
    value: '',
    delay: 500,
  });
  const [loading, setLoading] = useState<boolean>(false);
  const debouncedSearchTerm = useDebounce(debouncedSearchParams.value, debouncedSearchParams.delay);
  const [
    getPlaceAutocompleteResult,
    {
      data: { placeAutocompleteResult },
    },
  ] = useLazyQuery(PLACE_AUTOCOMPLETE_RESULT, {
    onCompleted: () => setLoading(false),
    notifyOnNetworkStatusChange: true,
  });
  const [
    getRegionCityAndCoordinateByPlaceId,
    {
      data: { regionAndCityAndCoordinateByPlaceId },
    },
  ] = useLazyQuery(REGION_AND_CITY_AND_COORDINATE_BY_PLACE_ID);

  useEffect(() => {
    if (debouncedSearchTerm) {
      getPlaceAutocompleteResult({
        variables: {
          input: {
            lang,
            country: countryIso2,
            term: debouncedSearchTerm,
            type: options.searchType ? options.searchType.toUpperCase() : undefined,
          },
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchTerm]);

  useEffect(() => {
    if (regionAndCityAndCoordinateByPlaceId && options?.onSelectedItemChange) {
      options.onSelectedItemChange({
        region: regionAndCityAndCoordinateByPlaceId.region,
        city: regionAndCityAndCoordinateByPlaceId.city,
        coordinates: {
          latitude: regionAndCityAndCoordinateByPlaceId.coordinates.latitude,
          longitude: regionAndCityAndCoordinateByPlaceId.coordinates.longitude,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [regionAndCityAndCoordinateByPlaceId]);

  const defaultInputValue =
    options?.defaultRegion && options.defaultCity
      ? `${capitalize(options.defaultCity)}, ${capitalize(options.defaultRegion)}`
      : options.defaultCity
        ? capitalize(options.defaultCity)
        : '';

  return {
    loading,
    minLengthToSearch,
    loadingLabel: t('google_places_autocomplete_loading_label'),
    emptyItemsLabel: t('google_places_autocomplete_empty_items_label'),
    minLettersLabel: t('google_places_autocomplete_minimum_letters_label', {
      minLengthToSearch,
    }),
    defaultInputValue,
    renderOptionLabel: ({ item, inputValue }) => (
      <>
        <Icon name="pin" />
        <AutocompleteOptionLabel item={item} inputValue={inputValue} />
      </>
    ),
    onInputValueChange: (changes: UseComboboxStateChange<AutocompleteItem>): void => {
      if (options?.onInputValueChange) {
        options?.onInputValueChange(changes.inputValue);
      }

      if (changes.type === useCombobox.stateChangeTypes.InputChange && changes.inputValue.length >= minLengthToSearch) {
        setDebouncedSearchParams({ value: changes.inputValue, delay: 500 });
        setLoading(true);
      } else if (changes.type === useCombobox.stateChangeTypes.FunctionSetInputValue) {
        setDebouncedSearchParams({ value: '', delay: 0 });
        setLoading(false);
      }
    },
    onSelectedItemChange: (changes: UseComboboxStateChange<AutocompleteItem>): void => {
      getRegionCityAndCoordinateByPlaceId({
        variables: { placeId: changes.selectedItem.value },
      });
    },
    items:
      placeAutocompleteResult?.map((result) => ({
        label: result.prediction,
        value: result.placeId,
      })) ?? [],
  };
};
