import React from 'react';
import { Trans } from 'react-i18next';
import kebabCase from 'lodash/kebabCase';

import { Button } from '@ets-global/b2c-website-ui';

import Picture from '../../Element/Picture';
import { useDateToString } from '../../Element/DateToString';
import { paths } from '../../../paths';
import ImgLazyLoad from '../../ImgLazyLoad';

import './style.scss';

const MainBlogEditorialContent = ({ editorialContent }) => {
  const DateToString = useDateToString();

  return (
    <section className={'blog-list-top-news'}>
      <div className="blog-list-top-news__picture">
        {editorialContent.get('headerImage') ? (
          <ImgLazyLoad
            alt={editorialContent.get('headerImage').alt}
            url={editorialContent.get('headerImage').url}
            skeleton={
              <div
                className="skeleton-item skeleton-item--blue"
                style={{ display: 'block', width: '100%', height: '370px' }}
              />
            }
          />
        ) : (
          <Picture
            images={editorialContent.get('images').toJS()}
            skeleton={
              <div
                className="skeleton-item skeleton-item--blue"
                style={{ display: 'block', width: '100%', height: '370px' }}
              />
            }
          />
        )}
      </div>
      <div className={'blog-list-top-news__content'}>
        <p className={'blog-list-top-news__category'}>
          <Trans>blog.editorial-content.category.{editorialContent.get('editorialContentType').toLowerCase()}</Trans>
        </p>
        <p className={'blog-list-top-news__title'}>{editorialContent.get('title')}</p>
        <p className={'blog-list-top-news__text'}>{editorialContent.get('subtitle')}</p>
        <p className={'blog-list-top-news__date'}>
          <DateToString date={editorialContent.get('date')} format="DD MMM" />
        </p>
        <Button
          to={paths.BLOG_ARTICLE}
          params={{
            editorialContentType: kebabCase(editorialContent.get('editorialContentType')),
            slug: editorialContent.get('slug'),
          }}
        >
          <Trans>blog.read-more</Trans>
        </Button>
      </div>
    </section>
  );
};

export default MainBlogEditorialContent;
