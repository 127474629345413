import React from 'react';
import { fromJS } from 'immutable';
import classNames from 'classnames';
import { useLocation } from 'react-router';
import { Trans } from 'react-i18next';

import { Loader } from '@ets-global/b2c-website-ui';

import { useRouter } from '../../../hooks';
import { getParams } from '../../../helpers/router';
import {
  NEWS,
  CASE_STUDY,
  TESTIMONIAL,
  BASIC_CONTENT,
  LANDING_PAGE,
  LANDING_PAGE_PARTNER,
} from '../../../graphql/queries';
import { ArticleHeader, LandingHeader, ArticleBlock, MoreLinks } from '../../../components/Content';
import NextSessions from '../../../components/SessionSearch/Next';
import HeaderMeta from '../../../components/HeaderMeta';
import NotFoundHandler from '../../../components/NotFoundHandler';
import NewsletterBlock from '../../../containers/NewsletterBlock';
import LastEditorialContent from '../../../containers/LastEditorialContent';
import useQuery from '../../../hooks/useQuery';
import MarketingBannerContainer from '../../../containers/MarketingBanner';

import { PrismicRichText } from '../../../components/Base';
import Picture from '../../../components/Element/Picture';
import { LocalizedRedirect } from '../../../components/Routing';

import './styles.scss';

const Article = () => {
  const location = useLocation();
  const {
    currentRoute: {
      params: { editorialContentType, slug, locale: lang, country },
    },
    partnerDomain,
    generatePrismicDocumentTypeOrCustomLinkPath,
  } = useRouter();
  const { articleWithRichTextEditor } = getParams(location.search);

  let query;
  let variables = { lang, country, slug };
  switch (editorialContentType) {
    case 'news':
      query = NEWS;
      break;
    case 'case-study':
      query = CASE_STUDY;
      break;
    case 'testimonial':
      query = TESTIMONIAL;
      break;
    case 'content':
      query = BASIC_CONTENT;
      break;
    case 'landing-page':
      query = LANDING_PAGE;
      break;
    case 'landing-page-partner':
      query = LANDING_PAGE_PARTNER;
      variables.referrer = partnerDomain;
      break;
    default:
      return <NotFoundHandler />;
  }

  let {
    data: { editorialContent },
    loading,
  } = useQuery(query, {
    variables,
  });

  if (loading) {
    return <Loader />;
  }

  if (!editorialContent) {
    return <NotFoundHandler />;
  }

  if (editorialContent.redirectLink?.slug) {
    return (
      <LocalizedRedirect status={301} to={generatePrismicDocumentTypeOrCustomLinkPath(editorialContent.redirectLink)} />
    );
  }

  const meta = editorialContent.meta;
  editorialContent = fromJS(editorialContent);
  const isLandingPage = editorialContent.get('editorialContentType') === 'LANDING_PAGE';
  const isLandingPagePartner = editorialContent.get('editorialContentType') === 'LANDING_PAGE_PARTNER';
  const displayNewsletterBlock = editorialContent.get('displayNewsletterBlock');
  const hasLinks = editorialContent.get('links').size > 0;
  const hasDocuments = editorialContent.get('documents').size > 0;
  const backgroundPartnerLogoImages = [
    {
      source: 'MOBILE',
      url: require(`../../../assets/images/landing-page-partner/mobile.png`),
      alt: 'landing-page-partner',
    },
    {
      source: 'TABLET',
      url: require(`../../../assets/images/landing-page-partner/mobile.png`),
      alt: 'landing-page-partner',
    },
    {
      source: 'DESKTOP',
      url: require(`../../../assets/images/landing-page-partner/desk.png`),
      alt: 'landing-page-partner',
    },
  ];

  return (
    <main className={classNames('main article container container--full')}>
      <article>
        {!isLandingPagePartner && <HeaderMeta meta={meta} page={editorialContentType} type={'article'} />}
        {isLandingPage || isLandingPagePartner ? (
          <>
            <LandingHeader editorialContent={editorialContent} />
            <MarketingBannerContainer documentId={editorialContent.get('id')} />
          </>
        ) : (
          <>
            <ArticleHeader editorialContent={editorialContent} />
            <MarketingBannerContainer documentId={editorialContent.get('id')} />
          </>
        )}
        {isLandingPagePartner && (
          <section className="article-block article-block--image-right">
            <div className="container container--padding">
              <p className="article-block__title-summary">{editorialContent.get('titleSummary')}</p>
              <div className="article-block__inner">
                <div className="article-block__block-picture">
                  {editorialContent.get('partnerLogo') && (
                    <>
                      <Picture images={backgroundPartnerLogoImages} />
                      <Picture
                        className="article-block__block-picture--logo"
                        images={editorialContent.get('partnerLogo').toJS()}
                      />
                    </>
                  )}
                </div>
                <PrismicRichText className="article-block__text" richText={editorialContent.get('summary').toJS()} />
              </div>
            </div>
          </section>
        )}
        {editorialContent
          .get('blocks')
          .filter((block) => {
            if (articleWithRichTextEditor) {
              return block.get('__typename') === 'ECRichTextEditorBlock';
            } else if (block.get('__typename') === 'ECDiscountBlock') {
              return block.has('discounts') && block.get('discounts').size > 0;
            }

            return true;
          })
          .map((block, key) => (
            <ArticleBlock key={key} block={block} />
          ))}
        <aside>
          {isLandingPage && (
            <NextSessions
              className={'product-page__next-sessions'}
              testTypeFamilies={editorialContent.get('testTypeFamilies').toJS()}
            />
          )}
          {displayNewsletterBlock && (
            <div className={'container container--padding'}>
              <NewsletterBlock />
            </div>
          )}
          {(hasLinks || hasDocuments) && !articleWithRichTextEditor && (
            <MoreLinks links={editorialContent.get('links')} documents={editorialContent.get('documents')} />
          )}
          {['news', 'case-study', 'testimonial'].includes(editorialContentType) && (
            <section className="container more-articles">
              <LastEditorialContent
                title={
                  <p className="more-articles__title">
                    <Trans>blog.article.last-publication.title</Trans>
                  </p>
                }
              />
            </section>
          )}
        </aside>
      </article>
    </main>
  );
};

export default Article;
