import React, { useRef } from 'react';

import { useIntersectionObserver } from '../../hooks';

const LazyLoad = ({ children, skeleton }) => {
  const elementRef = useRef(null);
  const [inView] = useIntersectionObserver(elementRef);

  return <div ref={elementRef}>{!inView ? skeleton ? skeleton : <div /> : children}</div>;
};

export default LazyLoad;
