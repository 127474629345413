import React from 'react';

import SkeletonItem from '../../SkeletonItem';

import './styles.scss';

const HomeSliderSkeleton = () => (
  <section
    className="slider-home slider-home--has-push-slider skeleton-item skeleton-item--blue"
    style={{ width: '100%' }}
  >
    <div className="slider-home__slider swiper-container">
      <div className="swiper-wrapper">
        <div className="carousel">
          <div className="carousel__items">
            <div className="carousel__item">
              <div className="slider-home__slider-item">
                <div className="container">
                  <div className="slider-home__slider-item-content">
                    <h2>
                      <SkeletonItem
                        className="slider-home__slider-item-subtitle"
                        style={{ width: '20%', height: '19px' }}
                      />
                    </h2>
                    <h2>
                      <SkeletonItem
                        className="slider-home__slider-item-title"
                        style={{ width: '90%', height: '70px' }}
                      />
                    </h2>
                    <SkeletonItem className={'button'} style={{ width: '130px', height: '38px' }} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
);

export default HomeSliderSkeleton;
