import { isWithinInterval } from 'date-fns';

import { convertMinutesToHours } from './convertMinutesToHours';

export const isCurrentlyInsideItsMaintenanceWindow = (testDurationInHours, nextItsMaintenanceWindow) => {
  if (!nextItsMaintenanceWindow) {
    return false;
  }
  const MAINTENANCE_WINDOW_BUFFER_MINUTES = 60;
  const totalBuffer = testDurationInHours + convertMinutesToHours(MAINTENANCE_WINDOW_BUFFER_MINUTES);
  const totalBufferHours = Math.floor(totalBuffer);
  const totalBufferMinutes = 60 * (totalBuffer - totalBufferHours);

  const start = new Date(nextItsMaintenanceWindow.startDate);
  start.setHours(start.getHours() - totalBufferHours);
  start.setMinutes(start.getMinutes() - totalBufferMinutes);
  const end = new Date(nextItsMaintenanceWindow.endDate);

  return isWithinInterval(new Date(), { start, end });
};
