import React from 'react';

import { FieldWrapper } from '../../@ets-global/b2c-website-ui/Fields';
import { AutocompleteField } from '../../@ets-global/b2c-website-ui/Fields/Autocomplete';
import { useGooglePlacesAutocomplete } from './useGooglePlacesAutocomplete';

export interface GooglePlacesAutocompleteContainerProps {
  id: string;
  label?: string;
  placeholder?: string;
  required?: boolean;
  showError?: boolean;
  errorMessage?: string;
  hasValue?: boolean;
  disabled?: boolean;
  defaultRegion?: string;
  defaultCity?: string;
  onInputValueChange?: (value: string) => void;
  onSelectedItemChange?: (options: {
    city: string;
    region: string;
    coordinates: { latitude: number; longitude: number };
  }) => void;
  searchType?: 'city' | 'region';
}

export const GooglePlacesAutocompleteContainer: React.FC<GooglePlacesAutocompleteContainerProps> = ({
  id,
  label,
  placeholder,
  required,
  showError,
  hasValue,
  errorMessage,
  disabled,
  defaultRegion,
  defaultCity,
  onInputValueChange,
  onSelectedItemChange,
  searchType,
}) => {
  const googlePlacesAutocompleteProps = useGooglePlacesAutocomplete({
    defaultRegion,
    defaultCity,
    onInputValueChange,
    onSelectedItemChange,
    searchType,
  });

  return (
    <FieldWrapper
      id={id}
      label={label}
      icon="pin"
      required={required ?? false}
      showError={showError ?? false}
      hasValue={hasValue ?? true}
      errorMessage={errorMessage}
    >
      <AutocompleteField
        {...googlePlacesAutocompleteProps}
        initialInputValue={googlePlacesAutocompleteProps.defaultInputValue}
        inputProps={{ placeholder, disabled }}
      />
    </FieldWrapper>
  );
};
