import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import Button from '@ets-global/b2c-website-ui/Button';

import { SubTitle } from '../../Base/Title';
import HomeSelectField from './HomeSelectField';

const PersonTypeAndPurposeSelects = ({ personTypes = [] }) => {
  const [selectedPersonType, setSelectedPersonType] = useState(null);
  const [selectedPurpose, setSelectedPurpose] = useState(null);
  const { t } = useTranslation();

  if (!personTypes || personTypes.length === 0) {
    return '';
  }

  const selectedPersonTypeString = selectedPersonType
    ? selectedPersonType.name
    : t('home.select-person-type-purpose.default-person-type');

  const selectedPurposeString = selectedPurpose
    ? selectedPurpose.name
    : t('home.select-person-type-purpose.default-person-type');

  const handleSetSelectedPersonType = (value) => {
    if (selectedPersonType && personTypes[value].name === selectedPersonType.name) {
      return;
    }

    setSelectedPersonType(personTypes[value]);
    setSelectedPurpose(null);
  };

  const handleSetSelectedPurpose = (value) => {
    if (selectedPurpose && selectedPersonType.purposes[value].name === selectedPurpose.name) {
      return;
    }

    setSelectedPurpose(selectedPersonType.purposes[value]);
  };

  const personTypeChoice = personTypes.map((personType, key) => ({
    value: key,
    text: personType.name,
  }));

  let purposeChoice = null;
  if (selectedPersonType) {
    purposeChoice = selectedPersonType.purposes.map((purpose, key) => ({
      value: key,
      text: purpose.name,
    }));
  }

  return (
    <section className="dotted-select__container">
      <div className="container">
        <div className="dotted-select__wrapper">
          <SubTitle className="subtitle--blue">
            <Trans>home.select-person-type-purpose.title</Trans>
          </SubTitle>
          <HomeSelectField
            value={selectedPersonTypeString}
            className={selectedPersonType ? 'dotted-select--has-value' : ''}
            label={t('home.select-person-type-purpose.person-type-label')}
            choices={personTypeChoice}
            onSelectChoice={handleSetSelectedPersonType}
          />
          <HomeSelectField
            value={selectedPurposeString}
            className={selectedPurpose ? 'dotted-select--has-value' : ''}
            label={t('home.select-person-type-purpose.purpose-label')}
            choices={purposeChoice}
            onSelectChoice={handleSetSelectedPurpose}
          />
          {selectedPurpose && (
            <Button {...selectedPurpose.link}>
              <Trans>home.select-person-type-purpose.button</Trans>
            </Button>
          )}
          {!selectedPurpose && (
            <Button disabled={true}>
              <Trans>home.select-person-type-purpose.button</Trans>
            </Button>
          )}
        </div>
      </div>
    </section>
  );
};

PersonTypeAndPurposeSelects.propTypes = {
  personTypes: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      purposes: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string,
          link: PropTypes.object,
        }),
      ),
    }),
  ),
};

export default PersonTypeAndPurposeSelects;
