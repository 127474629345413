import React, { useState } from 'react';
import { fromJS } from 'immutable';
import { object, number } from 'prop-types';

import { OPEN_SESSIONS_SEARCH } from '../../../../graphql/queries';
import SessionSearchItem from '../List/Item';
import Carousel from '../../../Carousel';
import { Address } from '../../../Base';
import SessionSearchMapTooltipSkeleton from './SessionSearchMapTooltipSkeleton';
import { useBasketCookie, useRouter, useQuery } from '../../../../hooks';

const SessionSearchMapTooltip = ({ sessionSearchFilter, testingSiteId }) => {
  const { lang, countryEmoIso2 } = useRouter();
  const [isRegisterButtonClicked, setRegisterButtonClicked] = useState(false);
  const { getPromoCodeOrVoucher } = useBasketCookie();
  const promoCodeOrVoucher = getPromoCodeOrVoucher();

  const filter = {
    testTypeIds: sessionSearchFilter.testTypeIds,
    testingSiteId: testingSiteId,
    date: sessionSearchFilter.date,
  };

  const {
    data: { openSessionsSearch },
    fetchMore,
    loading,
  } = useQuery(OPEN_SESSIONS_SEARCH, {
    variables: {
      filter,
      country: countryEmoIso2,
      lang,
      discount:
        promoCodeOrVoucher?.discountCode && !promoCodeOrVoucher?.isVoucher
          ? { discountCode: promoCodeOrVoucher.discountCode, referrer: promoCodeOrVoucher.referrer }
          : null,
      voucher: promoCodeOrVoucher?.isVoucher ? promoCodeOrVoucher.discountCode : null,
    },
  });

  if (loading || openSessionsSearch === null) {
    return <SessionSearchMapTooltipSkeleton />;
  }

  let loadingMore = false;

  const onLoadMore = async () => {
    if (loadingMore) {
      return false;
    }
    loadingMore = true;

    await fetchMore({
      variables: {
        filter,
        offset: sessions.size,
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;
        loadingMore = false;

        return {
          openSessionsSearch: {
            pageInfo: fetchMoreResult.openSessionsSearch.pageInfo,
            items: [...prev.openSessionsSearch.items, ...fetchMoreResult.openSessionsSearch.items],
            __typename: prev.openSessionsSearch.__typename,
          },
        };
      },
    });

    return true;
  };

  const { items = [], pageInfo } = openSessionsSearch;
  const sessions = fromJS(items);

  const testingSite = sessions.get(0).get('testingSite');

  return (
    <div className="search-session-map__tooltip">
      <div className={'search-session-map__tooltip-address'}>
        <Address
          address={testingSite.get('address')}
          addressName={testingSite.get('name')}
          wheelchairAccess={testingSite.get('wheelchairAccess')}
        />
      </div>
      <Carousel className={'search-session-map__tooltip-slider'} itemNumber={pageInfo.total} onLoadMore={onLoadMore}>
        {sessions.map((session, key) => {
          return (
            <SessionSearchItem
              isRegisterButtonClicked={isRegisterButtonClicked}
              setRegisterButtonClicked={setRegisterButtonClicked}
              key={key}
              session={session}
              inMap={true}
            />
          );
        })}
      </Carousel>
    </div>
  );
};

SessionSearchMapTooltip.prototype = {
  sessionSearchFilter: object.isRequired,
  testingSiteId: number.isRequired,
};

export default React.memo(
  SessionSearchMapTooltip,
  (
    { sessionSearchFilter: prevSessionSearchFilter, testingSiteId: prevTestingSiteId },
    { sessionSearchFilter: nextSessionSearchFilter, testingSiteId: nextTestingSiteId },
  ) => {
    return (
      fromJS(prevSessionSearchFilter).equals(fromJS(nextSessionSearchFilter)) && prevTestingSiteId === nextTestingSiteId
    );
  },
);
