import React from 'react';
import PropTypes from 'prop-types';

import { Link } from '@ets-global/b2c-website-ui';
import { PrismicRichText } from '../../../Base';
import { useDataLayer } from '../../../../hooks';
import { isPdfUrl } from '../../../../@ets-global/helper/urlChecker';

import './styles.scss';

const ECLinksBlock = ({ title, subtitle, linkItems }) => {
  const { pushEventPdfDownloaded } = useDataLayer();

  const handleClick = (item) => {
    if (isPdfUrl(item.link.to)) {
      pushEventPdfDownloaded('Test LP', item.title);
    }
  };

  return (
    <div className="ec-links-block">
      <div className="container container--padding">
        <p className="ec-links-block__title">{title}</p>
        {subtitle && <p className="ec-links-block__subtitle">{subtitle}</p>}
        <div className="ec-links-block__links">
          {linkItems.map((linkItem, key) => (
            <div key={key} className="ec-links-link-item">
              <p className="ec-links-link-item__title">{linkItem?.title}</p>
              {linkItem?.description && (
                <PrismicRichText className="ec-links-link-item__description" richText={linkItem.description} />
              )}
              {linkItem?.link?.to && linkItem?.link?.label && (
                <Link
                  icon="arrow"
                  to={linkItem?.link?.to}
                  onClick={() => handleClick(linkItem)}
                  className="ec-links-link-item__link"
                >
                  {linkItem?.link?.label}
                </Link>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

ECLinksBlock.prototype = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  linkItems: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      link: PropTypes.shape({
        label: PropTypes.string.isRequired,
        to: PropTypes.string.isRequired,
      }).isRequired,
    }),
  ).isRequired,
};

export default ECLinksBlock;
