import React from 'react';
import PropTypes from 'prop-types';
import { darkenHexAndToStringHSL } from '../../../../helpers/color';

import './styles.scss';

const ECStepsBlock = ({ title, steps = [], stepColor }) => (
  <div className="ec-steps-block">
    <p className="ec-steps-block__title">{title}</p>
    <div className="ec-steps-block__steps">
      {steps.map((step, index) => (
        <div
          key={index}
          className="step-item"
          style={{ backgroundColor: darkenHexAndToStringHSL(stepColor, 15 * index) }}
        >
          <span className="step-item__index">{index + 1}</span>
          <span className="step-item__text">{step}</span>
        </div>
      ))}
    </div>
  </div>
);

ECStepsBlock.propTypes = {
  title: PropTypes.string.isRequired,
  steps: PropTypes.arrayOf(PropTypes.string).isRequired,
  stepColor: PropTypes.string,
};

export default ECStepsBlock;
