import React from 'react';
import { Trans } from 'react-i18next';
import PropTypes from 'prop-types';

import NoSessionForNonProctored from './noSessionForNonProctored';

const NoOpenSessionsSearch = ({ reloadSessionSearchPage, isEproctored }) => (
  <div className={'search-session-main__content'}>
    <div className="search-session-main__no-results">
      {isEproctored ? (
        <p className="search-session-main__no-results-paragraph">
          <Trans>session-search.main.no-result.eproctored</Trans>
        </p>
      ) : (
        <NoSessionForNonProctored reloadSessionSearchPage={reloadSessionSearchPage} />
      )}
    </div>
  </div>
);

NoOpenSessionsSearch.propTypes = {
  reloadSessionSearchPage: PropTypes.func.isRequired,
};

export default NoOpenSessionsSearch;
