import React, { useState, useRef, useEffect } from 'react';
import { fromJS } from 'immutable';
import PropTypes from 'prop-types';

import Button from '@ets-global/b2c-website-ui/Button';

import PushSlider from './PushSlider';
import Carousel from '../../Carousel';
import Picture from '../../Element/Picture';
import PaginationItem from './PaginationItem';
import HasSessionsAvailableWithItems from '../../../containers/HasSessionsAvailable/HasSessionsAvailableWithItems';
import { isBrowser, isSsr } from '../../../helpers/ssr';

import './styles.scss';

const HomeSlider = ({ slides }) => {
  const carouselRef = useRef();
  const [slideIndex, setSlideIndex] = useState(0);
  const [displayFull, setDisplayFull] = useState(false);

  slides = fromJS(slides);
  const slidesCount = slides.size;

  const onChangeSlide = () => {
    carouselRef.current.handleNext();
    if (slideIndex + 1 === slides.size) {
      setSlideIndex(0);

      return;
    }

    setSlideIndex(slideIndex + 1);
  };

  const onSetSlide = (index) => {
    carouselRef.current.switchPage(index);
    setSlideIndex(index);
  };

  const displaySlide = (slide, key = 0) => {
    const { label: linkLabel, ...link } = slide.get('link').toJS();

    const testTypeIds = slide.get('testTypeFamily')
      ? slide.toJS().testTypeFamily.testTypes.map((testType) => testType.id)
      : [];

    return (
      <div key={key} className="slider-home__slider-item">
        <div className="container">
          <div className="slider-home__slider-item-content">
            <h2 className="slider-home__slider-item-subtitle">{slide.get('marketingHook')}</h2>
            <h2 className="slider-home__slider-item-title">{slide.get('title')}</h2>
            {linkLabel && link && <Button {...link}>{linkLabel}</Button>}
          </div>
          {slide.get('testTypeFamily') && (
            <HasSessionsAvailableWithItems testTypeIds={testTypeIds}>
              {({ hasSessions, sessions }) =>
                hasSessions ? <PushSlider nextSession={sessions[0]} testTypesId={testTypeIds} /> : ''
              }
            </HasSessionsAvailableWithItems>
          )}
        </div>
        <Picture
          className={'slider-home__slider-item-picture'}
          images={slide.get('images').toJS()}
          forcePreloadImage={true}
        />
      </div>
    );
  };

  useEffect(() => {
    setTimeout(() => setDisplayFull(!isSsr), 1000);
  }, []);

  let itemWidth = 0;
  if (isBrowser) {
    itemWidth = window.outerWidth;
  }

  return (
    <section className="slider-home slider-home--has-push-slider">
      <div className="slider-home__slider swiper-container">
        <div className="swiper-wrapper">
          {slidesCount > 1 &&
            (displayFull ? (
              <Carousel
                ref={carouselRef}
                infinityLoop={true}
                autoWidth={true}
                itemWidth={itemWidth}
                displayNav={false}
                elementMargin={0}
                onTouchEnd={(index) => setSlideIndex(index)}
              >
                {slides.map((slide, key) => {
                  return displaySlide(slide, key);
                })}
              </Carousel>
            ) : (
              displaySlide(slides.first())
            ))}
          {slidesCount === 1 && displaySlide(slides.first())}
        </div>
      </div>
      {slidesCount > 1 && displayFull && (
        <div className="slider-home__mini-slider swiper-container">
          <div className="swiper-wrapper container">
            {slides.map((slide, key) => (
              <PaginationItem
                key={key}
                currentIndex={key}
                active={slideIndex === key}
                onClick={onSetSlide}
                onChangeSlide={onChangeSlide}
                marketingHook={slide.get('marketingHook')}
              />
            ))}
          </div>
        </div>
      )}
    </section>
  );
};

HomeSlider.propTypes = {
  slides: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      marketingHook: PropTypes.string,
      link: PropTypes.shape({
        label: PropTypes.string,
        to: PropTypes.string,
      }),
      images: PropTypes.array,
    }),
  ),
};

export default HomeSlider;
