import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { fromJS } from 'immutable';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';

import { PREPTOOLS_VERSIONS } from '../../graphql/queries';
import PrepTools from '../../components/PrepTools';
import { useBasketCookie, useQuery, useRouter } from '../../hooks';
import { PurchaseContext } from '../../store/Purchase';
import InjectHtml from '../../components/InjectHtml';
import HasPreptools from '../HasPreptools';

import './styles.scss';

const PrepToolsContainer = ({
  testTypeFamily,
  displayNameOfTestTypeFamily = true,
  displayOnlinePrepTool = true,
  displayBookPrepTool = true,
}) => {
  const { lang, countryEmoIso2 } = useRouter();
  const { getPromoCodeOrVoucher } = useBasketCookie();
  const { t } = useTranslation();
  const promoCodeOrVoucher = getPromoCodeOrVoucher();

  const {
    data: { preparationToolVersion = [] },
    loading,
  } = useQuery(PREPTOOLS_VERSIONS, {
    variables: {
      country: countryEmoIso2,
      lang,
      preparationToolVersionInput: {
        discount:
          promoCodeOrVoucher?.discountCode && !promoCodeOrVoucher?.isVoucher
            ? { discountCode: promoCodeOrVoucher.discountCode, referrer: promoCodeOrVoucher.referrer }
            : null,
      },
    },
  });

  const { purchase, refetch } = useContext(PurchaseContext);

  const constraints = {
    testTypeFamilyName: yup
      .string()
      .required()
      .default(() => 'all'),
    book: yup.boolean().default(true),
    online: yup.boolean().default(true),
  };
  const schemaValidation = yup.object().shape(constraints);

  const filters = fromJS(schemaValidation.cast({ testTypeFamilyName: testTypeFamily.name }));

  return (
    <HasPreptools testTypeFamily={testTypeFamily}>
      {({ hasPrepTools }) =>
        hasPrepTools && (
          <>
            <div className={'container container--full prep-tools'}>
              <div className={'container container--padding'}>
                {displayNameOfTestTypeFamily && (
                  <InjectHtml
                    component={'h2'}
                    className="prep-tools__title"
                    html={t('product.prepare-for-test', { testTypeFamilyName: testTypeFamily.name })}
                  />
                )}
                <PrepTools
                  loading={loading}
                  preparationToolVersions={fromJS(preparationToolVersion)}
                  purchase={fromJS(purchase || {})}
                  filters={filters}
                  purchaseRefetch={refetch}
                  displayOnlinePrepTool={displayOnlinePrepTool}
                  displayBookPrepTool={displayBookPrepTool}
                />
              </div>
            </div>
          </>
        )
      }
    </HasPreptools>
  );
};

PrepToolsContainer.propTypes = {
  testTypeFamily: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    testTypes: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
      }),
    ).isRequired,
  }).isRequired,
  displayNameOfTestTypeFamily: PropTypes.bool,
  displayOnlinePrepTool: PropTypes.bool,
  displayBookPrepTool: PropTypes.bool,
};

const isEqual = ({ testTypeFamily: prevTestTypeFamily }, { testTypeFamily: nextTestTypeFamily }) =>
  prevTestTypeFamily.id === nextTestTypeFamily.id;

export default React.memo(PrepToolsContainer, isEqual);
