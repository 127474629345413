import React from 'react';
import PropTypes from 'prop-types';

import { PrismicRichText } from '../../../Base';

import './styles.scss';

const ECIconBulletPointListBlock = ({ title, subtitle, iconBulletPointItems }) => (
  <div className="ec-icon-bullet-point-list-block">
    <div className="container container--padding">
      <p className="ec-icon-bullet-point-list-block__title">{title}</p>
      {subtitle && <p className="ec-icon-bullet-point-list-block__subtitle">{subtitle}</p>}
      <div className="ec-icon-bullet-point-list-block__content">
        {iconBulletPointItems.map((iconItem, key) => (
          <div key={key} className="ec-icon-bullet-point-list-item">
            <div className="ec-icon-bullet-point-list-item__icon">
              <img src={iconItem?.icon?.url} alt={iconItem?.icon?.alt} />
            </div>
            {iconItem?.description && (
              <PrismicRichText
                className="ec-icon-bullet-point-list-item__description"
                richText={iconItem.description}
              />
            )}
          </div>
        ))}
      </div>
    </div>
  </div>
);

ECIconBulletPointListBlock.prototype = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  iconBulletPointItems: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.shape({
        url: PropTypes.string.isRequired,
        alt: PropTypes.string,
      }),
      description: PropTypes.array,
    }).isRequired,
  ).isRequired,
};

export default ECIconBulletPointListBlock;
