import React from 'react';
import SkeletonItem from '../../../SkeletonItem';

const SearchSessionChoiceSkeleton = () => {
  return (
    <div className={'search-session-choice search-session-choice--skeleton'}>
      <div className={'search-session-choice__block'}>
        <SkeletonItem style={{ width: '90%', height: '28px' }} />
        <SkeletonItem style={{ width: '100%', height: '42px', marginTop: '12px' }} />
        <SkeletonItem style={{ width: '80%', height: '80px', marginTop: '50px' }} />
        <SkeletonItem style={{ width: '80%', height: '80px', marginTop: '10px' }} />
      </div>
    </div>
  );
};

export default SearchSessionChoiceSkeleton;
