import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { PRACTICE_TEST_CANDIDATE_TYPE } from '../../constants';
import { Fields } from '@ets-global/b2c-website-ui';
import { GooglePlacesAutocompleteContainer } from '../../containers/GooglePlacesAutocomplete';

import './styles.scss';

const getSelectFieldsOptions = (options, labelPrefix, translate) => {
  options = options.map((option) => ({
    value: option,
    label: translate(`${labelPrefix}.${option.toLowerCase()}`),
  }));
  options.unshift({ value: '', label: '' });

  return options;
};

const PracticeTestConclusionFormB2C = ({ values, setFieldValue, resetForm, candidateType, options, touched }) => {
  const { t } = useTranslation();

  const schoolCityField = (
    <GooglePlacesAutocompleteContainer
      id="schoolCity"
      hasValue={touched?.['schoolCityInput'] || values?.schoolCityInput?.length > 0 || values?.schoolCity?.length > 0}
      label={t('practice-test-conclusion.form.school-city.label')}
      onInputValueChange={(value) => setFieldValue('schoolCityInput', value)}
      onSelectedItemChange={(selectedItem) => {
        setFieldValue('schoolCity', selectedItem.city);
      }}
      searchType="city"
    />
  );

  const highSchoolStudentCandidateTypeForm = (
    <>
      <GooglePlacesAutocompleteContainer
        id="city"
        hasValue={touched?.['cityInput'] || values?.cityInput?.length > 0 || values?.city?.length > 0}
        label={t('practice-test-conclusion.form.city.label')}
        onInputValueChange={(value) => setFieldValue('cityInput', value)}
        onSelectedItemChange={(selectedItem) => {
          setFieldValue('city', selectedItem.city);
        }}
        searchType="city"
      />
      {options?.classLevels && (
        <Fields.FormikFastField
          id="classLevel"
          name="classLevel"
          component={Fields.SelectField}
          label={t('practice-test-conclusion.form.class-level.label')}
          required={false}
          options={getSelectFieldsOptions(options.classLevels, 'practice-test.class-level', t)}
        />
      )}
      <Fields.FormikFastField
        id="schoolName"
        name="schoolName"
        component={Fields.TextField}
        label={t('practice-test-conclusion.form.school-name.label')}
        required={false}
      />
      {schoolCityField}
    </>
  );
  const studentCandidateTypeForm = (
    <>
      {options?.schoolTypes && (
        <Fields.FormikFastField
          id="schoolType"
          name="schoolType"
          component={Fields.SelectField}
          label={t('practice-test-conclusion.form.school-type.label')}
          required={false}
          options={getSelectFieldsOptions(options.schoolTypes, 'practice-test.school-type', t)}
        />
      )}
      <Fields.FormikFastField
        id="schoolName"
        name="schoolName"
        component={Fields.TextField}
        label={t('practice-test-conclusion.form.school-name.label')}
        required={false}
      />
      {schoolCityField}
      {options?.degreeLevels && (
        <Fields.FormikFastField
          id="degreeLevel"
          name="degreeLevel"
          component={Fields.SelectField}
          label={t('practice-test-conclusion.form.degrees-level.label')}
          required={false}
          options={getSelectFieldsOptions(options.degreeLevels, 'practice-test.degree-level', t)}
        />
      )}
    </>
  );

  return (
    <>
      {candidateType && (
        <Fields.FormikFastField
          id="candidateType"
          name="candidateType"
          component={Fields.SelectField}
          label={t('practice-test-conclusion.form.candidate-type.label')}
          required={true}
          options={getSelectFieldsOptions(candidateType, 'practice-test.candidate-type', t)}
          onChange={(e) => {
            resetForm();
            setFieldValue('isB2C', true);
            setFieldValue('candidateType', e.target.value);
          }}
        />
      )}
      {values.candidateType === PRACTICE_TEST_CANDIDATE_TYPE.HIGH_SCHOOL_STUDENT && highSchoolStudentCandidateTypeForm}
      {values.candidateType === PRACTICE_TEST_CANDIDATE_TYPE.STUDENT && studentCandidateTypeForm}
      {values.candidateType === PRACTICE_TEST_CANDIDATE_TYPE.WORKER && options?.activityStatus && (
        <Fields.FormikFastField
          id="activityStatus"
          name="activityStatus"
          component={Fields.SelectField}
          label={t('practice-test-conclusion.form.activity-status.label')}
          required={false}
          options={getSelectFieldsOptions(options.activityStatus, 'practice-test.activity-status', t)}
        />
      )}
      {values.candidateType && options?.reasons && (
        <Fields.FormikFastField
          id="reason"
          name="reason"
          component={Fields.SelectField}
          label={t('practice-test-conclusion.form.reason.label')}
          required={true}
          options={getSelectFieldsOptions(options.reasons, 'practice-test.reason', t)}
        />
      )}
      <p className="practice-test-conclusion__mentions">
        <sup>*</sup> <Trans>common.form.required.fields</Trans>
      </p>
    </>
  );
};

export default PracticeTestConclusionFormB2C;
