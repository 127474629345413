import React from 'react';
import PropTypes from 'prop-types';
import { Trans } from 'react-i18next';
import { useHistory } from 'react-router';

import Link from '@ets-global/b2c-website-ui/Link';
import Button from '@ets-global/b2c-website-ui/Button';
import { Price } from '@ets-global/b2c-website-ui/Price';
import Icon from '@ets-global/b2c-website-ui/Icon';

import { TEST_TYPE_FAMILY_COLOR } from '../../constants';
import { useBasketCookie, useNumberByLocale, useRouter } from '../../hooks';
import { PrismicRichText } from '../Base';
import { paths } from '../../paths';
import { setParamsSearch } from '../../helpers/router';

import './style.scss';

const PartnerPromoCodeBock = ({ product, ...discount }) => {
  const { partnerDomain, generatePath } = useRouter();
  const history = useHistory();
  const { setPromoCodeOrVoucher } = useBasketCookie();
  const numberByLocale = useNumberByLocale();
  const isPrepTools = product?.__typename === 'DiscountProductPrepTool';

  const navigateToDiscountPage = () => {
    const infoPromoCodeOrVoucher = {
      discountCode: discount.code,
      testTypeIds: [product?.testTypeId],
      prepToolIds: [product?.prepToolId],
      isCpf: true,
      referrer: partnerDomain,
      isVoucher: false,
    };
    setPromoCodeOrVoucher(infoPromoCodeOrVoucher);
    history.push(
      generatePath(
        isPrepTools ? paths.PREP_TOOLS : paths.SESSION_SEARCH,
        {},
        !isPrepTools ? setParamsSearch({ testTypeIds: infoPromoCodeOrVoucher.testTypeIds }) : null,
      ),
    );
  };

  const promoCodeHeaderBackgroundColor = product?.color ? TEST_TYPE_FAMILY_COLOR[product?.color] : '#074277';
  let discountValueForTranslation = product?.price ? numberByLocale({ value: product?.price?.discountAmount }) : null;
  if (product?.price?.discountType === 'PERCENT') {
    discountValueForTranslation = `${product?.price?.discountAmount}%`;
  }

  return (
    <div className="promocode">
      <div className="promocode__header" style={{ backgroundColor: promoCodeHeaderBackgroundColor }}>
        <Icon
          className="promocode__header-icon"
          style={{ fill: promoCodeHeaderBackgroundColor }}
          name={isPrepTools ? 'landingPagePrepTool' : 'landingPageTestType'}
        />
        <p className="promocode__header-title">{product?.title}</p>
      </div>
      <div className="promocode__content">
        {discount?.title && <div className="promocode__content-title">{discount.title}</div>}
        {product?.price && (
          <>
            <Price
              currency={product.price?.currency}
              price={product.price?.value}
              discountedPrice={product.price?.discountedPrice}
              isInsteadOfDisplayed={true}
            />
            <div className="promocode__content-price-subtitle">
              <Trans values={{ discount: discountValueForTranslation }}>
                landing-page-partner.discount-block.amount
              </Trans>
            </div>
          </>
        )}
        {discount?.subtitle && <div className="promocode__content-price-description">{discount.subtitle}</div>}
        {discount?.text && <PrismicRichText className="promocode__content-description" richText={discount.text} />}

        <div className="promocode__content-footer">
          {discount?.link && (
            <Link icon={'arrow'} className="promocode__content-footer--link" target="_blank" {...discount.link}>
              <span>{discount.link?.label}</span>
            </Link>
          )}
          <Button className="button--full" onClick={navigateToDiscountPage}>
            <Trans>
              {isPrepTools
                ? 'landing-page-partner.discount-block.preptool-button'
                : 'landing-page-partner.discount-block.button-testtype'}
            </Trans>
          </Button>
        </div>
      </div>
    </div>
  );
};

PartnerPromoCodeBock.propTypes = {
  code: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  text: PropTypes.arrayOf(PropTypes.object),
  link: PropTypes.object,
  product: PropTypes.shape({
    __typename: PropTypes.string,
    testTypeId: PropTypes.number,
    prepToolId: PropTypes.number,
    color: PropTypes.string,
    title: PropTypes.string,
    price: PropTypes.object,
  }),
};

export default PartnerPromoCodeBock;
