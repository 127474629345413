import * as yup from 'yup';

import { DEFAULT_SESSION_SEARCH_DISTANCE } from '../constants';
import { dateToISOString } from './Formatter/DateFormatter';

export const getValidatedFilter = (filter = {}, defaultValue = {}, resetAdditionalParams = false) => {
  let constraints = {
    testTypeIds: yup.array().of(yup.number()).required().default(defaultValue.testTypeIds),
    city: yup.string().default(defaultValue.city),
    region: yup.string().default(defaultValue.region),
    coordinates: yup
      .object()
      .shape({
        latitude: yup.number().required(),
        longitude: yup.number().required(),
      })
      .default(defaultValue.coordinates),
    date: yup
      .string()
      .matches(/([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))/)
      .required()
      .default(() => dateToISOString()),
    showMap: yup.boolean().default(false),
    offset: yup.number(),
    distance: yup.number().default(DEFAULT_SESSION_SEARCH_DISTANCE),
  };

  const additionalConstraints = {
    candidate: yup.object().shape({
      firstname: yup.string(),
      lastname: yup.string(),
      email: yup.string(),
      products: yup.object(),
    }),
    prepTools: yup.string(),
    discountCode: yup.string(),
    isCpf: yup.bool(),
  };

  for (let key in additionalConstraints) {
    if (resetAdditionalParams) {
      delete filter[key];
    } else if (filter[key]) {
      constraints[key] = additionalConstraints[key];
    }
  }

  const schemaValidation = yup.object().shape(constraints);

  try {
    return schemaValidation.cast(filter);
  } catch (error) {
    return schemaValidation.cast({});
  }
};

export const getSessionSearchFilter = (paramsSearch, defaultValue) => {
  let filter = getValidatedFilter(paramsSearch, defaultValue);

  return {
    testTypeIds: filter.testTypeIds,
    city: filter.city,
    region: filter.region,
    coordinates: filter.coordinates,
    date: filter.date,
    showMap: filter.showMap,
    offset: filter.offset,
    distance: filter.distance,
  };
};

export const checkTestTypeIdsIsValid = (testTypeIds, testTypeFamilies) => {
  const testTypeIdsAvailable = testTypeFamilies.reduce((testTypeIds, testTypeFamily) => {
    testTypeIds.push(...testTypeFamily.testTypes.map((testType) => testType.id));

    return testTypeIds;
  }, []);

  return [testTypeIdsAvailable, testTypeIds].reduce((a, b) => a.filter((c) => b.includes(c))).length > 0;
};

export const checkEProctoring = (testTypeIds, testTypeFamilies) => {
  for (const testTypeFamily of testTypeFamilies) {
    if (
      testTypeFamily.testTypes.filter(
        (testType) => testType.isEproctored && (!testTypeIds || testTypeIds.includes(testType.id))
      ).length > 0
    ) {
      return true;
    }
  }

  return false;
};
