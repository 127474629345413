import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Trans } from 'react-i18next';
import classNames from 'classnames';

import { CEFRLevel } from '../../constants';

import './styles.scss';

const PanelCEFRLevel = ({ currentLevel }) => {
  const levels = Object.values(CEFRLevel);
  const [openItems, setOpenItems] = useState([currentLevel]);

  const toggleItem = (key) => {
    const newOpenItems = openItems.includes(key)
      ? openItems.filter((currentKey) => currentKey !== key)
      : [...openItems, key];
    setOpenItems(newOpenItems);
  };

  return (
    <div className="panel-cefr-level">
      <h3 className="panel-cefr-level__title">
        <Trans>panel.cefr-level.title</Trans>
      </h3>
      <ul>
        {levels.map((level, key) => {
          const levelKey = level.toLowerCase();
          const suffixTranslationKey = `panel.cefr-level.${levelKey}`;

          return (
            <li key={key}>
              <div className="panel-cefr-level__item" onClick={() => toggleItem(levelKey)} aria-hidden="true">
                <div className="panel-cefr-level__item-summary">
                  <div className="panel-cefr-level__item-arrow">
                    {currentLevel === levelKey && <div className="panel-cefr-level__item-arrow-with-image" />}
                  </div>
                  <div
                    className={classNames('panel-cefr-level__item-level', {
                      'panel-cefr-level__item-level--selected': currentLevel === levelKey,
                    })}
                  >
                    {level}
                  </div>
                  <div className="panel-cefr-level__item-title">
                    <Trans>{suffixTranslationKey}.user-level</Trans>
                    <br />
                    <span className="panel-cefr-level__item-title--bold">
                      <Trans>{suffixTranslationKey}.user-ability</Trans>
                    </span>
                  </div>
                </div>
                <i
                  className={classNames('panel-cefr-level__item-dropdown', {
                    'panel-cefr-level__item-dropdown--up': openItems.includes(levelKey),
                    'panel-cefr-level__item-dropdown--down': !openItems.includes(levelKey),
                  })}
                />
              </div>
              <div
                className={classNames('panel-cefr-level__item-content', {
                  'panel-cefr-level__item-content--active': openItems.includes(levelKey),
                })}
              >
                <Trans>{suffixTranslationKey}.level-description</Trans>
              </div>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

PanelCEFRLevel.propTypes = {
  currentLevel: PropTypes.string,
};

export default PanelCEFRLevel;
