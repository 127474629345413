import { Button } from '@ets-global/b2c-website-ui';
import classNames from 'classnames';
import { fromJS } from 'immutable';
import capitalize from 'lodash/capitalize';
import kebabCase from 'lodash/kebabCase';
import omit from 'lodash/omit';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { Trans } from 'react-i18next';
import { useHistory, useLocation, useParams } from 'react-router';
import slugify from 'slugify/slugify';

import { DEFAULT_SESSION_SEARCH_DISTANCE } from '../../constants';
import { COORDINATES } from '../../graphql/queries';
import { getParams, setParamsSearch } from '../../helpers/router';
import { checkEProctoring, getSessionSearchFilter, getValidatedFilter } from '../../helpers/sessionSearch';
import { useBasketCookie, useRouter } from '../../hooks';
import useQuery from '../../hooks/useQuery';
import { paths } from '../../paths';
import { AppContext } from '../../store/App';
import { useDateToString } from '../Element/DateToString';
import HeaderMeta from '../HeaderMeta';
import SessionSearchMain from './Main';
import SideBar from './SideBar';

const SessionSearch = ({ testTypeFamilies = [], loading }) => {
  const location = useLocation();
  const params = useParams();
  const history = useHistory();
  const { generateUrl, generatePath } = useRouter();
  const { getPromoCodeOrVoucher } = useBasketCookie();
  const promoCodeOrVoucher = getPromoCodeOrVoucher();
  const { city, region, coordinates } = useContext(AppContext);
  const DateToString = useDateToString();
  const selectedCoordinate = useRef(null);

  const defaultValueFilter = {
    testTypeIds: testTypeFamilies.length > 0 ? testTypeFamilies[0].testTypes.map((testType) => testType.id) : [],
    city,
    region,
    coordinates: { longitude: 0, latitude: 0 },
    distance: DEFAULT_SESSION_SEARCH_DISTANCE,
  };

  let paramsSearch = getParams(location.search);
  const isEproctored = checkEProctoring(paramsSearch.testTypeIds, testTypeFamilies);
  if (!isEproctored && params.city && params.region) {
    paramsSearch = {
      ...paramsSearch,
      city: params.city.split('?')[0],
      region: params.region,
    };
  }

  const [showTestTypeFamilies, setShowTestTypeFamilies] = useState(!promoCodeOrVoucher?.isCpf);

  const skipReversGeolocation = !params.city || !params.region || !!selectedCoordinate.current;
  let variables = {};
  if (!skipReversGeolocation) {
    variables = { city: slugify(params.city), region: slugify(params.region) };
  }

  const {
    data: { coordinates: paramCoordinate = null },
    loading: coordinateLoading,
  } = useQuery(COORDINATES, {
    variables,
    skip: skipReversGeolocation,
  });

  if (!skipReversGeolocation && !coordinateLoading) {
    selectedCoordinate.current = { latitude: paramCoordinate.latitude, longitude: paramCoordinate.longitude };
  }

  if (
    skipReversGeolocation &&
    coordinates.longitude !== 0 &&
    coordinates.latitude !== 0 &&
    !selectedCoordinate.current
  ) {
    selectedCoordinate.current = coordinates;
  }

  let sessionSearchFilter = getSessionSearchFilter(
    { coordinates: selectedCoordinate.current || undefined, distance: defaultValueFilter.distance, ...paramsSearch },
    defaultValueFilter,
  );

  const testTypeIdsAvailable = testTypeFamilies.reduce((testTypeIds, testTypeFamily) => {
    testTypeIds.push(...testTypeFamily.testTypes.map((testType) => testType.id));

    return testTypeIds;
  }, []);

  sessionSearchFilter.testTypeIds = sessionSearchFilter.testTypeIds.filter((testtype) =>
    testTypeIdsAvailable.includes(testtype),
  );

  let testTypeFamilyName = '';
  if (sessionSearchFilter.testTypeIds) {
    testTypeFamilyName = testTypeFamilies.reduce((acc, testTypeFamily) => {
      return testTypeFamily.testTypes.map((testType) => testType.id).includes(sessionSearchFilter.testTypeIds[0])
        ? testTypeFamily.name
        : acc;
    }, '');
  }

  const generateSearchPath = (params, relative = true) => {
    let returnFunction = generatePath;
    if (!relative) {
      returnFunction = generateUrl;
    }

    let queryParams = {
      testTypeIds: params.testTypeIds,
      date: params.date,
    };

    if (!isEproctored) {
      queryParams = {
        ...queryParams,
        showMap: params.showMap,
        distance: params.distance,
      };
    }

    let locationParams = {};
    const { city, region } = params;
    if (city && region) {
      locationParams = {
        city: kebabCase(city),
        region: kebabCase(region),
      };
    }

    return returnFunction(paths.SESSION_SEARCH, locationParams, setParamsSearch(queryParams));
  };

  // Fix url with default params if missing
  useEffect(() => {
    if (testTypeFamilies.length > 0 && city && region) {
      if (
        !checkEProctoring(paramsSearch.testTypeIds, testTypeFamilies) &&
        (typeof paramsSearch.showMap === 'undefined' ||
          !paramsSearch.testTypeIds ||
          !paramsSearch.city ||
          !paramsSearch.region ||
          !paramsSearch.date)
      ) {
        history.replace(generateSearchPath(getValidatedFilter(paramsSearch, defaultValueFilter)));
      } else if (!paramsSearch.testTypeIds || !paramsSearch.city || typeof paramsSearch.showMap === 'undefined') {
        const { city, region, ...filter } = defaultValueFilter;
        history.replace(generateSearchPath(getValidatedFilter(paramsSearch, filter)));
      }
    }
  }, [testTypeFamilies, city]);

  const toggleBlocks = () => {
    setShowTestTypeFamilies(!showTestTypeFamilies);
  };

  const pushToUrl = (params) => {
    // Reset params for automatic registration if testTypeIds are changed
    const resetAdditionalParams = !!params.testTypeIds;
    if (params.coordinates) {
      selectedCoordinate.current = params.coordinates;
    }

    history.push(
      generateSearchPath({
        ...paramsSearch,
        ...getSessionSearchFilter({ ...sessionSearchFilter, ...params }, defaultValueFilter, resetAdditionalParams),
      }),
    );
  };

  if (!loading && testTypeFamilies.length === 0) {
    return (
      <div className={classNames('checkout-summary-layout')}>
        <div className="checkout-summary-layout__inner">
          <main className="checkout-summary-layout__main checkout-summary-layout__main--centered">
            <h3>
              <Trans>session-search.main.no-testtype-families.firstline</Trans>
            </h3>
            <Button to={paths.LANDING_CONTACT}>
              <Trans>session-search.main.no-testtype-families.secondline</Trans>
            </Button>
          </main>
        </div>
      </div>
    );
  }

  return (
    <div className="search-session__inner">
      <HeaderMeta
        page={'session-search'}
        meta={{
          image: {
            url: require('../../assets/images/banner/session-search/mobile.jpg'),
            dimensions: {
              width: 350,
              height: 350,
            },
          },
          canonical:
            location.search && generateSearchPath(omit(sessionSearchFilter, ['date', 'offset', 'distance']), false),
        }}
        transParam={{
          testTypeFamilyName: testTypeFamilyName,
          date: DateToString({ date: sessionSearchFilter.date, format: 'MMMM YYYY' }),
          city: capitalize(sessionSearchFilter.city),
        }}
      />
      <SideBar
        testTypeFamilies={testTypeFamilies}
        showTestTypeFamilies={showTestTypeFamilies}
        toggleBlocks={toggleBlocks}
        sessionSearchFilter={sessionSearchFilter}
        pushToUrl={pushToUrl}
        loading={loading}
        isChangeTestTypeFilterModalDisplayed={Boolean(promoCodeOrVoucher)}
        canChangeTestTypeFilter={!promoCodeOrVoucher?.isCpf}
      />
      <SessionSearchMain
        className={classNames('search-session__main', {
          'search-session__main--active': !showTestTypeFamilies,
        })}
        loading={!location.search || loading}
        sessionSearchFilter={sessionSearchFilter}
        onFilterChange={pushToUrl}
        title={'session-search.main.title'}
        toggleBlocks={toggleBlocks}
        isEproctored={isEproctored}
        showReturnButton={!promoCodeOrVoucher?.isCpf}
      />
    </div>
  );
};

SessionSearch.propTypes = {
  testTypeFamilies: PropTypes.array,
  loading: PropTypes.bool.isRequired,
};

export default React.memo(
  SessionSearch,
  (
    { testTypeFamilies: prevTestTypeFamily, loading: prevLoading },
    { testTypeFamilies: nextTestTypeFamily, loading: nextLoading },
  ) =>
    prevTestTypeFamily && fromJS(prevTestTypeFamily).equals(fromJS(nextTestTypeFamily)) && prevLoading === nextLoading,
);
