import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import SkeletonItem from '../../../../SkeletonItem';
import { Button } from '@ets-global/b2c-website-ui';

import './styles.scss';

const SessionSearchItemSkeleton = ({ inMap = false, className = '' }) => {
  return (
    <div
      className={classNames(
        'search-session-item',
        'search-session-item--skeleton',
        inMap && 'search-session-item--in-map',
        className,
      )}
    >
      <SkeletonItem className="search-session-item__date" />
      <div className="search-session-item__content">
        <div className="search-session-item__informations">
          <SkeletonItem style={{ width: '90%', height: '25px' }} />
          <SkeletonItem style={{ width: '50%', height: '25px' }} />
        </div>
        <div className="search-session-item__register">
          <Button className={'skeleton-item'}>
            <div style={{ width: '10px', height: '16px' }} />
          </Button>
        </div>
      </div>
    </div>
  );
};

SessionSearchItemSkeleton.propTypes = {
  inMap: PropTypes.bool,
  className: PropTypes.string,
};

export default SessionSearchItemSkeleton;
