import './styles.scss';

import { Link, Loader } from '@ets-global/b2c-website-ui';
import classNames from 'classnames';
import { fromJS } from 'immutable';
import React, { useContext, useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { ArticleBlock, MoreLinks } from '../../components/Content';
import { useDateToString } from '../../components/Element/DateToString';
import HeaderMeta from '../../components/HeaderMeta';
import PanelCEFRLevel from '../../components/PanelCEFRLevel';
import { LocalizedRedirect } from '../../components/Routing';
import TestBlock from '../../components/TestBlock';
import { ECBlockType, TEST_TYPE_FAMILY_COLOR } from '../../constants';
import MarketingBannerContainer from '../../containers/MarketingBanner';
import { RESULT_PAGE_PRACTICE_TEST } from '../../graphql/queries';
import { useDataLayer, useRouter } from '../../hooks';
import useQuery from '../../hooks/useQuery';
import { paths } from '../../paths';
import { AuthContext } from '../../store/Auth';

const PracticeTestResultPage = () => {
  const {
    generatePath,
    currentRoute: {
      params: { locale: lang, country, slug, sessionId },
    },
  } = useRouter();
  const { user } = useContext(AuthContext);
  const { t } = useTranslation();
  const DateToString = useDateToString();
  const { pushEventLoadedPlacementTestFunnel } = useDataLayer();

  const {
    data: { resultPagePracticeTest },
    loading,
  } = useQuery(RESULT_PAGE_PRACTICE_TEST, {
    variables: { lang, country, sessionId: parseInt(sessionId, 10) },
  });

  useEffect(() => {
    pushEventLoadedPlacementTestFunnel({
      stepName: 'results page',
      stepNumber: '24',
    });
  }, [sessionId]);

  if (loading) {
    return <Loader />;
  }

  if (!resultPagePracticeTest) {
    return (
      <LocalizedRedirect
        to={{
          pathname: generatePath(paths.PRACTICE_TEST_CONCLUSION_FORM, {
            slug,
            sessionId,
          }),
        }}
      />
    );
  }

  const color = resultPagePracticeTest.testTypeFamily.color
    ? TEST_TYPE_FAMILY_COLOR[resultPagePracticeTest.testTypeFamily.color]
    : null;
  const blocks = fromJS(resultPagePracticeTest.blocks).map((block) => {
    switch (block.get('__typename')) {
      case ECBlockType.ECSessionsBlock:
        block = block.set('testTypeFamilies', [resultPagePracticeTest.testTypeFamily]);
        break;
      case ECBlockType.ECPreparationToolsBlock:
        block = block
          .set('testTypeFamily', resultPagePracticeTest.testTypeFamily)
          .set('displayNameOfTestTypeFamily', false);
        break;
    }

    return block;
  });

  return (
    <main className={classNames('main', 'practice-test-result-page')}>
      <HeaderMeta page={'result-page-practice-test'} meta={resultPagePracticeTest.meta} />
      <div className="practice-test-result-page__main">
        <div className="container container--padding practice-test-result-page__heading">
          <p className="practice-test-result-page__heading-title">
            <Trans values={{ firstname: user.firstname }}>result-page-practice-test.introduction.test-finished</Trans>
          </p>
          <p className="practice-test-result-page__heading-subtitle">
            <Trans>result-page-practice-test.introduction.your-result-is</Trans>
          </p>
        </div>
        <div className="practice-test-result-page__level-background" />
        <div className="container container--padding-bottom">
          <div className={'practice-test-result-page__level'}>
            <div className={'practice-test-result-page__level-title'}>
              <Trans>
                {resultPagePracticeTest.CEFRLevel
                  ? 'result-page-practice-test.introduction.your-level-is'
                  : 'result-page-practice-test.introduction.your-below-level-is'}
              </Trans>
            </div>
            {resultPagePracticeTest.CEFRLevel && (
              <div className={'practice-test-result-page__level-logo'}>{resultPagePracticeTest.CEFRLevel}</div>
            )}
            <div className={'practice-test-result-page__level-content'}>
              <p className={'practice-test-result-page__level-content--bold'}>
                <Trans>
                  {resultPagePracticeTest.CEFRLevel
                    ? 'result-page-practice-test.introduction.level-explanation-title'
                    : 'result-page-practice-test.introduction.below-level-explanation-title'}
                </Trans>
              </p>
              <p>
                <Trans>
                  result-page-practice-test.introduction.level-explanation-
                  {resultPagePracticeTest.CEFRLevel ? resultPagePracticeTest.CEFRLevel.toLowerCase() : 'below-a1'}
                </Trans>
              </p>
              <p
                dangerouslySetInnerHTML={{
                  __html: t('result-page-practice-test.introduction.test-date', {
                    date: DateToString({
                      date: resultPagePracticeTest.dateOfTest,
                      format: 'dddd LL',
                    }),
                    time: DateToString({
                      date: resultPagePracticeTest.dateOfTest,
                      format: 'LT',
                    }),
                  }),
                }}
              />
            </div>
            <div className={'practice-test-result-page__level--clear'} />
          </div>
        </div>
        <div className="container">
          <h2 className="practice-test-result-page__title">
            <Trans>result-page-practice-test.content.test-result-correlation</Trans>
          </h2>
          <div className="practice-test-result-page__row">
            <div className="practice-test-result-page__column practice-test-result-page__column--first">
              <PanelCEFRLevel currentLevel={resultPagePracticeTest.CEFRLevel?.toLowerCase()} />
            </div>
            <div className="practice-test-result-page__column practice-test-result-page__column--last">
              <TestBlock
                title={resultPagePracticeTest.summaryBox.title}
                richText={fromJS(resultPagePracticeTest.summaryBox.content)}
                color={color}
              >
                <Link icon={'arrow'} {...resultPagePracticeTest.summaryBox.link}>
                  {resultPagePracticeTest.summaryBox.link.label}
                </Link>
              </TestBlock>
            </div>
          </div>
        </div>
        <MarketingBannerContainer documentId={resultPagePracticeTest.id} />
        {blocks.map((block, key) => (
          <ArticleBlock key={key} block={fromJS(block)} />
        ))}
      </div>
      <aside>
        {(resultPagePracticeTest.links.length > 0 || resultPagePracticeTest.documents.length > 0) && (
          <MoreLinks
            links={fromJS(resultPagePracticeTest.links)}
            documents={fromJS(resultPagePracticeTest.documents)}
          />
        )}
      </aside>
    </main>
  );
};

export default PracticeTestResultPage;
