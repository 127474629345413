/*global google:true*/

import React, { useState, useEffect } from 'react';
import { fromJS } from 'immutable';
import { Loader } from '@ets-global/b2c-website-ui';
import PropTypes from 'prop-types';

import GoogleInteractiveMap from '../../../Google/InteractiveMap';
import { TESTING_SITES } from '../../../../graphql/queries';
import SessionSearchMapTooltip from './SessionSearchMapTooltip';
import SkeletonItem from '../../../SkeletonItem';
import { useRouter, useQuery } from '../../../../hooks';
import { paths } from '../../../../paths';
import { getParams, setParamsSearch } from '../../../../helpers/router';
import { getSessionSearchFilter } from '../../../../helpers/sessionSearch';
import { useHistory, useLocation } from 'react-router';

import NoOpenSessionsSearch from '../NoResult/noOpenSessionsSearch';
import './style.scss';

const SessionSearchMap = ({ sessionSearchFilter = {}, isEproctored }) => {
  const { coordinates, city, region, testTypeIds, date, distance } = sessionSearchFilter;

  const [testingSiteSelected, selectTestingSite] = useState(null);
  const [displayOverlay, setDisplayOverlay] = useState(false);
  const { countryEmoIso2, generatePath } = useRouter();
  const [defaultZoom, setDefaultZoom] = useState(distance === 0 ? 4 : 10);

  const history = useHistory();
  const location = useLocation();
  const markerZoom = 15;
  const scaleConst = 156543.03392;

  const getRadius = (latitude, zoom) =>
    ((scaleConst * Math.cos((latitude * Math.PI) / 180)) / Math.pow(2, zoom)) * 0.005;

  const testingSitesFilter = {
    coordinates,
    testTypeIds,
    date,
    distance: distance === 0 ? distance : getRadius(coordinates.latitude, defaultZoom),
  };

  const variables = { filters: testingSitesFilter, country: countryEmoIso2 };

  const {
    data: { testingSites = [] },
    client,
    loading,
  } = useQuery(TESTING_SITES, { variables });

  const markers = testingSites.map((testingSite) => ({
    longitude: testingSite.address.coordinates.longitude,
    latitude: testingSite.address.coordinates.latitude,
  }));

  useEffect(() => {
    setDefaultZoom(sessionSearchFilter.distance === 0 ? 4 : 10);

    return () => {
      selectTestingSite(null);
    };
  }, [sessionSearchFilter, countryEmoIso2]);

  const reloadSessionSearchPage = () => {
    history.push(
      generatePath(
        paths.SESSION_SEARCH,
        { city, region },
        setParamsSearch(getSessionSearchFilter({ ...getParams(location.search), distance: 0 })),
      ),
    );
    setDefaultZoom(4);
  };

  const onChange = async (map, client) => {
    const { center, zoom } = map;

    setDisplayOverlay(true);
    const {
      data: { testingSites = [] },
    } = await client.query({
      query: TESTING_SITES,
      variables: {
        filters: {
          ...testingSitesFilter,
          coordinates: { latitude: center.lat(), longitude: center.lng() },
          distance: getRadius(center.lat(), zoom),
        },
        country: countryEmoIso2,
      },
    });

    testingSites.map((testingSite) => {
      const {
        address: {
          coordinates: { latitude, longitude },
        },
        id,
      } = testingSite;
      let marker = new google.maps.Marker({
        position: { lat: parseFloat(latitude), lng: parseFloat(longitude) },
        icon: require('../../../../assets/images/marker.png'),
        map,
      });
      marker.addListener('click', () => {
        const { lat, lng } = marker.getPosition();
        map.setCenter({ lat: lat() - 0.003, lng: lng() });
        map.setZoom(markerZoom);
        onMarkerClicked(id);
      });

      return marker;
    });
    setDisplayOverlay(false);
  };

  const onMarkerClicked = (testingSiteId) => {
    selectTestingSite(null);
    selectTestingSite(testingSiteId);
  };

  if (loading) {
    return <SkeletonItem style={{ width: '100%', height: '535px', marginTop: '20px' }} />;
  }

  if (testingSites.length === 0) {
    return <NoOpenSessionsSearch reloadSessionSearchPage={reloadSessionSearchPage} isEproctored={isEproctored} />;
  }

  return (
    <div id="session-map" className="search-session-map">
      {displayOverlay && <Loader className={'search-session-map__loader'} />}
      <GoogleInteractiveMap
        center={{ lat: coordinates.latitude, lng: coordinates.longitude }}
        onChange={(map) => onChange(map, client)}
        zoom={defaultZoom}
        markers={fromJS(markers)}
        onMarkerClicked={(id) => onMarkerClicked(id)}
        className={'search-session-map__iframe'}
      />
      {testingSiteSelected && (
        <SessionSearchMapTooltip sessionSearchFilter={sessionSearchFilter} testingSiteId={testingSiteSelected} />
      )}
    </div>
  );
};

SessionSearchMap.propType = {
  sessionSearchFilter: PropTypes.object,
};

export default React.memo(
  SessionSearchMap,
  ({ sessionSearchFilter: prevSessionSearchFilter }, { sessionSearchFilter: nextSessionSearchFilter }) => {
    return fromJS(prevSessionSearchFilter).equals(fromJS(nextSessionSearchFilter));
  },
);
