import PropTypes from 'prop-types';

import { PREPTOOLS_VERSIONS } from '../../graphql/queries';
import { useRouter, useQuery, useBasketCookie } from '../../hooks';

const HasPreptools = ({ testTypeFamily, children }) => {
  const { lang, countryEmoIso2 } = useRouter();
  const { getPromoCodeOrVoucher } = useBasketCookie();
  const promoCodeOrVoucher = getPromoCodeOrVoucher();

  const {
    data: { preparationToolVersion: preparationToolVersions = [] },
    loading,
  } = useQuery(PREPTOOLS_VERSIONS, {
    // Same variable as PrepTools to set Query in cache
    variables: {
      country: countryEmoIso2,
      lang,
      preparationToolVersionInput: {
        discount:
          promoCodeOrVoucher?.discountCode && !promoCodeOrVoucher?.isVoucher
            ? { discountCode: promoCodeOrVoucher.discountCode, referrer: promoCodeOrVoucher.referrer }
            : null,
      },
    },
  });

  let hasPrepTools = false;
  if (!loading && preparationToolVersions) {
    hasPrepTools =
      preparationToolVersions.filter(
        (preparationToolVersion) => preparationToolVersion.relatedFamily.testTypeFamilyName === testTypeFamily.name,
      ).length > 0;
  }

  return children({ hasPrepTools, loading });
};

HasPreptools.propTypes = {
  testTypeFamily: PropTypes.shape({
    testTypes: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
      }),
    ).isRequired,
  }).isRequired,
  children: PropTypes.func.isRequired,
};

export default HasPreptools;
