import { SelectField } from '@ets-global/b2c-website-ui';
import { List, Map } from 'immutable';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

import { setParam } from '../../helpers/router';

const Filters = ({ filters, editorialContentTypes, testTypeFamilies }) => {
  const history = useHistory();
  const { t } = useTranslation();

  const categoriesOptions = [
    { label: t('blog.category-filter.none'), value: 'none' },
    ...editorialContentTypes.map((value) => ({
      label: t(`blog.editorial-content.category.${value.toLowerCase()}`),
      value,
    })),
  ];

  const testTypeFamiliesOptions = [
    { label: t('blog.test-filter.none'), value: 'none' },
    ...testTypeFamilies.map((testTypeFamily) => ({
      label: testTypeFamily.get('name'),
      value: testTypeFamily.get('id'),
    })),
  ];

  const updateURL = ({ key, value }) => {
    history.push({
      search: setParam({
        search: history.location.search,
        key,
        value: value === 'none' ? null : value,
      }),
    });
  };

  return (
    <section className="blog-list__filters">
      <SelectField
        name={'editorialContentType'}
        value={filters.get('editorialContentType') || 'none'}
        onChange={(e) => updateURL({ key: 'editorialContentType', value: e.target.value })}
        options={categoriesOptions}
      />
      <SelectField
        name={'testTypeFamilyId'}
        value={filters.get('testTypeFamilyId') || 'none'}
        onChange={(e) => updateURL({ key: 'testTypeFamilyId', value: e.target.value })}
        options={testTypeFamiliesOptions}
      />
    </section>
  );
};

Filters.propTypes = {
  filters: PropTypes.instanceOf(Map),
  editorialContentTypes: PropTypes.instanceOf(List),
  testTypeFamilies: PropTypes.instanceOf(List),
};

export default Filters;
