import { PRACTICE_TEST_CANDIDATE_TYPE } from '../../constants';

export default ({ Yup, values, isFrenchAndDomTomRegion }) => {
  const formValidatorB2C = Yup.object().shape({
    reason: Yup.string().required(),
  });

  const formValidatorHighSchoolStudent = Yup.object().shape({
    city: Yup.string(),
    zipCode: Yup.string(),
    schoolName: Yup.string(),
    schoolCity: Yup.string(),
  });

  const formValidatorStudent = Yup.object().shape({
    schoolType: Yup.string(),
    schoolName: Yup.string(),
    schoolCity: Yup.string(),
    degreesLevel: Yup.string(),
  });

  const formValidatorActive = Yup.object().shape({
    activityStatus: Yup.string(),
  });

  let formValidator = Yup.object().shape({
    isB2C: Yup.boolean().required(),
    candidateType: Yup.string().required(),
  });

  if (values.isB2C) {
    formValidator = formValidator.concat(formValidatorB2C);
    // eslint-disable-next-line default-case
    switch (values.candidateType) {
      case PRACTICE_TEST_CANDIDATE_TYPE.HIGH_SCHOOL_STUDENT:
        formValidator = isFrenchAndDomTomRegion
          ? formValidator.concat(formValidatorHighSchoolStudent)
          : formValidatorHighSchoolStudent;
        break;
      case PRACTICE_TEST_CANDIDATE_TYPE.STUDENT:
        formValidator = formValidator.concat(formValidatorStudent);
        break;
      case PRACTICE_TEST_CANDIDATE_TYPE.WORKER:
        formValidator = formValidator.concat(formValidatorActive);
        break;
    }
  }

  try {
    formValidator.validateSync(values, {
      abortEarly: false,
      stripUnknown: true,
    });

    return {};
  } catch (error) {
    return error.inner.reduce((errors, validationError) => {
      errors[validationError.path] = validationError.message;

      return errors;
    }, {});
  }
};
