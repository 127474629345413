import React, { Fragment, useState } from 'react';
import { Trans } from 'react-i18next';
import PropTypes from 'prop-types';
import { List as ImmutableList } from 'immutable';
import { Loader, Link, Button } from '@ets-global/b2c-website-ui';

import SessionSearchItemSkeleton from './Item/SessionSearchItemSkeleton';
import Item from './Item';
import useGlobalDocuments from '../../../../hooks/useGlobalDocuments';

const List = ({
  inMap = false,
  sessions = [],
  skeleton = false,
  withDisabilityMessage = true,
  showLoadMore = true,
  onLoadMore = () => {},
  loadMoreProps = {},
  isEproctored = false,
}) => {
  const [loading, setLoading] = useState(false);
  const [isRegisterButtonClicked, setRegisterButtonClicked] = useState(false);
  const { globalDocuments } = useGlobalDocuments();

  const onClickMoreButton = (e) => {
    e.preventDefault();
    setLoading(true);
    onLoadMore().then(() => setLoading(false));
  };

  return (
    <div className="search-session-main__list search-session-main__list--active">
      {!skeleton && withDisabilityMessage && !isEproctored && (
        <div className="search-session-main__handicap">
          <h3 className="search-session-main__handicap-title">
            <strong className="search-session-main__handicap-title-bold">
              <Trans>session-search.main.handicap-title-strong</Trans>
            </strong>
            <Trans>session-search.main.handicap-title</Trans>
          </h3>
          <p className="search-session-main__handicap-paragraph">
            <Trans>session-search.main.handicap-paragraph</Trans>
          </p>
          {globalDocuments.testTakersWithDisabilitiesUrl && (
            <Link to={globalDocuments.testTakersWithDisabilitiesUrl} icon={'arrow'}>
              <Trans>session-search.main.handicap-download.button</Trans>
            </Link>
          )}
        </div>
      )}
      {skeleton && [...Array(4)].map((session, key) => <SessionSearchItemSkeleton key={key} inMap={inMap} />)}
      {!skeleton && (
        <Fragment>
          {sessions.map((session, key) => (
            <Item
              key={key}
              session={session}
              inMap={inMap}
              isRegisterButtonClicked={isRegisterButtonClicked}
              setRegisterButtonClicked={setRegisterButtonClicked}
            />
          ))}
          {showLoadMore && (
            <div className="search-session-main__more">
              {loading && <Loader />}
              <Button {...loadMoreProps} onClick={onClickMoreButton} disabled={loading}>
                <Trans>session-search.main.load-more.button</Trans>
              </Button>
            </div>
          )}
        </Fragment>
      )}
    </div>
  );
};

List.propTypes = {
  inMap: PropTypes.bool,
  sessions: PropTypes.instanceOf(ImmutableList),
  skeleton: PropTypes.bool,
  withDisabilityMessage: PropTypes.bool,
  showLoadMore: PropTypes.bool,
  loadMoreProps: PropTypes.object,
  isEproctored: PropTypes.bool,
};

export default List;
