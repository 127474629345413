import './styles.scss';

import { fromJS } from 'immutable';
import React, { Fragment } from 'react';

import { SmallTitle, SubTitle } from '../../components/Base/Title';
import MainBlogEditorialContent from '../../components/EditorialContent/MainBlogEditorialContent';
import MainBlogEditorialContentSkeleton from '../../components/EditorialContent/MainBlogEditorialContent/MainBlogEditorialContentSkeleton';
import HeaderMeta from '../../components/HeaderMeta';
import NotFoundHandler from '../../components/NotFoundHandler';
import SkeletonItem from '../../components/SkeletonItem';
import NewsletterBlock from '../../containers/NewsletterBlock';
import { BLOG } from '../../graphql/queries';
import { useQuery, useRouter } from '../../hooks';
import BlogFilters from './filters';
import BlogList from './list';

const Blog = ({ params: filters = {} }) => {
  const { lang, countryEmoIso2 } = useRouter();

  const {
    data: { blogPage, editorialContentTypes = [], testTypeFamilies = [] },
    loading,
  } = useQuery(BLOG, { variables: { lang, country: countryEmoIso2 } });

  if (!loading && !blogPage) {
    return <NotFoundHandler />;
  }

  const blog = fromJS(blogPage);

  return (
    <main className={'main blog-list container container--full container--blue'}>
      <article className={'container container--padding'}>
        {blogPage && <HeaderMeta page={'blog'} meta={blogPage.meta} />}
        <section className={'blog-list__titles'}>
          {loading && (
            <Fragment>
              <SkeletonItem style={{ height: '19px', width: '10%' }} />
              <br />
              <SkeletonItem style={{ height: '28px', width: '25%' }} />
            </Fragment>
          )}
          {!loading && (
            <Fragment>
              {blog.get('subtitle') && <SubTitle component={'p'}>{blog.get('subtitle')}</SubTitle>}
              {blog.get('title') && <SmallTitle lvl={1}>{blog.get('title')}</SmallTitle>}
            </Fragment>
          )}
        </section>
        {loading && <MainBlogEditorialContentSkeleton />}
        {!loading && blog.get('mainContent') && <MainBlogEditorialContent editorialContent={blog.get('mainContent')} />}
        <BlogFilters
          filters={fromJS(filters)}
          editorialContentTypes={fromJS(editorialContentTypes)}
          testTypeFamilies={fromJS(testTypeFamilies)}
        />
        <BlogList filters={filters} loading={loading} />
        {!loading && blog.get('displayNewsletterBlock') && <NewsletterBlock />}
      </article>
    </main>
  );
};

export default Blog;
