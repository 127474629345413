import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Trans } from 'react-i18next';

import BaseModal from '../../Base/Modal/BaseModal';
import { Button } from '@ets-global/b2c-website-ui';

const ChangeTestTypeFilterModal = ({ onClose, onSubmit }) => {
  const buttons = (
    <Fragment>
      <Button name="button" className={'button--transparent-light'} onClick={onClose}>
        <Trans>common.cancel</Trans>
      </Button>

      <Button name="button" onClick={onSubmit}>
        <Trans>common.validate</Trans>
      </Button>
    </Fragment>
  );

  return (
    <BaseModal
      isOpen
      onClose={onClose}
      title={<Trans>session-search.main.item.warning.change-testtype.voucher-lost.title</Trans>}
      footer={buttons}
    >
      <Trans>session-search.main.item.warning.change-testtype.voucher-lost.content</Trans>
    </BaseModal>
  );
};

ChangeTestTypeFilterModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default ChangeTestTypeFilterModal;
