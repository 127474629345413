import React, { Fragment, useEffect } from 'react';
import classNames from 'classnames';
import loadable from '@loadable/component';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

import { HOME } from '../../graphql/queries/home';
import { HomeSlider, PersonTypeAndPurposeSelects, ETSInfoBlock, HomeProgrammes } from '../../components/Home';
import ProfileBlock from '../../components/ProfileBlock';
import HeaderMeta from '../../components/HeaderMeta';
import { useNotification, useQuery, useRouter } from '../../hooks';
import HomeSliderSkeleton from '../../components/Home/HomeSlider/HomeSliderSkeleton';
import PersonTypeAndPurposeSelectsSkeleton from '../../components/Home/PersonTypeAndPurposeSelects/PersonTypeAndPurposeSelectsSkeleton';
import MarketingBannerContainer from '../../containers/MarketingBanner';
import LazyLoad from '../../components/LazyLoad';

const HomeLastNews = loadable(() => import('../../components/Home/HomeLastNews'));
const NewsletterBlock = loadable(() => import('../../containers/NewsletterBlock'));

const Home = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { newNotification } = useNotification();
  const { lang, countryEmoIso2, currentRoute } = useRouter();
  const queryParams = new URLSearchParams(currentRoute.search);

  useEffect(() => {
    if (queryParams.has('account-deleted') && queryParams.get('account-deleted')) {
      newNotification({
        content: t('account.delete_my_account.success'),
        type: 'success',
      });
      queryParams.delete('account-deleted');
      history.replace({
        search: queryParams.toString(),
      });
    }
  }, []);

  const {
    data: { home },
    loading,
  } = useQuery(HOME, {
    variables: {
      lang,
      country: countryEmoIso2,
    },
  });

  let content = '';
  if (loading) {
    content = (
      <Fragment>
        <HomeSliderSkeleton />
        <PersonTypeAndPurposeSelectsSkeleton />
      </Fragment>
    );
  } else if (home) {
    const {
      displayNewsletterBlock,
      slides,
      programmeMarketingHook,
      programmes,
      otherProgrammeInfos: otherProgrammes,
      personTypes,
      etsInfoBlock,
      profiles,
    } = home;

    let testTypeFamilyIds = [];
    slides.map((slide) => {
      if (slide.testTypeFamily && slide.testTypeFamily.id && 0 > testTypeFamilyIds.indexOf(slide.testTypeFamily.id)) {
        testTypeFamilyIds.push(slide.testTypeFamily.id);
      }
    });

    content = (
      <Fragment>
        <HeaderMeta page={'home'} meta={home.meta} />
        <HomeSlider slides={slides} />
        <PersonTypeAndPurposeSelects personTypes={personTypes} />
        <HomeProgrammes
          marketingHook={programmeMarketingHook}
          programmes={programmes}
          otherProgrammes={otherProgrammes}
        />
        <MarketingBannerContainer documentId={home.id} />
        {profiles.map((profile, key) => (
          <Fragment key={key}>
            <ProfileBlock {...profile} />
          </Fragment>
        ))}
        <ETSInfoBlock {...etsInfoBlock} />
        <section className={'container'}>
          <LazyLoad>
            <HomeLastNews />
          </LazyLoad>
        </section>
        {displayNewsletterBlock && (
          <aside className={'container container--padding'}>
            <LazyLoad>
              <NewsletterBlock />
            </LazyLoad>
          </aside>
        )}
      </Fragment>
    );
  }

  return <main className={classNames('main', 'main--no-offset-top', 'main--offset-bottom')}>{content}</main>;
};

export default Home;
