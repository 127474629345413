import React, { Fragment, useContext, useState } from 'react';
import { Trans } from 'react-i18next';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useLocation } from 'react-router';
import kebabCase from 'lodash/kebabCase';

import { Button, Link } from '@ets-global/b2c-website-ui';

import Filters from '../Filters';
import SessionSearchList from './List';
import SessionSearchMap from './Map';
import { Title } from '../../Base';
import { useRouter } from '../../../hooks';
import { COUNTRY } from '../../../constants';
import { paths } from '../../../paths';
import { AppContext } from '../../../store/App';

import './styles.scss';

const SessionSearchMain = ({
  className,
  sessionSearchFilter,
  loading,
  onFilterChange,
  showReturnButton,
  toggleBlocks,
  itemNumber = 10,
  withCity = true,
  withDisabilityMessage = true,
  showLoadMore = true,
  title,
  ssr = true,
  isEproctored = false,
}) => {
  const { lang, countryEmoIso2 } = useRouter();
  const [filtersDisplayed, setFiltersDisplayed] = useState(true);
  const { countries, country } = useContext(AppContext);
  const location = useLocation();

  const toggleFilterOnMobile = () => {
    setFiltersDisplayed(!filtersDisplayed);
  };

  let errorOnCountry = null;
  if (COUNTRY[sessionSearchFilter.region] && countryEmoIso2 !== COUNTRY[sessionSearchFilter.region]) {
    errorOnCountry = {
      targetCountryIso: COUNTRY[sessionSearchFilter.region],
    };
  }

  let errorOnSessionList = null;

  if (errorOnCountry) {
    const { targetCountryIso } = errorOnCountry;
    const { city, region } = sessionSearchFilter;
    const selectedCountry = countries.filter((country) => country.emoIso2 === targetCountryIso.toUpperCase());
    let locale =
      selectedCountry[0].locales.filter((locale) => locale.main === 'yes')[0] || selectedCountry[0].locales[0];
    locale = locale.code.toLowerCase();
    const currentCountry = country.name;
    const targetCountry = selectedCountry[0].name;
    const kebabCity = kebabCase(city);
    const kebabRegion = kebabCase(region);

    errorOnSessionList = (
      <div className={'search-session-main__content'}>
        <div className="search-session-main__no-results">
          <p className="search-session-main__no-results-paragraph">
            <Trans values={{ currentCountry, targetCountry }}>session-search.main.error-on-county</Trans>
          </p>
          <Button
            className={'search-session-main__no-results-button'}
            to={paths.SESSION_SEARCH}
            params={{ country: targetCountryIso, locale, city: kebabCity, region: kebabRegion }}
            search={location.search}
          >
            <Trans>session-search.main.county-button</Trans>
          </Button>
        </div>
      </div>
    );
  }

  return (
    <main
      className={classNames('search-session-main', className, {
        'search-session-main--filters-active': sessionSearchFilter.showMap,
      })}
    >
      {showReturnButton ? (
        <Link icon={'arrow'} className="link--reversed search-session-main__back-link" onClick={toggleBlocks}>
          <Trans>common.back</Trans>
        </Link>
      ) : null}

      <Title.SmallTitle lvl={2}>
        <Trans>{title}</Trans>
      </Title.SmallTitle>
      {!loading && sessionSearchFilter.date && sessionSearchFilter.city && (
        <Fragment>
          <button
            className={'search-session-main__close-map'}
            onClick={() => {
              onFilterChange({ showMap: !sessionSearchFilter.showMap });
              if (!filtersDisplayed && sessionSearchFilter.showMap) {
                toggleFilterOnMobile();
              }
            }}
          >
            <Trans>common.back</Trans>
          </button>
          <Filters
            sessionSearchFilter={sessionSearchFilter}
            locale={lang}
            onFilterChange={onFilterChange}
            filtersDisplayed={filtersDisplayed}
            toggleFilter={() => toggleFilterOnMobile()}
            withCity={withCity}
            isEproctored={isEproctored}
          />
        </Fragment>
      )}

      {errorOnSessionList ? (
        errorOnSessionList
      ) : !sessionSearchFilter.showMap || isEproctored ? (
        <SessionSearchList
          sessionSearchFilter={sessionSearchFilter}
          loading={loading}
          itemNumber={itemNumber}
          withDisabilityMessage={withDisabilityMessage}
          showLoadMore={showLoadMore}
          ssr={ssr}
          isEproctored={isEproctored}
        />
      ) : (
        <SessionSearchMap sessionSearchFilter={sessionSearchFilter} isEproctored={isEproctored} />
      )}
    </main>
  );
};

SessionSearchMain.propType = {
  sessionSearchFilter: PropTypes.shape({
    showMap: PropTypes.bool,
  }),
  loading: PropTypes.bool,
  onFilterChange: PropTypes.func,
  toggleBlocks: PropTypes.func,
  showReturnButton: PropTypes.bool,
  className: PropTypes.string,
  itemNumber: PropTypes.number,
  withDisabilityMessage: PropTypes.bool,
  withCity: PropTypes.bool,
  showLoadMore: PropTypes.bool,
  title: PropTypes.string,
  ssr: PropTypes.bool,
  isEproctored: PropTypes.bool,
};

export default SessionSearchMain;
