import React from 'react';
import { fromJS } from 'immutable';
import { useHistory } from 'react-router';

import { paths } from '../../paths';
import { EDITORIAL_CONTENT_LIST } from '../../graphql/queries';
import { EditorialContentList } from '../../components/EditorialContent';
import EditorialContentListSkeleton from '../../components/EditorialContent/EditorialContentList/EditorialContentListSkeleton';
import { useRouter, useQuery } from '../../hooks';
import { getParams, setParams } from '../../helpers/router';

const List = ({ filters, loading }) => {
  const { location } = useHistory();
  const { lang, countryEmoIso2 } = useRouter();

  const editorialContentListVariables = { limit: 6, lang, country: countryEmoIso2 };

  if (filters.testTypeFamilyId) {
    editorialContentListVariables.testTypeFamilyIds = [filters.testTypeFamilyId];
  }

  if (filters.editorialContentType) {
    editorialContentListVariables.editorialContentType = filters.editorialContentType;
  }

  if (filters.cursor) {
    editorialContentListVariables.cursor = filters.cursor;
  }

  const {
    data: { itemsWithCursor },
    fetchMore,
    loading: editorialContentListLoading,
  } = useQuery(EDITORIAL_CONTENT_LIST, { variables: editorialContentListVariables });

  const onLoadMore = (e) => {
    e.preventDefault();
    fetchMore({
      query: EDITORIAL_CONTENT_LIST,
      variables: {
        ...editorialContentListVariables,
        cursor: itemsWithCursor.cursor,
      },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        const previousItemsWithCursor = previousResult.itemsWithCursor;
        const { items: newItems, cursor: newCursor, hasNextPage } = fetchMoreResult.itemsWithCursor;

        return {
          itemsWithCursor: {
            cursor: newCursor,
            hasNextPage,
            items: [...previousItemsWithCursor.items, ...newItems],
            __typename: previousItemsWithCursor.__typename,
          },
        };
      },
    });
  };

  if (loading || editorialContentListLoading) {
    return <EditorialContentListSkeleton />;
  }

  return (
    <EditorialContentList
      editorialContentList={fromJS(itemsWithCursor.items)}
      showLoadMore={itemsWithCursor.hasNextPage}
      blog={true}
      loadMoreProps={{
        to: paths.BLOG,
        search: setParams({ ...getParams(location.search), cursor: itemsWithCursor.cursor }),
        onClick: onLoadMore,
      }}
    />
  );
};

export default List;
