import intersection from 'lodash/intersection';
import PropTypes from 'prop-types';

import { TEST_TYPE_FAMILIES } from '../../graphql/queries';
import { checkEProctoring } from '../../helpers/sessionSearch';
import { useBasketCookie, useQuery, useRouter } from '../../hooks';

const HasSessionsAvailable = ({ testTypeIds, children }) => {
  const { lang, countryEmoIso2 } = useRouter();
  const { getPromoCodeOrVoucher } = useBasketCookie();
  const promoCodeOrVoucher = getPromoCodeOrVoucher();

  const {
    data: { testTypeFamilies: testTypeFamiliesAvailable = [] },
    loading: loadingTestTypeFamilies,
  } = useQuery(TEST_TYPE_FAMILIES, {
    variables: {
      lang,
      country: countryEmoIso2,
      testTypeIds: promoCodeOrVoucher ? promoCodeOrVoucher.testTypeIds : undefined,
    },
  });

  let hasSessions = false;
  let isEproctored = false;
  if (testTypeFamiliesAvailable.length > 0) {
    const testTypeIdsAvailable = [
      ...new Set(
        testTypeFamiliesAvailable.reduce((acc, testTypeFamily) => {
          acc.push(...testTypeFamily.testTypes.map((testTypes) => testTypes.id));

          return acc;
        }, [])
      ),
    ];

    hasSessions = testTypeIdsAvailable.length > 0;
    if (testTypeIds) {
      hasSessions =
        intersection(
          testTypeIdsAvailable.map(({ id }) => id),
          testTypeIds.map(({ id }) => id)
        ).length > 0;
      isEproctored = checkEProctoring(testTypeIds, testTypeFamiliesAvailable);
    }
  }

  return children({
    loading: loadingTestTypeFamilies,
    hasSessions,
    isEproctored,
  });
};

HasSessionsAvailable.propTypes = {
  testTypeIds: PropTypes.arrayOf(PropTypes.number.isRequired),
  children: PropTypes.func.isRequired,
};

export default HasSessionsAvailable;
