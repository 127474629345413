import React from 'react';
import { Trans } from 'react-i18next';
import SearchSessionTestTypeFamilyChoice from './TestTypeFamilyChoice';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import SmallTitle from '../../Base/Title/SmallTitle';
import SearchSessionChoiceSkeleton from './TestTypeFamilyChoice/SearchSessionChoiceSkeleton';

import './styles.scss';

const SearchSessionSideBar = ({
  testTypeFamilies = [],
  toggleBlocks,
  showTestTypeFamilies,
  sessionSearchFilter,
  pushToUrl,
  loading,
  canChangeTestTypeFilter = true,
  isChangeTestTypeFilterModalDisplayed = false,
}) => {
  return (
    <aside
      className={classNames('search-session__sidebar', {
        'search-session__sidebar--active': showTestTypeFamilies,
      })}
    >
      <SmallTitle lvl={2}>
        <Trans>session-search.side-bar.title</Trans>
      </SmallTitle>
      {loading && <SearchSessionChoiceSkeleton />}
      {!loading && (
        <div className="search-session-choice">
          {testTypeFamilies.map((testTypeFamily, index) => (
            <SearchSessionTestTypeFamilyChoice
              key={index}
              testTypeFamily={testTypeFamily}
              checkedTestTypeIds={sessionSearchFilter.testTypeIds}
              toggleBlocks={toggleBlocks}
              sessionSearchFilter={sessionSearchFilter}
              pushToUrl={pushToUrl}
              canChangeTestTypeFilter={canChangeTestTypeFilter}
              isChangeTestTypeFilterModalDisplayed={isChangeTestTypeFilterModalDisplayed}
            />
          ))}
        </div>
      )}
    </aside>
  );
};

SearchSessionSideBar.propTypes = {
  toggleBlocks: PropTypes.func.isRequired,
  showTestTypeFamilies: PropTypes.bool.isRequired,
  sessionSearchFilter: PropTypes.object.isRequired,
  pushToUrl: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  canChangeTestTypeFilter: PropTypes.bool,
  isChangeTestTypeFilterModalDisplayed: PropTypes.bool,
};

export default SearchSessionSideBar;
