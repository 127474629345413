import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { fromJS } from 'immutable';
import PropTypes from 'prop-types';
import { useHistory, useLocation } from 'react-router';

import List from './List';
import { OPEN_SESSIONS_SEARCH } from '../../../../graphql/queries';
import { useBasketCookie, useQuery, useRouter } from '../../../../hooks';
import { setParamsSearch, getParams } from '../../../../helpers/router';
import { getSessionSearchFilter } from '../../../../helpers/sessionSearch';
import { paths } from '../../../../paths';
import NoOpenSessionsSearch from '../NoResult/noOpenSessionsSearch';

const SessionSearchList = ({
  sessionSearchFilter = {},
  loading,
  itemNumber = 10,
  withDisabilityMessage = true,
  showLoadMore = true,
  ssr = true,
  isEproctored = false,
}) => {
  const { i18n } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const { countryEmoIso2, lang, generatePath } = useRouter();
  const { getPromoCodeOrVoucher } = useBasketCookie();
  const promoCodeOrVoucher = getPromoCodeOrVoucher();

  const hasMissingFilter =
    !sessionSearchFilter ||
    sessionSearchFilter.testTypeIds.length === 0 ||
    sessionSearchFilter.coordinates.longitude === 0 ||
    sessionSearchFilter.coordinates.latitude === 0 ||
    !sessionSearchFilter.date;

  const { showMap, city, region, offset, ...filters } = sessionSearchFilter;
  let { coordinates, ...sessionFilter } = filters;

  if (!isEproctored) {
    sessionFilter.coordinates = coordinates;
  }

  let variables = {
    filter: sessionFilter,
    country: countryEmoIso2,
    discount:
      promoCodeOrVoucher?.discountCode && !promoCodeOrVoucher?.isVoucher
        ? { discountCode: promoCodeOrVoucher.discountCode, referrer: promoCodeOrVoucher.referrer }
        : null,
    voucher: promoCodeOrVoucher?.isVoucher ? promoCodeOrVoucher.discountCode : null,
    lang,
    offset,
    limit: itemNumber,
  };

  const {
    data: { openSessionsSearch },
    fetchMore,
    loading: openSessionsLoading,
  } = useQuery(OPEN_SESSIONS_SEARCH, {
    variables,
    skip: hasMissingFilter,
    ssr,
  });

  const onLoadMore = () =>
    fetchMore({
      variables: {
        filter: sessionFilter,
        offset: (parseInt(offset, 10) || 0) + sessions.size,
        lang: i18n.language,
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;

        const result = {
          openSessionsSearch: {
            pageInfo: fetchMoreResult.openSessionsSearch.pageInfo,
            items: [...prev.openSessionsSearch.items, ...fetchMoreResult.openSessionsSearch.items],
            __typename: prev.openSessionsSearch.__typename,
          },
        };

        return result;
      },
    });

  const reloadSessionSearchPage = () => {
    history.push(
      generatePath(
        paths.SESSION_SEARCH,
        { city, region },
        setParamsSearch(getSessionSearchFilter({ ...getParams(location.search), distance: 0 })),
      ),
    );
  };

  if (loading || openSessionsLoading || !city) {
    return (
      <div className={'search-session-main__content'}>
        <List withDisabilityMessage={withDisabilityMessage} isEproctored={isEproctored} skeleton />
      </div>
    );
  }

  if (hasMissingFilter) {
    return (
      <div className={'search-session-main__content'}>
        <div className="search-session-main__no-results">
          <p className="search-session-main__no-results-paragraph">
            <Trans>session-search.main.no-parameters</Trans>
          </p>
        </div>
      </div>
    );
  }

  if (!openSessionsSearch || (openSessionsSearch.items && openSessionsSearch.items.length === 0)) {
    return <NoOpenSessionsSearch reloadSessionSearchPage={reloadSessionSearchPage} isEproctored={isEproctored} />;
  }

  let { items: sessions = [] } = openSessionsSearch;
  sessions = fromJS(sessions);

  let loadMoreProps = {};
  if (showLoadMore) {
    loadMoreProps = {
      to: paths.SESSION_SEARCH,
      params: { city, region },
      search: setParamsSearch(
        getSessionSearchFilter({
          ...getParams(location.search),
          offset: (parseInt(offset, 10) || 0) + sessions.size,
        }),
      ),
    };
  }

  return (
    <div className={'search-session-main__content'}>
      <List
        sessions={sessions}
        withDisabilityMessage={withDisabilityMessage}
        showLoadMore={(parseInt(offset, 10) || 0) + sessions.size < openSessionsSearch.pageInfo.total && showLoadMore}
        loadMoreProps={loadMoreProps}
        onLoadMore={onLoadMore}
        isEproctored={isEproctored}
      />
    </div>
  );
};

SessionSearchList.propTypes = {
  sessionSearchFilter: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  itemNumber: PropTypes.number,
  withDisabilityMessage: PropTypes.bool,
  showLoadMore: PropTypes.bool,
  isEproctored: PropTypes.bool,
};

export default React.memo(
  SessionSearchList,
  (
    { sessionSearchFilter: prevSessionSearchFilter, loading: prevLoading },
    { sessionSearchFilter: nextSessionSearchFilter, loading: nextLoading },
  ) => {
    return fromJS(prevSessionSearchFilter).equals(fromJS(nextSessionSearchFilter)) && prevLoading === nextLoading;
  },
);
