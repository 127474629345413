import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Trans, useTranslation } from 'react-i18next';
import { Form, Formik } from 'formik';
import { useMutation } from '@apollo/client';
import { START_HOME_SESSION } from '../../../../graphql/mutations';

import { Fields, Button } from '@ets-global/b2c-website-ui';

import { useDateToString } from '../../../Element/DateToString';
import { env } from '../../../../helpers/env';
import { useDataLayer, useRouter } from '../../../../hooks';
import useYup from '../../../../hooks/useYup';
import { convertMinutesToHours } from '../../../../helpers/convertMinutesToHours';
import { isCurrentlyInsideItsMaintenanceWindow } from '../../../../helpers/isCurrentlyInsideItsMaintenanceWindow';
import ConfirmModal from '../../../Base/Modal/ConfirmModal';
import './styles.scss';

const ECLegalSentenceForHomeTestBlock = ({
  title,
  sessionIsStarted,
  sessionIsFinished,
  testDuration,
  candidateName,
  homeSessionId,
  nextItsMaintenanceWindow,
}) => {
  const DateToString = useDateToString();
  const { lang } = useRouter();
  const { Yup } = useYup();
  const { t } = useTranslation();
  const { pushEventGAClickNavigation } = useDataLayer();
  const [startHomeSession] = useMutation(START_HOME_SESSION);
  const [modalOptions, setModalOptions] = useState({
    type: 'default',
    isOpened: false,
  });
  const [isInMaintenanceWindow, setIsInMaintenanceWindow] = useState(false);
  const testDurationInHours = convertMinutesToHours(testDuration);

  useEffect(() => {
    if (sessionIsFinished) {
      setModalOptions({ type: 'default', isOpened: true });
    }
    const interval = setInterval(() => handleMaintenanceWindow(), 1000);

    return () => clearInterval(interval);
  }, []);

  const redirectToIts = () => {
    window.location = env('RAZZLE_ITS_REDIRECTION_URL');
  };

  const handleMaintenanceWindow = () => {
    setIsInMaintenanceWindow(isCurrentlyInsideItsMaintenanceWindow(testDurationInHours, nextItsMaintenanceWindow));
  };

  const isStartSessionModal = () => {
    return modalOptions.type === 'start-session';
  };

  const getModalTitle = () => {
    switch (modalOptions.type) {
      case 'start-session': {
        return 'toeic-in-autonomy.modal-start-session.title';
      }
      case 'CANNOT_START_DUE_TO_ONGOING_ITS_MAINTENANCE_WINDOW': {
        return 'toeic-in-autonomy.modal-its-maintenance-window.title';
      }
      default:
        return 'toeic-in-autonomy.modal.title';
    }
  };

  const getModalBody = () => {
    switch (modalOptions.type) {
      case 'start-session': {
        return 'toeic-in-autonomy.modal-start-session.description-with-duration-singular';
      }
      case 'CANNOT_START_DUE_TO_ONGOING_ITS_MAINTENANCE_WINDOW': {
        return 'toeic-in-autonomy.modal-its-maintenance-window.description';
      }
      default:
        return 'toeic-in-autonomy.modal.description-with-duration';
    }
  };

  const toggleModal = () => {
    setModalOptions({
      ...modalOptions,
      isOpened: !modalOptions?.isOpened,
    });
  };

  const startSession = () => {
    pushEventGAClickNavigation({ eventLabel: 'start the test' });
    startHomeSession({
      variables: {
        id: homeSessionId,
      },
      onCompleted: ({ startHomeSessionResult: { hasSucceeded, reason } }) => {
        toggleModal();
        if (hasSucceeded) {
          redirectToIts();

          return;
        }

        if (reason) {
          setModalOptions({ type: reason, isOpened: true });

          return;
        }

        setModalOptions({ type: 'default', isOpened: true });
      },
    });
  };

  const handleSubmit = (data, actions) => {
    actions.setSubmitting(false);
    if (sessionIsFinished) {
      setModalOptions({ type: 'default', isOpened: true });

      return;
    }
    if (!sessionIsStarted) {
      setModalOptions({ type: 'start-session', isOpened: true });

      return;
    }
    redirectToIts();
  };

  const validationSchema = Yup.object().shape({
    agreement: Yup.boolean()
      .required()
      .test('isChecked', t('toeic-in-autonomy.form.agreement-error-message'), (value) => value === true),
  });

  let modalButtons = [{ label: t('common.ok'), onClick: () => toggleModal() }];
  if (isStartSessionModal()) {
    modalButtons = [
      { label: t('common.refuse'), className: 'button--transparent-light', onClick: () => toggleModal() },
      { label: t('common.accept'), disabled: isInMaintenanceWindow, onClick: () => startSession() },
    ];
  }

  return (
    <Fragment>
      <div className="ec-legal-sentance-for-home-test-block">
        <div className="container container--padding">
          <p className="ec-legal-sentance-for-home-test-block__title">{title}</p>
          <Formik initialValues={{ agreement: false }} validationSchema={validationSchema} onSubmit={handleSubmit}>
            {({ isSubmitting }) => (
              <Form>
                <Fields.FormikFastField
                  id="agreement"
                  name="agreement"
                  component={Fields.CheckboxField}
                  label={t('toeic-in-autonomy.form.label.legal_sentence', { fullName: candidateName })}
                  required={true}
                />
                <Button type="submit" disabled={isSubmitting || isInMaintenanceWindow}>
                  <Trans>toeic-in-autonomy.form.button.{sessionIsStarted ? 'started' : 'not_started'}</Trans>
                </Button>
                {isInMaintenanceWindow && (
                  <p
                    className="ec-legal-sentance-for-home-test-block__its-maintenance-window-error"
                    dangerouslySetInnerHTML={{
                      __html: t('toeic-in-autonomy.maintenance-window.error', {
                        endDate: DateToString({
                          date: nextItsMaintenanceWindow.endDate,
                          timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                          format: 'Pp',
                        }),
                      }),
                    }}
                  ></p>
                )}
              </Form>
            )}
          </Formik>
        </div>
      </div>
      <ConfirmModal
        isOpen={modalOptions.isOpened}
        title={t(getModalTitle())}
        onClose={() => toggleModal()}
        buttons={modalButtons}
      >
        <Trans count={testDurationInHours} values={{ homeTestDuration: testDurationInHours.toLocaleString(lang) }}>
          {getModalBody()}
        </Trans>
      </ConfirmModal>
    </Fragment>
  );
};

ECLegalSentenceForHomeTestBlock.propTypes = {
  title: PropTypes.string.isRequired,
  sessionIsStarted: PropTypes.bool.isRequired,
  sessionIsFinished: PropTypes.bool.isRequired,
  testDuration: PropTypes.number.isRequired,
  candidateName: PropTypes.string.isRequired,
  homeSessionId: PropTypes.number.isRequired,
  nextItsMaintenanceWindow: PropTypes.object,
};

export default ECLegalSentenceForHomeTestBlock;
