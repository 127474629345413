import './styles.scss';

import kebabCase from 'lodash/kebabCase';
import React, { useEffect } from 'react';
import { useLocation } from 'react-router';

import { LocalizedRedirect } from '../../../components/Routing';
import SessionSearchComponent from '../../../components/SessionSearch';
import { REGION_CITY_BY_COORDINATES, TEST_TYPE_FAMILIES } from '../../../graphql/queries';
import { getParams, setParamsSearch } from '../../../helpers/router';
import { checkTestTypeIdsIsValid } from '../../../helpers/sessionSearch';
import { useBasketCookie, useDataLayer, useQuery, useRouter } from '../../../hooks';
import { paths } from '../../../paths';

const SessionSearch = () => {
  const location = useLocation();
  const { lang, countryEmoIso2, generatePath } = useRouter();
  const { getPromoCodeOrVoucher } = useBasketCookie();
  const promoCodeOrVoucher = getPromoCodeOrVoucher();
  const { pushEventLoadedPurchaseFunnel } = useDataLayer();
  const filter = getParams(location.search);
  const {
    data: { regionCity },
    loading: geolocationLoading,
  } = useQuery(REGION_CITY_BY_COORDINATES, {
    variables: { lang, coordinates: filter.coordinates },
    skip: !filter.coordinates,
  });

  useEffect(() => {
    pushEventLoadedPurchaseFunnel({
      type: 'test order',
      stepName: 'test research',
      stepNumber: 1,
    });
  }, []);

  const options = {
    variables: {
      country: countryEmoIso2,
      lang,
    },
  };

  if (promoCodeOrVoucher?.testTypeIds) {
    options.variables.testTypeIds = promoCodeOrVoucher.testTypeIds;
    // cache conflict with the query executed in the `HasSessionsAvailable` component
    // because the testtypes included in the testtype family depend on the fact there is an available voucher or not (voucher used on this page but not in `HasSessionsAvailable`)
    options.fetchPolicy = 'no-cache';
  }

  const testTypeFamiliesResult = useQuery(TEST_TYPE_FAMILIES, options);

  let testTypeFamilies = testTypeFamiliesResult.data.testTypeFamilies;
  if (promoCodeOrVoucher?.isCpf && promoCodeOrVoucher.testTypeIds && testTypeFamilies) {
    testTypeFamilies = testTypeFamilies.map((testTypeFamily) => ({
      ...testTypeFamily,
      testTypes: testTypeFamily.testTypes.filter((testType) => promoCodeOrVoucher.testTypeIds.includes(testType.id)),
    }));
  }

  if (
    !testTypeFamiliesResult.loading &&
    filter.testTypeIds &&
    !checkTestTypeIdsIsValid(filter.testTypeIds, testTypeFamilies)
  ) {
    return <LocalizedRedirect to={paths.BAD_REQUEST} />;
  } else if (filter.coordinates && !geolocationLoading) {
    const { city, region, coordinates, ...paramSearch } = filter;

    return (
      <LocalizedRedirect
        to={{
          pathname: generatePath(paths.SESSION_SEARCH, {
            region: kebabCase(regionCity.region),
            city: kebabCase(regionCity.city),
          }),
          search: setParamsSearch(paramSearch),
        }}
      />
    );
  }

  return (
    <div className="search-session">
      <SessionSearchComponent
        testTypeFamilies={testTypeFamilies}
        loading={testTypeFamiliesResult.loading || geolocationLoading}
      />
    </div>
  );
};

export default SessionSearch;
