import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import DropDown from '../../DropDown';

import './styles.scss';

const HomeSelectField = ({ label, value, onSelectChoice, className, choices = [] }) => {
  const selectRef = useRef();

  const handleSelect = (value) => {
    selectRef.current.close();
    onSelectChoice(value);
  };

  return (
    <div className={classNames('dotted-select', className)}>
      <span className="dotted-select__label">{label}</span>

      <DropDown
        className={'dotted-select__selectbox'}
        buttonLabel={value}
        buttonClassName={'dotted-select__input'}
        contentClassName={'dotted-select__choices'}
        selectRef={selectRef}
        disabled={!choices || choices.length === 0}
      >
        <ul>
          {choices &&
            choices.map((choice, key) => (
              <li key={key} className="dotted-select__choice">
                <button
                  type="button"
                  className="dotted-select__choice-button"
                  onClick={() => handleSelect(choice.value)}
                >
                  {choice.text}
                </button>
              </li>
            ))}
        </ul>
      </DropDown>
    </div>
  );
};

HomeSelectField.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
  onSelectChoice: PropTypes.func.isRequired,
  choices: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.number.isRequired,
      letter: PropTypes.string,
      text: PropTypes.string.isRequired,
    }),
  ),
};

export default HomeSelectField;
