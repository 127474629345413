import React, { Fragment, useContext } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router';
import { fromJS } from 'immutable';

import { SelectField, Button, Loader } from '@ets-global/b2c-website-ui';

import { getParams, setParams } from '../../helpers/router';
import { paths } from '../../paths';
import LocationCenter from '../../components/LocationCenter';
import { AppContext } from '../../store/App';
import Title from '../../components/Element/Title';
import { PARTNERS, TEST_TYPE_FAMILIES } from '../../graphql/queries';
import { addressToString } from '../../helpers/Formatter/StringFormatter';
import HeaderMeta from '../../components/HeaderMeta';
import { useRouter, useQuery } from '../../hooks';

import './styles.scss';

const PartnerSearch = () => {
  const history = useHistory();
  const location = useLocation();
  const { countryEmoIso2 } = useRouter();
  const filters = getParams(location.search) || {};

  const {
    country: { name: countryName },
  } = useContext(AppContext);
  const {
    t,
    i18n: { language: lang },
  } = useTranslation();
  const {
    data: { partners = [] },
    loading: partnersLoading,
  } = useQuery(PARTNERS, {
    variables: {
      country: countryEmoIso2,
      testTypeIds: filters.testTypeIds ? filters.testTypeIds : null,
    },
  });

  const {
    data: { testTypeFamilies = [] },
    loading: testTypeFamiliesLoading,
  } = useQuery(TEST_TYPE_FAMILIES, {
    variables: { lang, country: countryEmoIso2, forPartners: true },
  });

  const seoPartners = {};
  partners.slice(0, 2).forEach((partner, key) => {
    seoPartners[`companyName${key}`] = partner.name;
    seoPartners[`companyCity${key}`] = partner.address.city;
  });

  const testTypeFamiliesOptions = [
    { label: t('cmd-epa.main.test-type-families.none'), value: 'none' },
    ...testTypeFamilies.map((testTypeFamily) => ({
      label: testTypeFamily.name,
      value: testTypeFamily.id,
    })),
  ];

  const getTestTypeFamilyIdByTestTypeIds = () => {
    if (!filters?.testTypeIds) {
      return 'none';
    }

    const testTypeFamily = testTypeFamilies.find((currentTestTypeFamily) =>
      currentTestTypeFamily.testTypes.find((testType) => filters.testTypeIds.includes(testType.id)),
    );

    return testTypeFamily?.id || 'none';
  };

  const updateFilters = (testTypeFamilyId) => {
    if (testTypeFamilyId === 'none') {
      history.push({
        search: setParams({}),
      });
    }
    const testTypeFamily = testTypeFamilies.find((testTypeFamily) => testTypeFamily.id === testTypeFamilyId);
    const testTypeIds = testTypeFamily?.testTypes.map((testType) => testType.id) || [];
    history.push({
      search: setParams({ testTypeIds }),
    });
  };

  return (
    <Fragment>
      <HeaderMeta page={'cmd-epa'} transParam={seoPartners} />
      <main className="main">
        <div className="container container--padding container--text-left">
          <header className="page-header">
            <Title lvl={1} className={'small-title'}>
              <Trans values={{ country: countryName }}>cmd-epa.main.title</Trans>
            </Title>
            <Button to={paths.COUNTRY} className={'button--transparent-light'}>
              <Trans>cmd-epa.main.change-country</Trans>
            </Button>
          </header>
        </div>

        <div className="container container--full container--blue container--text-left">
          <div className="container container--padding">
            <main className="page-main">
              <div className="select-test">
                {testTypeFamiliesLoading && <Loader />}
                {!testTypeFamiliesLoading && testTypeFamilies && testTypeFamilies.length > 0 ? (
                  <>
                    <SelectField
                      label={t('cmd-epa.main.test-type-label')}
                      name={'testTypeFamilyId'}
                      value={getTestTypeFamilyIdByTestTypeIds()}
                      onChange={(e) => updateFilters(e.target.value)}
                      options={testTypeFamiliesOptions}
                    />
                    <p className="select-test__help-text">
                      <Trans>cmd-epa.main.test-type-description</Trans>
                    </p>
                  </>
                ) : (
                  <p className="select-test__help-text">
                    <span
                      dangerouslySetInnerHTML={{
                        __html: t('cmd-epa.redirect-to.ets'),
                      }}
                    />
                  </p>
                )}
              </div>
              {partnersLoading ? (
                <Loader />
              ) : (
                partners.map((partner, key) => (
                  <LocationCenter
                    key={key}
                    centerName={partner.name}
                    address={addressToString(fromJS(partner.address), ', ', true)}
                    website={partner.website}
                    email={partner.email}
                    phone={partner.phone}
                  />
                ))
              )}
            </main>
          </div>
        </div>
      </main>
    </Fragment>
  );
};

export default PartnerSearch;
