import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import { OPEN_SESSIONS_SEARCH } from '../../graphql/queries';
import { dateToISOString } from '../../helpers/Formatter/DateFormatter';
import { AppContext } from '../../store/App';
import { useBasketCookie, useQuery, useRouter } from '../../hooks';
import HasSessionsAvailable from './index';

const HasSessionsAvailableWithItems = ({ testTypeIds = [], children }) => {
  const { lang, countryEmoIso2 } = useRouter();
  const { coordinates } = useContext(AppContext);
  const { getPromoCodeOrVoucher } = useBasketCookie();
  const promoCodeOrVoucher = getPromoCodeOrVoucher();

  return (
    <HasSessionsAvailable testTypeIds={testTypeIds}>
      {({ loading, hasSessions, isEproctored }) => {
        let queryCoordinates = null;
        if (!isEproctored && (coordinates.latitude !== 0 || coordinates.longitude !== 0)) {
          queryCoordinates = {
            latitude: parseFloat(coordinates.latitude),
            longitude: parseFloat(coordinates.longitude),
          };
        }

        // Same variable as NextSessionsList to set Query in cache
        const {
          data: { openSessionsSearch },
          loading: loadingOpenSessionSearch,
        } = useQuery(OPEN_SESSIONS_SEARCH, {
          variables: {
            filter: {
              testTypeIds,
              coordinates: queryCoordinates,
              date: dateToISOString(),
            },
            discount:
              promoCodeOrVoucher?.discountCode && !promoCodeOrVoucher?.isVoucher
                ? { discountCode: promoCodeOrVoucher.discountCode, referrer: promoCodeOrVoucher.referrer }
                : null,
            voucher: promoCodeOrVoucher?.isVoucher ? promoCodeOrVoucher.discountCode : null,
            lang,
            country: countryEmoIso2,
            limit: 3,
          },
          skip: loading || !hasSessions || (!isEproctored && queryCoordinates === null),
          ssr: false,
        });

        const sessions = openSessionsSearch && openSessionsSearch.items ? openSessionsSearch.items : [];

        return children({
          hasSessions: sessions.length > 0,
          isEproctored,
          loading: loading || loadingOpenSessionSearch,
          sessions,
        });
      }}
    </HasSessionsAvailable>
  );
};

HasSessionsAvailableWithItems.propTypes = {
  testTypeIds: PropTypes.arrayOf(PropTypes.number.isRequired).isRequired,
  children: PropTypes.func.isRequired,
};

export default HasSessionsAvailableWithItems;
