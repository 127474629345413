import React from 'react';
import PropTypes from 'prop-types';

import InjectHtml from '../../../InjectHtml';
import PartnerPromoCodeBock from '../../../PartnerPromoCodeBlock';

import './styles.scss';

const ECDiscountBlock = ({ title, discounts }) => (
  <div className="container container--padding">
    <div className="article-block__inner">
      <InjectHtml component="p" className="article-block__title" html={title} />
      <div className="promocode-list">
        {discounts.map((discount, key) => (
          <PartnerPromoCodeBock key={key} {...discount} />
        ))}
      </div>
    </div>
  </div>
);

ECDiscountBlock.propTypes = {
  title: PropTypes.string.isRequired,
  discounts: PropTypes.array.isRequired,
};

export default ECDiscountBlock;
