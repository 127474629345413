import React, { useState, useContext, useEffect } from 'react';
import { Trans } from 'react-i18next';
import classNames from 'classnames';
import { fromJS } from 'immutable';
import kebabCase from 'lodash/kebabCase';

import { Button, Link } from '@ets-global/b2c-website-ui';

import { paths } from '../../paths';
import { TEST_TYPE_FAMILY_COLOR } from '../../constants';
import { TEST_TYPE_FAMILY } from '../../graphql/queries';
import Banner from '../../components/Base/Banner';
import Advice from '../../components/Advice';
import QuoteBanner from '../../components/QuoteBanner';
import TestBlock from '../../components/TestBlock';
import { PrismicRichText } from '../../components/Base';
import ProfileBlock from '../../components/ProfileBlock';
import PrepTools from '../../containers/PrepTools';
import NextSessions from '../../components/SessionSearch/Next';
import { AppContext } from '../../store/App';
import { setParamsSearch } from '../../helpers/router';
import BannerSkeleton from '../../components/Base/Banner/BannerSkeleton';
import SkeletonItem from '../../components/SkeletonItem';
import HasSessionsAvailable from '../../containers/HasSessionsAvailable';
import HasPreptools from '../../containers/HasPreptools';
import HeaderMeta from '../../components/HeaderMeta';
import { useDataLayer, useQuery, useRouter } from '../../hooks';
import NotFoundHandler from '../../components/NotFoundHandler';
import { LocalizedRedirect } from '../../components/Routing';

import './styles.scss';

const TestTypeFamily = () => {
  const { lang, countryEmoIso2, currentRoute, generatePrismicDocumentTypeOrCustomLinkPath } = useRouter();
  const { params } = currentRoute;
  const { pushEventLoadedPage } = useDataLayer();
  const [toggledQuestion, setToggledQuestion] = useState('');
  const { city, region } = useContext(AppContext);
  const {
    data: { testTypeFamily },
    loading,
  } = useQuery(TEST_TYPE_FAMILY, { variables: { slug: params.slug, lang, country: countryEmoIso2 } });

  useEffect(() => {
    if (testTypeFamily) {
      pushEventLoadedPage(currentRoute, { testType: testTypeFamily.name });
    }
  }, [currentRoute, testTypeFamily]);

  if (testTypeFamily?.redirectLink?.slug) {
    return (
      <LocalizedRedirect status={301} to={generatePrismicDocumentTypeOrCustomLinkPath(testTypeFamily.redirectLink)} />
    );
  }

  let content = (
    <>
      <BannerSkeleton />
      <article className="product-page__main">
        <section className="container container--padding">
          <div className="product-page__row">
            <div className="product-page__column product-page__column--first">
              <SkeletonItem style={{ width: '100%', height: '300px' }} />
            </div>
            <div className={'product-page__column product-page__column--last'}>
              <SkeletonItem className={'skeleton-item--blue'} style={{ height: '240px', width: '100%' }} />

              <div className="product-page__buttons product-page__buttons--keep-mobile">
                <Button className={'product-page__button  skeleton-item skeleton-item--blue'}>
                  <div style={{ width: '10px', height: '16px' }} />
                </Button>
                <Button className={'product-page__button skeleton-item'}>
                  <div style={{ width: '10px', height: '16px' }} />
                </Button>
              </div>
            </div>
          </div>
        </section>
      </article>
    </>
  );

  if (!loading) {
    if (!testTypeFamily) return <NotFoundHandler />;
    let sessionSearchParam = {};
    if (city && region) {
      sessionSearchParam = {
        city: kebabCase(city),
        region: kebabCase(region),
      };
    }

    const testTypeFamilyColor = testTypeFamily.color ? TEST_TYPE_FAMILY_COLOR[testTypeFamily.color] : null;
    const testTypeIds = testTypeFamily.testTypes.filter((testType) => !!testType?.id).map((testType) => testType.id);

    content = (
      <>
        <HeaderMeta page={'test-type-family'} meta={testTypeFamily.meta} />
        <Banner
          title={testTypeFamily.name}
          subtitle={testTypeFamily.marketingHook}
          baseline={testTypeFamily.baseline}
          images={testTypeFamily.images}
          type={'product-page'}
          borderColor={testTypeFamilyColor}
        />
        <article className="product-page__main">
          <section className="container container--padding">
            <div className="product-page__row">
              <div className="product-page__column product-page__column--first">
                <div className="product-page__introduction">
                  <PrismicRichText richText={testTypeFamily.description} />
                  <div className="product-page__introduction-links">
                    {testTypeFamily.links.map(({ label, ...link }, key) => (
                      <Link key={key} icon={'arrow'} className={'product-page__link'} {...link}>
                        {label}
                      </Link>
                    ))}
                  </div>
                </div>
              </div>
              <div className="product-page__column product-page__column--last">
                {testTypeFamily.doYouKnowBox.length > 0 && (
                  <TestBlock
                    titleTranslationKey="product.good-you-know"
                    richText={fromJS(testTypeFamily.doYouKnowBox)}
                    color={testTypeFamilyColor}
                    className={'product-page__good-to-know'}
                  />
                )}
                <div className="product-page__buttons product-page__buttons--keep-mobile">
                  <HasSessionsAvailable testTypeIds={testTypeIds}>
                    {({ hasSessions }) =>
                      hasSessions ? (
                        <Button
                          className={'product-page__button'}
                          to={paths.SESSION_SEARCH}
                          params={sessionSearchParam}
                          search={setParamsSearch({
                            testTypeIds,
                            showMap: false,
                          })}
                        >
                          <Trans>product.find-a-session</Trans>
                        </Button>
                      ) : testTypeFamily.ifNoSessionContent && testTypeFamily.ifNoSessionContent.link ? (
                        <>
                          {testTypeFamily.ifNoSessionContent.shortText && (
                            <p className="product-page__if-no-session-pagragraph">
                              {testTypeFamily.ifNoSessionContent.shortText}
                            </p>
                          )}
                          <Button className={'product-page__button'} {...testTypeFamily.ifNoSessionContent.link}>
                            {testTypeFamily.ifNoSessionContent.link.label}
                          </Button>
                        </>
                      ) : (
                        <Button
                          className={'product-page__button'}
                          to={paths.PARTNER_SEARCH}
                          search={setParamsSearch({ testTypeIds })}
                        >
                          <Trans>product.find-a-session</Trans>
                        </Button>
                      )
                    }
                  </HasSessionsAvailable>
                  <HasPreptools testTypeFamily={testTypeFamily}>
                    {({ hasPrepTools }) =>
                      hasPrepTools && (
                        <Button
                          className={'button--transparent product-page__button'}
                          to={paths.PREP_TOOLS}
                          search={`testTypeFamilyName=${testTypeFamily.name}`}
                        >
                          <Trans>product.be-prepared</Trans>
                        </Button>
                      )
                    }
                  </HasPreptools>
                </div>
              </div>
            </div>
          </section>
          {testTypeFamily.testimonial && (
            <QuoteBanner
              type={<Trans>quote-banner.testimony</Trans>}
              quote={testTypeFamily.testimonial.title}
              author={testTypeFamily.testimonial.subtitle}
              images={testTypeFamily.images}
              borderColor={testTypeFamilyColor}
              withBorder={true}
              className={'product-page__quote-banner'}
            />
          )}
          <section className="container container--padding">
            <div className="product-page__row product-page__row--reverse">
              <div className="product-page__column product-page__column--first">
                {testTypeFamily.sections.map((section, index) => (
                  <Advice
                    id={index}
                    key={index}
                    title={section.title}
                    answer={section.description}
                    className={classNames('product-page__advice', {
                      'product-page__advice--first': index === 0,
                    })}
                    isOpen={toggledQuestion === index}
                    toggleQuestion={() => setToggledQuestion(toggledQuestion === index ? '' : index)}
                  />
                ))}
              </div>
              {testTypeFamily.informationBoxContent.length > 0 && (
                <div className="product-page__column product-page__column--last">
                  <TestBlock
                    titleTranslationKey="product.information"
                    richText={fromJS(testTypeFamily.informationBoxContent)}
                    docs={testTypeFamily.informationBoxFiles}
                    color={testTypeFamilyColor}
                    className={'product-page__test-block'}
                  />
                </div>
              )}
            </div>
          </section>
          <aside>
            <NextSessions className={'product-page__next-sessions'} testTypeFamilies={[testTypeFamily]} />
            <PrepTools testTypeFamily={testTypeFamily} />
          </aside>
          <section className="product-page__who-are-you">
            {testTypeFamily.profiles.map((profile, key) => {
              let profileBlockProps = {
                ...profile,
                testimonials: profile.scenarios
                  .filter(
                    ({ editorialContentHighlighted: editorialContent }) =>
                      editorialContent && editorialContent.__typename === 'Testimonial',
                  )
                  .map(({ editorialContentHighlighted: editorialContent }) => editorialContent),
              };

              return <ProfileBlock key={key} {...profileBlockProps} />;
            })}
          </section>
        </article>
      </>
    );
  }

  return <main className={'main main--no-offset-top'}>{content}</main>;
};

export default TestTypeFamily;
