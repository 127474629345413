/*global google:true*/

import React, { useRef, useEffect } from 'react';
import scriptLoader from 'react-async-script-loader';

import { env } from '../../helpers/env';

const GoogleInteractiveMap = ({
  center,
  zoom,
  markers,
  onChange = () => {},
  onMarkerClicked = () => {},
  className = '',
}) => {
  const mapEl = useRef(null);

  useEffect(() => {
    if (typeof google !== 'object' || typeof google.maps !== 'object') {
      return;
    }
    const map = new google.maps.Map(mapEl.current, {
      center,
      zoom,
      disableDefaultUI: true,
      zoomControl: true,
      zoomControlOptions: {
        position: google.maps.ControlPosition.RIGHT_TOP,
      },
    });
    map.addListener('click', function () {
      onMarkerClicked(null);
    });

    markers.map(({ latitude: lat, longitude: lng, id }) => {
      let marker = new google.maps.Marker({
        position: { lat: parseFloat(lat), lng: parseFloat(lng) },
        icon: require('../../assets/images/marker.png'),
        map,
      });

      marker.addListener('click', () => {
        map.setCenter(marker.getPosition());

        onMarkerClicked(id);
      });
    });

    map.addListener('idle', () => onChange(map));
  });

  return <div className={className} ref={mapEl} />;
};

export default React.memo(
  scriptLoader([`https://maps.googleapis.com/maps/api/js?key=${env('RAZZLE_B2C_WEBSITE_GOOGLE_API_KEY')}`])(
    GoogleInteractiveMap,
  ),
  (prevProps, nextProps) => {
    return (
      prevProps.center.lat === nextProps.center.lat &&
      prevProps.center.lng === nextProps.center.lng &&
      prevProps.markers.equals(nextProps.markers)
    );
  },
);
