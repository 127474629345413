import './styles.scss';

import { useMutation } from '@apollo/client';
import { Button, Loader } from '@ets-global/b2c-website-ui';
import { fromJS } from 'immutable';
import React, { useContext, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router';

import { PrismicRichText } from '../../components/Base';
import Banner from '../../components/Base/Banner';
import { ArticleBlock, MoreLinks } from '../../components/Content';
import HeaderMeta from '../../components/HeaderMeta';
import NotFoundHandler from '../../components/NotFoundHandler';
import TestBlock from '../../components/TestBlock';
import { ECBlockType, TEST_TYPE_FAMILY_COLOR } from '../../constants';
import { GENERATE_TESTING_TOKEN } from '../../graphql/mutations';
import { PRACTICE_TEST } from '../../graphql/queries';
import { env } from '../../helpers/env';
import { useDataLayer, useRouter } from '../../hooks';
import useAuth from '../../hooks/useAuth';
import useQuery from '../../hooks/useQuery';
import { paths } from '../../paths';
import { AppContext } from '../../store/App';
import { AuthContext } from '../../store/Auth';

const PracticeTest = () => {
  const { isAuthenticated } = useAuth();
  const location = useLocation();
  const history = useHistory();
  const { user } = useContext(AuthContext);
  const { country: countryPrismic } = useContext(AppContext);

  const {
    currentRoute: {
      params: { slug, locale: lang, country },
    },
    generatePath,
  } = useRouter();

  const {
    data: { practiceTest },
    loading,
  } = useQuery(PRACTICE_TEST, { variables: { slug, lang, country } });
  const [mutateGenerateTestingToken, generateTestingTokenResult] = useMutation(GENERATE_TESTING_TOKEN);
  const { pushEventLoadedPlacementTestFunnel } = useDataLayer();

  const generateTestingToken = () =>
    mutateGenerateTestingToken({
      variables: {
        testingSlug: practiceTest.testingSlug,
        destinationUrlIsNotAlreadyPassed: `${env(
          'RAZZLE_B2C_WEBSITE_URL'
        )}/${country}/${lang}/practice-test/${slug}/:sessionId/conclusion-form`,
        destinationUrlIsAlreadyPassed: `${env(
          'RAZZLE_B2C_WEBSITE_URL'
        )}/${country}/${lang}/practice-test/${slug}/:sessionId/result-page`,
        region: countryPrismic.regionName,
        country: countryPrismic.name,
        userStatus: user ? (user?.hasPurchases ? 'purchaser' : 'loggedIndUser') : 'anonymous',
      },
    });

  useEffect(() => {
    pushEventLoadedPlacementTestFunnel({
      stepName: 'placement test home',
      stepNumber: '1',
    });
  }, [slug]);

  useEffect(() => {
    if (location?.state?.isLogged && practiceTest?.testingSlug) {
      generateTestingToken();
    }
  }, [location?.state?.isLogged, practiceTest?.testingSlug]);

  useEffect(() => {
    if (generateTestingTokenResult?.data?.token) {
      window.location = `${env('RAZZLE_TESTING_WEB_URL')}/${lang}/token/${generateTestingTokenResult?.data?.token}`;
    }
  }, [generateTestingTokenResult.loading]);

  const onStartTest = () => {
    if (!isAuthenticated) {
      history.push(generatePath(paths.LOGIN, {}, encodeURI(`?referrer=${location.pathname}`)));

      return;
    }
    generateTestingToken();
  };

  if (loading) {
    return <Loader />;
  }

  if (!practiceTest) {
    return <NotFoundHandler />;
  }

  const color = practiceTest.testTypeFamilyColor ? TEST_TYPE_FAMILY_COLOR[practiceTest.testTypeFamilyColor] : null;

  const blocks = fromJS(practiceTest.blocks).map((block) => {
    switch (block.get('__typename')) {
      case ECBlockType.ECStepsBlock:
        block = block.set('stepColor', color);
        break;
      case ECBlockType.ECIconsBlock:
        block = block
          .set('iconColor', color)
          .set('practiceTestButtonLabel', practiceTest.buttonLabel)
          .set('onStartTest', onStartTest)
          .set('loading', generateTestingTokenResult.loading);
        break;
    }

    return block;
  });

  return (
    <main className="main main--no-offset-top">
      <HeaderMeta page={'practice-test'} meta={practiceTest.meta} />
      <Banner
        title={practiceTest.name}
        baseline={practiceTest.baseline}
        images={practiceTest.backgroundImage}
        borderColor={color}
        type={'practice-test-page'}
      />
      <article className="practice-test-page__main">
        <div className="container container--padding">
          <div className="practice-test-page__row">
            <div className="practice-test-page__column practice-test-page__column--first">
              <div className="practice-test-page__introduction">
                <h2 style={{ color }}>{practiceTest.titleDescription}</h2>
                <PrismicRichText richText={practiceTest.description} />
              </div>
            </div>
            <div className="practice-test-page__column practice-test-page__column--last">
              <TestBlock title={practiceTest.boxTitle} richText={fromJS(practiceTest.boxDescription)} color={color} />
              <Button
                className={'practice-test-page__button'}
                onClick={onStartTest}
                loading={generateTestingTokenResult.loading}
              >
                {practiceTest.buttonLabel}
              </Button>
            </div>
          </div>
        </div>
        {blocks.map((block, key) => (
          <ArticleBlock key={key} block={block} />
        ))}
      </article>
      <aside>
        {(practiceTest.links.length > 0 || practiceTest.documents.length > 0) && (
          <MoreLinks links={fromJS(practiceTest.links)} documents={fromJS(practiceTest.documents)} />
        )}
      </aside>
    </main>
  );
};

export default PracticeTest;
