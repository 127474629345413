import React from 'react';
import PropTypes from 'prop-types';
import { Trans, useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { FieldWrapper, Icon, DatePickerField } from '@ets-global/b2c-website-ui';

import { dateToISOString } from '../../../helpers/Formatter/DateFormatter';
import { GooglePlacesAutocompleteContainer } from '../../../containers/GooglePlacesAutocomplete';

import './styles.scss';

const SessionSearchFilters = ({
  sessionSearchFilter,
  onFilterChange,
  filtersDisplayed,
  toggleFilter,
  withCity = true,
  isEproctored = false,
}) => {
  const { t } = useTranslation();

  return (
    <div className={classNames('search-session-filters', filtersDisplayed && 'search-session-filters--collapse')}>
      <button className="search-session-filters__mobile-toggler" onClick={toggleFilter}>
        <Trans>session-search.main.filters.title</Trans>
      </button>
      <div className="search-session-filters__list">
        {withCity && !isEproctored && (
          <div className="search-session-filters__filter">
            <GooglePlacesAutocompleteContainer
              id="filter-by-location"
              label={t('session-search.main.label_autocomplete')}
              defaultRegion={sessionSearchFilter.region}
              defaultCity={sessionSearchFilter.city}
              onSelectedItemChange={onFilterChange}
            />
          </div>
        )}
        <div className="search-session-filters__filter">
          <FieldWrapper
            id="filter-by-date"
            label={t('session-search.main.label_datepicker')}
            icon="calendar"
            hasValue={Boolean(sessionSearchFilter.date)}
          >
            <DatePickerField
              id="filter-by-date"
              name="date"
              value={sessionSearchFilter.date}
              setFieldValue={(date) => onFilterChange({ date: dateToISOString(date) })}
              minDate={'today'}
              maxDate={new Date(new Date().setFullYear(new Date().getFullYear() + 1))}
            />
          </FieldWrapper>
        </div>
        {!isEproctored && (
          <div className="search-session-filters__toggler">
            <button
              className="search-session-filters__toggler-button"
              onClick={() => {
                onFilterChange({ showMap: !sessionSearchFilter.showMap });
                if (!sessionSearchFilter.showMap) {
                  toggleFilter();
                }
              }}
            >
              {!sessionSearchFilter.showMap ? (
                <span className="search-session-filters__icon-map">
                  <Trans>session-search.main.filters.show_map</Trans>
                  <Icon name="map" />
                </span>
              ) : (
                <span className="search-session-filters__icon-list">
                  <Trans>session-search.main.filters.show_list</Trans>
                  <Icon name="list" />
                </span>
              )}
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

SessionSearchFilters.propTypes = {
  sessionSearchFilter: PropTypes.object.isRequired,
  onFilterChange: PropTypes.func.isRequired,
  filtersDisplayed: PropTypes.bool.isRequired,
  toggleFilter: PropTypes.func.isRequired,
  withCity: PropTypes.bool,
  isEproctored: PropTypes.bool,
};

export default SessionSearchFilters;
