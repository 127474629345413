import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import { paths } from '../../paths';
import { useQuery, useRouter } from '../../hooks';
import { PARTNERS } from '../../graphql/queries';
import { AppContext } from '../../store/App';
import HasSessionsAvailable from '../HasSessionsAvailable';
import { LocalizedRedirect } from '../../components/Routing';
import PartnerSearch from '../../pages/PartnerSearch';
import SessionSearch from '../../pages/BuyingProcess/Search';

const RedirectSessionSearchOrPartnerSearch = ({ isSessionSearch = true }) => {
  const { countryEmoIso2 } = useRouter();
  const {
    data: { partners = [] },
    loading: partnersLoading,
  } = useQuery(PARTNERS, {
    variables: { country: countryEmoIso2 },
  });

  return (
    <HasSessionsAvailable>
      {({ loading, hasSessions }) => {
        const { country } = useContext(AppContext);
        const isRedirected = isSessionSearch
          ? !hasSessions && (country.isInOutOfFootprintSubRegion || partners.length > 0)
          : hasSessions || (!country.isInOutOfFootprintSubRegion && partners.length === 0);
        if (!loading && !partnersLoading && isRedirected) {
          return <LocalizedRedirect status={301} to={isSessionSearch ? paths.PARTNER_SEARCH : paths.SESSION_SEARCH} />;
        }

        return isSessionSearch ? <SessionSearch /> : <PartnerSearch />;
      }}
    </HasSessionsAvailable>
  );
};

RedirectSessionSearchOrPartnerSearch.propTypes = {
  isSessionSearch: PropTypes.bool,
};

export default RedirectSessionSearchOrPartnerSearch;
