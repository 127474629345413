import React from 'react';
import PropTypes from 'prop-types';

import { Button } from '@ets-global/b2c-website-ui';

import './styles.scss';

const ECIconsBlock = ({ title, icons, practiceTestButtonLabel, iconColor, onStartTest, loading }) => (
  <div className="ec-icons-block">
    <div className="container container--padding">
      <p className="ec-icons-block__title">{title}</p>
      <div className="ec-icons-block__icons">
        {icons.map((iconItem, key) => (
          <div key={key} className="icon-item">
            {iconItem?.icon && <img className="icon-item__image" src={iconItem.icon?.url} alt={iconItem.icon?.alt} />}
            {iconItem?.title && (
              <span className="icon-item__title" style={{ color: iconColor }}>
                {iconItem.title}
              </span>
            )}
            {iconItem?.description && <span className="icon-item__description">{iconItem.description}</span>}
          </div>
        ))}
      </div>
      {practiceTestButtonLabel && (
        <Button className={'ec-icons-block__practice-test-button'} onClick={onStartTest} loading={loading}>
          {practiceTestButtonLabel}
        </Button>
      )}
    </div>
  </div>
);

ECIconsBlock.propTypes = {
  title: PropTypes.string.isRequired,
  icons: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.shape({
        url: PropTypes.string,
        alt: PropTypes.string,
      }),
      title: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
    }),
  ).isRequired,
  practiceTestButtonLabel: PropTypes.string,
  iconColor: PropTypes.string,
  onStartTest: PropTypes.func,
  loading: PropTypes.bool,
};

export default ECIconsBlock;
