import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Advice from '../../../Advice';
import { useDataLayer } from '../../../../hooks';

import './styles.scss';

const ECFaqBlock = ({ title, subtitle, faqItems }) => {
  const [toggledQuestion, setToggledQuestion] = useState('');
  const { pushEventFaq } = useDataLayer();

  const handleToggleQuestion = (index, title) => {
    pushEventFaq(title);
    setToggledQuestion(toggledQuestion === index ? '' : index);
  };

  return (
    <div className="ec-faq-block">
      <div className="container container--padding">
        <p className="ec-faq-block__title">{title}</p>
        {subtitle && <p className="ec-faq-block__subtitle">{subtitle}</p>}
        <div className="ec-faq-block__content">
          {faqItems.map((item, index) => (
            <Advice
              key={index}
              id={index}
              title={item.question}
              answer={item.answer}
              isOpen={toggledQuestion === index}
              toggleQuestion={() => handleToggleQuestion(index, item.question)}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

ECFaqBlock.prototype = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  faqItems: PropTypes.arrayOf(
    PropTypes.shape({
      question: PropTypes.string.isRequired,
      answer: PropTypes.array.isRequired,
    }),
  ).isRequired,
};

export default ECFaqBlock;
