import React from 'react';
import PropTypes from 'prop-types';

import { PrismicRichText } from '../../../Base';

import './styles.scss';

const ECTableBlock = ({ title, subtitle, tableItems }) => (
  <div className="ec-table-block">
    <div className="container container--padding">
      <p className="ec-table-block__title">{title}</p>
      {subtitle && <p className="ec-table-block__subtitle">{subtitle}</p>}
      <div className="ec-table-block__content">
        {tableItems.map((tableItem, key) => (
          <div key={key} className="ec-table-item">
            <p className="ec-table-item__title">{tableItem.title}</p>
            {tableItem?.description && (
              <PrismicRichText className="ec-table-item__description" richText={tableItem.description} />
            )}
          </div>
        ))}
      </div>
    </div>
  </div>
);

ECTableBlock.prototype = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  tableItems: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      description: PropTypes.array.isRequired,
    }).isRequired,
  ).isRequired,
};

export default ECTableBlock;
