import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import merge from 'lodash/merge';
import * as yup from 'yup';
import { Trans } from 'react-i18next';
import kebabCase from 'lodash/kebabCase';

import { Button } from '@ets-global/b2c-website-ui';

import { paths } from '../../../paths';
import SessionSearchMain from '../Main';
import { setParamsSearch } from '../../../helpers/router';
import { AppContext } from '../../../store/App';
import { dateToISOString } from '../../../helpers/Formatter/DateFormatter';
import HasSessionsAvailable from '../../../containers/HasSessionsAvailable';

import './styles.scss';

const NextSessions = ({ testTypeFamilies, className }) => {
  const { city, region, coordinates } = useContext(AppContext);

  const getValidatedFilter = (filter) => {
    let constraints = {
      date: yup
        .string()
        .required()
        .default(() => dateToISOString()),
      showMap: yup.boolean().default(false),
    };

    const schemaValidation = yup.object().shape(constraints);

    return schemaValidation.cast(filter);
  };

  const testTypeIds = testTypeFamilies.reduce((acc, testTypeFamily) => {
    acc = merge(
      acc,
      testTypeFamily.testTypes.map((testTypes) => testTypes.id),
    );

    return acc;
  }, []);

  const [sessionSearchFilter, setSessionSearchFilter] = useState(
    getValidatedFilter({
      date: dateToISOString(),
      showMap: false,
    }),
  );

  const filters = { ...sessionSearchFilter, testTypeIds };
  let sessionSearchParam = {};
  if (city && region) {
    sessionSearchParam = {
      city: kebabCase(city),
      region: kebabCase(region),
    };
  }

  return (
    <HasSessionsAvailable testTypeIds={testTypeIds}>
      {({ hasSessions, isEproctored }) =>
        hasSessions && (
          <div className={classNames('next-sessions', className)}>
            <div className="container container--padding">
              <SessionSearchMain
                className={'next-sessions__main'}
                sessionSearchFilter={{ coordinates, city, ...filters }}
                loading={false}
                onFilterChange={(data) => setSessionSearchFilter({ ...filters, ...data })}
                title={
                  <Trans values={{ cityName: city }}>
                    {isEproctored
                      ? 'session-search.next-session.next-session-title-online'
                      : 'session-search.next-session.next-session-title'}
                  </Trans>
                }
                itemNumber={3}
                withCity={false}
                withDisabilityMessage={false}
                showLoadMore={false}
                ssr={false}
                isEproctored={isEproctored}
              />
              <Button
                className={'next-sessions__button'}
                to={paths.SESSION_SEARCH}
                params={sessionSearchParam}
                search={setParamsSearch(filters)}
              >
                <Trans>session-search.next-session.more-session</Trans>
              </Button>
            </div>
          </div>
        )
      }
    </HasSessionsAvailable>
  );
};

NextSessions.propTypes = {
  testTypeFamilies: PropTypes.arrayOf(
    PropTypes.shape({
      testTypes: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number.isRequired,
        }),
      ).isRequired,
    }),
  ).isRequired,
  className: PropTypes.string,
  sessionSearchFilter: PropTypes.shape({
    showMap: PropTypes.bool,
  }),
  onFilterChange: PropTypes.func,
};

export default NextSessions;
