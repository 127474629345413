import React from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';
import classNames from 'classnames';
import { Trans } from 'react-i18next';

import { useDateToString } from '../../Element/DateToString';
import Picture from '../../Element/Picture';
import { PrismicRichText, SocialShare } from '../../Base';
import InjectHtml from '../../InjectHtml';

import './styles.scss';

const ArticleHeader = ({ customClass, editorialContent }) => {
  const DateToString = useDateToString();
  const isNotBasicContent = editorialContent.get('editorialContentType') !== 'BASIC_CONTENT';

  return (
    <header
      className={classNames(
        'article-header',
        customClass,
        {
          'article-header--testimony': editorialContent.get('editorialContentType') === 'TESTIMONIAL',
          'article-header--case-study': editorialContent.get('editorialContentType') === 'CASE_STUDY',
        },
        'container',
        'container--padding',
      )}
    >
      <div className="article-header__top">
        {editorialContent.get('images') && (
          <div className="article-header__picture">
            <Picture images={editorialContent.get('images').toJS()} className={'article-header__picture-element'} />
          </div>
        )}
        <div className="article-header__headings">
          {isNotBasicContent && editorialContent.get('editorialContentType') && (
            <p className="article-header__category">
              <Trans>
                blog.editorial-content.category.{editorialContent.get('editorialContentType').toLowerCase()}
              </Trans>
            </p>
          )}
          <InjectHtml component="h1" className="article-header__title" html={editorialContent.get('title')} />
          {editorialContent.get('subtitle') && (
            <InjectHtml component="p" className="article-header__subtitle" html={editorialContent.get('subtitle')} />
          )}
        </div>
      </div>
      <div className="article-header__bottom">
        {isNotBasicContent && editorialContent.get('date') && (
          <div className="article-header__tags">
            <time className="article-header__tags-time" dateTime={editorialContent.get('date')}>
              <DateToString date={editorialContent.get('date')} format="MMMM YYYY" />
            </time>
          </div>
        )}
        <div className="article-header__shares">
          {editorialContent.get('meta') && <SocialShare title={editorialContent.get('meta').get('title')} />}
        </div>
        {editorialContent.get('summary') && (
          <PrismicRichText className="article-header__desc" richText={editorialContent.get('summary').toJS()} />
        )}
      </div>
    </header>
  );
};

ArticleHeader.propTypes = {
  customClass: PropTypes.string,
  editorialContent: PropTypes.instanceOf(Map).isRequired,
};

export default ArticleHeader;
