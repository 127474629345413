import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Map } from 'immutable';

import { ECBlockType } from '../../../constants';
import ECStepsBlock from '../ECBlocks/ECStepsBlock';
import ECIconsBlock from '../ECBlocks/ECIconsBlock';
import ECDiscountBlock from '../ECBlocks/ECDiscountBlock';
import ECDefaultBlock from '../ECBlocks/ECDefaultBlock';
import NextSessions from '../../SessionSearch/Next';
import PrepToolsContainer from '../../../containers/PrepTools';

import ECHorizontalIconContentBlock from '../ECBlocks/ECHorizontalIconContentBlock';
import ECIconBulletPointListBlock from '../ECBlocks/ECIconBulletPointListBlock';
import ECLinksBlock from '../ECBlocks/ECLinksBlock';
import ECTableBlock from '../ECBlocks/ECTableBlock';
import ECSmallVideoBlock from '../ECBlocks/ECSmallVideoBlock';
import ECFaqBlock from '../ECBlocks/ECFaqBlock';
import ECLegalSentenceForHomeTestBlock from '../ECBlocks/ECLegalSentenceForHomeTestBlock';

import './styles.scss';

const ArticleBlock = ({ block, customClass }) => {
  const classes = classNames('article-block', customClass, {
    'article-block--navy': block.get('backgroundColor') === 'NAVY',
    'article-block--grey': block.get('backgroundColor') === 'GREY',
    'article-block--sky': block.get('backgroundColor') === 'SKY' || block.has('discounts'),
    'article-block--image-left': block.get('images') && block.get('imagePosition') === 'LEFT',
    'article-block--image-right': block.get('images') && block.get('imagePosition') === 'RIGHT',
    'article-block--image-round': block.get('images') && block.get('__typename') === 'ECRoundImageBlock',
    'article-block--video': !!block.get('video'),
    'article-block--sessions': block.get('__typename') === 'ECSessionsBlock',
  });

  let content;
  switch (block.get('__typename')) {
    case ECBlockType.ECStepsBlock:
      content = <ECStepsBlock {...block.toJS()} />;
      break;
    case ECBlockType.ECIconsBlock:
      content = <ECIconsBlock {...block.toJS()} />;
      break;
    case ECBlockType.ECDiscountBlock:
      content = <ECDiscountBlock {...block.toJS()} />;
      break;
    case ECBlockType.ECSessionsBlock:
      content = (
        <aside>
          <NextSessions {...block.toJS()} />
        </aside>
      );
      break;
    case ECBlockType.ECPreparationToolsBlock:
      content = (
        <aside>
          <PrepToolsContainer {...block.toJS()} />
        </aside>
      );
      break;
    case ECBlockType.ECSmallVideoBlock:
      content = <ECSmallVideoBlock {...block.toJS()} />;
      break;
    case ECBlockType.ECHorizontalIconContentListBlock:
      content = <ECHorizontalIconContentBlock {...block.toJS()} />;
      break;
    case ECBlockType.ECIconBulletPointListBlock:
      content = <ECIconBulletPointListBlock {...block.toJS()} />;
      break;
    case ECBlockType.ECLinksBlock:
      content = <ECLinksBlock {...block.toJS()} />;
      break;
    case ECBlockType.ECFaqBlock:
      content = <ECFaqBlock {...block.toJS()} />;
      break;
    case ECBlockType.ECTableBlock:
      content = <ECTableBlock {...block.toJS()} />;
      break;
    case ECBlockType.ECLegalSentenceForHomeTestBlock:
      content = <ECLegalSentenceForHomeTestBlock {...block.toJS()} />;
      break;
    default:
      content = <ECDefaultBlock {...block.toJS()} />;
      break;
  }

  return <section className={classes}>{content}</section>;
};

ArticleBlock.propTypes = {
  block: PropTypes.instanceOf(Map).isRequired,
  customClass: PropTypes.string,
};

export default ArticleBlock;
