import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { Fields } from '@ets-global/b2c-website-ui';
import { PRACTICE_TEST_CANDIDATE_TYPE } from '../../constants';

import './styles.scss';

const PracticeTestConclusionFormB2B = () => {
  const { t } = useTranslation();

  return (
    <div className="practice-test-conclusion__form-candidate-type">
      <p className="practice-test-conclusion__form-candidate-type-description">
        <Trans>practice-test.candidate-type.description</Trans>
      </p>
      <div className="practice-test-conclusion__form-candidate-type-radios">
        <Fields.FormikFastField
          id={PRACTICE_TEST_CANDIDATE_TYPE.IS_CLIENT}
          name="candidateType"
          component={Fields.RadioField}
          label={t('practice-test.candidate-type.is_client')}
        />
        <Fields.FormikFastField
          id={PRACTICE_TEST_CANDIDATE_TYPE.IS_NOT_CLIENT}
          name="candidateType"
          component={Fields.RadioField}
          label={t('practice-test.candidate-type.is_not_client')}
        />
      </div>
    </div>
  );
};

export default PracticeTestConclusionFormB2B;
