import React from 'react';
import PropTypes from 'prop-types';

import { Button } from '@ets-global/b2c-website-ui';
import { PrismicRichText } from '../../../Base';

import './styles.scss';

const ECSmallVideoBlock = ({ title, video, description, link }) => (
  <div className="container container--padding">
    <div className="ec-small-video-block">
      <div className="ec-small-video-block__video">
        <iframe
          title={title}
          width="560"
          height="315"
          src={`https://www.youtube.com/embed/${video}`}
          frameBorder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          modestbranding="1"
        />
      </div>
      <div className="ec-small-video-block__content">
        <p className="ec-small-video-block__content-title">{title}</p>
        {description && (
          <PrismicRichText className="ec-small-video-block__content-description" richText={description} />
        )}
        {link?.label && link?.to && (
          <Button className={'ec-small-video-block__content-button'} to={link?.to}>
            {link.label}
          </Button>
        )}
      </div>
    </div>
  </div>
);

ECSmallVideoBlock.prototype = {
  title: PropTypes.string.isRequired,
  description: PropTypes.array,
  video: PropTypes.string.isRequired,
  link: PropTypes.shape({
    label: PropTypes.string.isRequired,
    to: PropTypes.string.isRequired,
  }),
};

export default ECSmallVideoBlock;
