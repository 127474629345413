import React from 'react';
import PropTypes from 'prop-types';

import { Button } from '@ets-global/b2c-website-ui';

import Picture from '../../../Element/Picture';
import InjectHtml from '../../../InjectHtml';
import { PrismicRichText } from '../../../Base';

const ECDefaultBlock = ({ images, quote, author, text, video, title, subtitle, link }) => (
  <div className="container container--padding">
    <div className="article-block__inner">
      {images && (
        <div className="article-block__block-picture">
          <Picture images={images} />
        </div>
      )}

      {quote && (
        <div className="article-block__quote">
          <blockquote className="article-block__quote-text">{quote}</blockquote>
          {author && <p className="article-block__quote-author">{author}</p>}
        </div>
      )}

      {(text || video) && (
        <div className="article-block__block-text">
          {title && <InjectHtml component="p" className="article-block__title" html={title} />}
          {subtitle && <InjectHtml component="p" className="article-block__subtitle" html={subtitle} />}

          {video && (
            <div className="article-block__video-wrapper">
              <iframe
                title={title}
                width="560"
                height="315"
                src={`https://www.youtube.com/embed/${video}`}
                frameBorder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                modestbranding="1"
              />
            </div>
          )}

          {text && <PrismicRichText className="article-block__text" richText={text} />}

          {link && (
            <Button {...link} className="article-block__link">
              {link.label}
            </Button>
          )}
        </div>
      )}
    </div>
  </div>
);

ECDefaultBlock.propTypes = {
  images: PropTypes.arrayOf(
    PropTypes.shape({
      url: PropTypes.string,
      alt: PropTypes.string,
    }),
  ),
  quote: PropTypes.string,
  author: PropTypes.string,
  text: PropTypes.array.isRequired,
  video: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  link: PropTypes.shape({
    label: PropTypes.string,
    to: PropTypes.string,
  }),
};

export default ECDefaultBlock;
