import React from 'react';
import PropTypes from 'prop-types';

import Link from '@ets-global/b2c-website-ui/Link';

import { Separator } from '../../Base';

import './styles.scss';

const ETSInfoBlock = ({ title, description, link, infoBlocks }) => {
  const { label: labelLink, ...propsLink } = link;

  return (
    <section className="container">
      <Separator />

      <div className="discover-ets">
        <div className="discover-ets__column">
          <h3 className="discover-ets__title">{title}</h3>
          <p className="discover-ets__paragraph">{description}</p>
          <Link icon={'arrow'} {...propsLink}>
            {labelLink}
          </Link>
        </div>
        <div className="discover-ets__column">
          <ul className="discover-ets__keyfacts">
            {infoBlocks.map((info, key) => (
              <li key={key} className="discover-ets__fact">
                <p className="discover-ets__fact-number">{info.title}</p>
                <p className="discover-ets__fact-text">{info.description}</p>
              </li>
            ))}
          </ul>
          <Link icon={'arrow'} {...propsLink}>
            {labelLink}
          </Link>
        </div>
      </div>
    </section>
  );
};

ETSInfoBlock.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  link: PropTypes.object,
  infoBlocks: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      description: PropTypes.string,
    }),
  ),
};

export default ETSInfoBlock;
