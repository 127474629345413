import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Button } from '@ets-global/b2c-website-ui';
import { PrismicRichText } from '../../../Base';
import { useDataLayer } from '../../../../hooks';

import './styles.scss';

const ECHorizontalIconContentBlock = ({ title, subtitle, horizontalIconContentItems }) => {
  const { pushEventGAClickNavigation } = useDataLayer();

  const isLineOfThreeItems = () => {
    if ((horizontalIconContentItems.length + 1) % 4 === 0) {
      return false;
    }

    return horizontalIconContentItems.length % 4 !== 0;
  };

  return (
    <div className="ec-horizontal-icon-content-block">
      <div className="container container--padding">
        <p className="ec-horizontal-icon-content-block__title">{title}</p>
        {subtitle && <p className="ec-horizontal-icon-content-block__subtitle">{subtitle}</p>}
        <div
          className={classNames('ec-horizontal-icon-content-block__content', {
            'ec-horizontal-icon-content-block__content--line-of-three-items': isLineOfThreeItems(),
          })}
        >
          {horizontalIconContentItems.map((contentItem, key) => (
            <div key={key} className="ec-horizontal-icon-content-item">
              <div className="ec-horizontal-icon-content-item__icons">
                <span className="ec-horizontal-icon-content-item__icons-order">{key + 1}</span>
                {contentItem?.icon && (
                  <div className="ec-horizontal-icon-content-item__icons-image">
                    <img src={contentItem.icon?.url} alt={contentItem.icon?.alt} />
                  </div>
                )}
              </div>
              <p className="ec-horizontal-icon-content-item__title">{contentItem?.title}</p>
              {contentItem?.description && (
                <PrismicRichText
                  className="ec-horizontal-icon-content-item__description"
                  richText={contentItem.description}
                />
              )}
              <div className="ec-horizontal-icon-content-item__footer">
                {contentItem?.link?.to && contentItem.link?.label && (
                  <Button
                    className="ec-horizontal-icon-content-item__link button--transparent-light"
                    to={contentItem.link?.to}
                    onClick={() =>
                      pushEventGAClickNavigation({
                        eventLabel: 'instruction details',
                        stepDetail: `${key + 1} - ${contentItem?.title}`,
                        buttonLabel: contentItem.link?.label,
                      })
                    }
                  >
                    {contentItem.link?.label}
                  </Button>
                )}
                <span className="ec-horizontal-icon-content-item__border" />
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

ECHorizontalIconContentBlock.prototype = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  horizontalIconContentItems: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      icon: PropTypes.shape({
        url: PropTypes.string.isRequired,
        alt: PropTypes.string,
      }).isRequired,
      link: PropTypes.shape({
        label: PropTypes.string.isRequired,
        to: PropTypes.string.isRequired,
      }),
    }),
  ).isRequired,
};

export default ECHorizontalIconContentBlock;
