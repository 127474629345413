import React, { Fragment, useContext } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { Button } from '@ets-global/b2c-website-ui';

import TestTypeFamilyProgram from './TestTypeFamilyProgram';
import Tooltip from '../../../Base/Tooltip/Tooltip';
import TestTypeFamilyTitle from '../../../Title/TestTypeFamilyTitle';
import ModalContext from '../../../../store/Modal/ModalContext';
import ChangeTestTypeFilterModal from '../../ChangeTestTypeFilterModal';
import PrismicRichText from '../../../Base/PrismicRichText';
import { DEFAULT_SESSION_SEARCH_DISTANCE, TestTypeFormat } from '../../../../constants';

const SearchSessionTestTypeFamilyChoice = ({
  testTypeFamily,
  checkedTestTypeIds,
  sessionSearchFilter,
  toggleBlocks,
  pushToUrl,
  canChangeTestTypeFilter = true,
  isChangeTestTypeFilterModalDisplayed = false,
}) => {
  const { t } = useTranslation();
  const { showModal, hideModal } = useContext(ModalContext);

  const programs = testTypeFamily.testTypes.reduce((currentPrograms, testType) => {
    if (!currentPrograms[testType.program]) {
      currentPrograms[testType.program] = {
        type: testType.program,
        shortDescription: testType.shortDescription,
        scoreReport: testType.scoreReport,
        formats: {},
      };
    }

    if (!currentPrograms[testType.program].formats[testType.format]) {
      currentPrograms[testType.program].formats[testType.format] = {
        type: testType.format,
        title: testType.format === TestTypeFormat.CBT ? testType.cbtContent.title : testType.pbtContent.title,
        description:
          testType.format === TestTypeFormat.CBT ? testType.cbtContent.description : testType.pbtContent.description,
        iconName: testType.format.toLowerCase(),
        promotionLabel:
          testType.format === TestTypeFormat.CBT
            ? testType.cbtContent.promotionLabel
            : testType.pbtContent.promotionLabel,
        testTypeIds: [],
      };
    }

    currentPrograms[testType.program].formats[testType.format].testTypeIds.push(testType.id);

    return currentPrograms;
  }, {});

  let isOpen = false;
  let testTypeIds = [];

  testTypeFamily.testTypes.forEach((testType) => {
    testTypeIds.push(testType.id);
    if (checkedTestTypeIds.indexOf(testType.id) >= 0) {
      isOpen = true;
    }
  });

  return (
    <div
      className={classNames('search-session-choice__block', {
        'search-session-choice__block--closed': !isOpen,
      })}
    >
      <TestTypeFamilyTitle
        component="h3"
        className="search-session-choice__block-title"
        dataCypress={`testTypeFamily-${testTypeFamily.id}`}
        color={isOpen ? testTypeFamily.color : null}
        role="presentation"
        onClick={() => {
          if (isChangeTestTypeFilterModalDisplayed) {
            showModal(ChangeTestTypeFilterModal, {
              onClose: () => {
                hideModal();
              },
              onSubmit: () => {
                pushToUrl({ testTypeIds, distance: DEFAULT_SESSION_SEARCH_DISTANCE });
                hideModal();
              },
            });

            return;
          }
          pushToUrl({ testTypeIds, distance: DEFAULT_SESSION_SEARCH_DISTANCE });
        }}
        title={testTypeFamily.name}
      />
      <div className="search-session-choice__block-content">
        {testTypeFamily.shortDescription && (
          <PrismicRichText
            className="search-session-choice__block-paragraph"
            richText={testTypeFamily.shortDescription}
            forceTargetBlank={true}
          />
        )}
        {Object.keys(programs).length === 2 ? (
          <Fragment>
            <h4 className="search-session-choice__block-label">
              <Trans>session-search.side-bar.choice.program.text</Trans>
            </h4>
            <div className="search-session-choice__block-legend">
              <Tooltip
                position={'top-right'}
                icon={'info'}
                width={'400px'}
                label={t('session-search.side-bar.choice.difference-between-tests.label')}
              >
                <Trans>session-search.side-bar.choice.difference-between-tests.content</Trans>
              </Tooltip>
            </div>
          </Fragment>
        ) : (
          ''
        )}

        <div className="search-session-choice__programs">
          {Object.values(programs).map((program, key) => (
            <TestTypeFamilyProgram
              key={key}
              hasManyPrograms={Boolean(Object.keys(programs).length)}
              program={program}
              testTypeFamilyId={testTypeFamily.id}
              checkedTestTypeIds={checkedTestTypeIds}
              sessionSearchFilter={sessionSearchFilter}
              pushToUrl={pushToUrl}
              canChangeTestTypeFilter={canChangeTestTypeFilter}
              isChangeTestTypeFilterModalDisplayed={isChangeTestTypeFilterModalDisplayed}
            />
          ))}
        </div>
        <div className="search-session-choice__block-center">
          <Button type={'button'} onClick={toggleBlocks}>
            <Trans>session-search.side-bar.choice.button.find</Trans>
          </Button>
        </div>
      </div>
    </div>
  );
};

SearchSessionTestTypeFamilyChoice.propTypes = {
  toggleBlocks: PropTypes.func.isRequired,
  testTypeFamily: PropTypes.object.isRequired,
  checkedTestTypeIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  sessionSearchFilter: PropTypes.object,
  canChangeTestTypeFilter: PropTypes.bool,
  isChangeTestTypeFilterModalDisplayed: PropTypes.bool,
};

export default SearchSessionTestTypeFamilyChoice;
