import React, { Fragment } from 'react';
import { Trans } from 'react-i18next';
import { PropTypes } from 'prop-types';
import { List } from 'immutable';

import { Link } from '@ets-global/b2c-website-ui';

import './styles.scss';
import { useDataLayer } from '../../../hooks';

const MoreLinks = ({ links, documents }) => {
  const { pushEventPdfDownloaded } = useDataLayer();

  return (
    <section className={'more-links'}>
      <div className="container container--padding">
        <div className="more-links__inner">
          {links.size > 0 && (
            <div className="more-links__column">
              <Fragment>
                <p className="more-links__title">
                  <Trans>blog.article.more-links.title</Trans>
                </p>
                <ul>
                  {links.map((link, key) => {
                    const { label, ...propsLink } = link.toJS();

                    return (
                      <li key={key}>
                        <Link icon={'arrow'} {...propsLink}>
                          {label}
                        </Link>
                      </li>
                    );
                  })}
                </ul>
              </Fragment>
            </div>
          )}
          {documents.size > 0 && (
            <div className="more-links__column">
              <Fragment>
                <p className="more-links__title">
                  <Trans>blog.article.more-documents.title</Trans>
                </p>
                <ul>
                  {documents.map((document, key) => {
                    const { label, ...propsLink } = document.toJS();

                    return (
                      <li key={key}>
                        <Link icon={'download'} {...propsLink} onClick={pushEventPdfDownloaded}>
                          {label}
                        </Link>
                      </li>
                    );
                  })}
                </ul>
              </Fragment>
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

MoreLinks.propTypes = {
  links: PropTypes.instanceOf(List),
  documents: PropTypes.instanceOf(List),
};

export default MoreLinks;
