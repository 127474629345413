import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Trans } from 'react-i18next';

import BaseModal from '../../Base/Modal/BaseModal';
import { Button } from '@ets-global/b2c-website-ui';

const SessionSwitchWarningModal = ({ message, canValidate, onClose, onSubmit }) => {
  const buttons = (
    <Fragment>
      <Button name="button" className={'button--transparent-light'} onClick={onClose}>
        <Trans>common.cancel</Trans>
      </Button>

      {canValidate && (
        <Button name="button" onClick={onSubmit} data-cypress={'buying-process.accept-change-session'}>
          <Trans>common.validate</Trans>
        </Button>
      )}
    </Fragment>
  );

  return (
    <BaseModal
      isOpen
      onClose={onClose}
      title={<Trans>session-search.main.item.warning.change-session.title</Trans>}
      footer={buttons}
    >
      <Trans>{message}</Trans>
    </BaseModal>
  );
};

SessionSwitchWarningModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default SessionSwitchWarningModal;
