import React, { useContext } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Map } from 'immutable';
import kebabCase from 'lodash/kebabCase';

import Button from '@ets-global/b2c-website-ui/Button';
import Icon from '@ets-global/b2c-website-ui/Icon';

import { paths } from '../../../../paths';
import { useDateToString } from '../../../Element/DateToString';
import InjectHtml from '../../../InjectHtml';
import { AppContext } from '../../../../store/App';

import './styles.scss';

const PushSlider = ({ nextSession, testTypesId }) => {
  const { t } = useTranslation();
  const DateToString = useDateToString();
  const testTypeIds = testTypesId.map((testTypeId) => 'testTypeIds[]=' + testTypeId).join('&');
  const { city, region } = useContext(AppContext);
  const kebabCity = kebabCase(city);
  const kebabRegion = kebabCase(region);

  return (
    <div className="push-slider">
      <InjectHtml
        component="p"
        className="push-slider__subtitle"
        html={t('home.next-session.test-type-family-name', {
          testTypeFamilyName: nextSession.testTypeFamily.name,
          interpolation: { escapeValue: false },
        })}
      />
      <div className="push-slider__information">
        <h2 className="push-slider__date">
          <DateToString date={nextSession.scheduledAt} timezone={nextSession.timeZone} format="DD MMM" />
        </h2>
        <p className="push-slider__localization">
          {nextSession.testingSite ? (
            <>
              <Icon name="pin" />
              <span className="push-slider__localization-city">{nextSession.testingSite.address.city}</span>
            </>
          ) : (
            <Trans>common.online</Trans>
          )}
        </p>
      </div>
      <Button
        to={paths.SESSION_SEARCH}
        params={{
          city: kebabCity,
          region: kebabRegion,
        }}
        search={testTypeIds}
        className="form__button form__submit"
      >
        <Trans>home.next-session.register</Trans>
      </Button>
    </div>
  );
};

PushSlider.prototype = {
  nextSession: PropTypes.instanceOf(Map),
  testTypesId: PropTypes.arrayOf(PropTypes.number),
};

export default PushSlider;
